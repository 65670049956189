import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import swal from 'sweetalert2';
function ViewText(props) {
  const [state, setState] = useState({
    text: null,
  });
  useEffect(() => {
    async function fetchData() {
      if (props.srcurl && props.srcurl.length > 0) {
        try {
          if (!props.srcurl) return;
          var res = await fetch(props.srcurl);
          console.log(res);
          res = await res.text();
          setState({ text: res });
        } catch (e) {
          await swal.fire("¡Ups!", 'Error al mostrar archivo', "error");
        }
      }
    }
    fetchData();
  }, [props.srcurl]);
  return (
    <div className="ViewText">
      <Modal {...props} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Mostrar Texto</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p style={{ width: "100%", height: "500px" }}>{state.text}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
export default ViewText;
