import React from "react";
import AppMenuUsuario from "./AppMenuUsuario";
import AppNotificaciones from "./AppNotificaciones";
function AppHeader(props) {
  return (
    <div className="AppHeader">
      <header className="main-header">
        <nav className="navbar navbar-expand-md bg-3">
          <div className="navbar-logo p-3 py-md-0">
            <a className="" id="linkIndex" href="# ">
              <img
                src="/images/landing/logo.png"
                alt="Dashcore"
                className="logo logo-sticky d-block d-md-none"
              />
              <img
                src="/images/landing/logo-light.png"
                alt="Dashcore"
                className="logo d-none d-md-block"
              />
            </a>
            <button
              type="button"
              className="navbar-toggler"
              style={{ paddingLeft: 0 }}
            >
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
            </button>
          </div>
          <div className="navbar-options px-3 px-md-4">
            <form
              className="form search-form mr-auto"
              action="/search"
              method="GET"
              id="header-search"
            >
              <div className="form-group mb-0 d-flex position-relative">
                <input
                  type="text"
                  className="form-control pl-5"
                  placeholder="Buscar..."
                  id="txtBuscar"
                  name="query"
                />
                <button type="submit" className="btn btn-link pl-0 pt-2">
                  <i className="pe pe-7s-search pe-2x"></i>
                </button>
              </div>
            </form>

            <ul className="nav">
              <AppNotificaciones />
              <AppMenuUsuario user={props.user} />
              {/*<li className="aside-toggler" data-behavior="absolute">
                <a href="# ">
                  <i className="pe pe-7s-keypad"></i>
                </a>
  </li>*/}
            </ul>
          </div>
        </nav>
      </header>
    </div>
  );
}

export default AppHeader;
