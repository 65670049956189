import React from "react";
function Error404(props) {
  return (
    <main>
      <div className="container-fluid">
        <div className="fullscreen row align-items-center">
          <div className="col-md-4 mx-md-auto text-center">
            <div
              className="center-xy color-6 alpha-5 bold"
              style={{ fontSize: "25rem", zIndex: "-1" }}
            >
              404
            </div>
            <img
              src="/images/landing/section/status/404.svg"
              className="img-responsive w-70 mx-auto"
              alt="Página no encontrada"
            />
            <h2 className="bold text-danger mt-5">
              Upss no encontramos esta página
            </h2>
            <p className="color-2">
              Sorry, por más que buscamos no podemos encontrar la página que
              estás buscando.
            </p>
            <a href="/" className="btn btn-4 btn-rounded mt-5 py-3">
              <i className="icon fas fa-long-arrow-alt-left"></i> Regresa al Home
            </a>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Error404;
