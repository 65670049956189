import React from "react";
import Navigation from "../../components/Navigation";
import FooterLanding from "../../components/FooterLanding";
import Partners from "../../components/Partners";

function LandingIndex() {
  return (
    <div className="LandingIndex">
      <Navigation />
      <header className="header online-payment-header section color-1 alpha-8">
        <div id="stripes">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div className="container overflow-hidden bring-to-front">
          <div className="row">
            <div className="col-md-6">
              <h1 className="color-1 bold">
                LifeChain
                <span className="d-block light">
                  {" "}
                  Consultoría y Desarrollo en Blockchain{" "}
                </span>
              </h1>
              <p className="lead">
                Ten un impacto disruptivo en tu ecosistema e industria. No te quedes atrás, nosotros te ayudamos a innovar.
              </p>
              <nav className="nav mt-5">
                {/*<!--<a href="#" className="nav-link mr-3 btn btn btn-rounded btn-1">
								<i className="fas fa-tag mr-3"></i> Planes & precio </a>-->*/}
                <a
                  href="/#verticales"
                  className="nav-link btn btn-rounded btn-success"
                >
                  Contáctanos
                </a>
              </nav>
            </div>
            <div className="col-md-6">
              <img
                src="images/landing/bg/seguimiento.webp"
                className="img-hero img-responsive absolute-md"
                alt=""
              />
            </div>
          </div>
        </div>
      </header>
      <section id="verticales" className="section bg-1 edge top-left">
        <div className="container">
          <div className="section-heading mb-6 text-center">
            <h5 className="accent bold small text-uppercase">
              Elige tu caso de uso{" "}
            </h5>
            <h2>Verticales</h2>
          </div>

          <div className="row gap-y text-center text-md-left">
            <a href="/certificacion-documentos" className="col-md-4 py-4 rounded shadow-hover text-center" style={{ color: 'inherit', textDecoration: 'none' }}>
              <div >
                <div className="icon-shape mb-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 276.1 281.01"
                    className="shape shape-xxl drop-shadow"
                    fill="#05d5ff"
                  >
                    <defs>
                      <linearGradient
                        id="linear-gradient"
                        y1="140.5"
                        x2="276.1"
                        y2="140.5"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop offset="0" stopColor="#1ca1a4" />
                        <stop offset="1" stopColor="#05d5ff" />
                      </linearGradient>
                    </defs>
                    <g id="layer_2" data-name="layer 2">
                      <g id="OBJECTS">
                        <path
                          className="cls-1"
                          d="M261.95,86.82a83.45,83.45,0,0,0-8.57-10.71l0,0s-50.84-57.89-137-73.9l0,0A92.94,92.94,0,0,0,5,77.21c-17.58,85.56,16.63,154.31,16.83,154.72a83.17,83.17,0,0,0,6.67,11.93c21.11,31.38,60.18,43.83,96.07,33.7,0,0,58.12-14.28,123.33-79h0C278.76,169,285.58,121.95,261.95,86.82Z"
                        />
                      </g>
                    </g>
                  </svg>
                  <i className="pe pe-7s-file pe-3x color-1 icon center-xy"></i>
                </div>
                <h5 className="bold text-capitalize">SEGURIDAD DE ARCHIVOS</h5>
                <p>
                  {" "}
                  Verifica tus archivos fácilmente y evita
                  su falsificación.
                  Perfecto para notarios y legales.
                  Es usado por el BID Lab para proteger
                  a mujeres víctimas de violencia.
                </p>
              </div>
            </a>
            <a href="https://nft.lifechain.mx" target="_blank" rel="noreferrer" className="col-md-4 py-4 rounded shadow-hover text-center" style={{ color: 'inherit', textDecoration: 'none' }}>
              <div >
                <div className="icon-shape mb-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 276.1 281.01"
                    className="shape shape-xxl drop-shadow"
                    fill="#1ca1a4"
                  >
                    <defs>
                      <linearGradient
                        id="linear-gradient"
                        y1="140.5"
                        x2="276.1"
                        y2="140.5"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop offset="0" stopColor="#1ca1a4" />
                        <stop offset="1" stopColor="#05d5ff" />
                      </linearGradient>
                    </defs>
                    <g id="layer_2" data-name="layer 2">
                      <g id="OBJECTS">
                        <path
                          className="cls-1"
                          d="M261.95,86.82a83.45,83.45,0,0,0-8.57-10.71l0,0s-50.84-57.89-137-73.9l0,0A92.94,92.94,0,0,0,5,77.21c-17.58,85.56,16.63,154.31,16.83,154.72a83.17,83.17,0,0,0,6.67,11.93c21.11,31.38,60.18,43.83,96.07,33.7,0,0,58.12-14.28,123.33-79h0C278.76,169,285.58,121.95,261.95,86.82Z"
                        />
                      </g>
                    </g>
                  </svg>
                  <i className="icon fas fa-2x fa-music center-xy color-1"></i>
                </div>
                <h5 className="bold text-capitalize">NFTS</h5>
                <p>
                  Te ayudamos a monetizar tu comunidad con NFTs,
                  identificar a los fans plus, ofrecer nuevas experiencias y recibir regalías en
                  las reventas de arte, merch y entradas de eventos.
                </p>
              </div>
            </a>
            <a href="/contact?consultoria" className="col-md-4 py-4 rounded shadow-hover text-center" style={{ color: 'inherit', textDecoration: 'none' }}>
              <div>
                <div className="icon-shape mb-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 276.1 281.01"
                    className="shape shape-xxl drop-shadow"
                    fill="#05d5ff"
                  >
                    <defs>
                      <linearGradient
                        id="linear-gradient"
                        y1="140.5"
                        x2="276.1"
                        y2="140.5"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop offset="0" stopColor="#1ca1a4" />
                        <stop offset="1" stopColor="#05d5ff" />
                      </linearGradient>
                    </defs>
                    <g id="layer_2" data-name="layer 2">
                      <g id="OBJECTS">
                        <path
                          className="cls-1"
                          d="M261.95,86.82a83.45,83.45,0,0,0-8.57-10.71l0,0s-50.84-57.89-137-73.9l0,0A92.94,92.94,0,0,0,5,77.21c-17.58,85.56,16.63,154.31,16.83,154.72a83.17,83.17,0,0,0,6.67,11.93c21.11,31.38,60.18,43.83,96.07,33.7,0,0,58.12-14.28,123.33-79h0C278.76,169,285.58,121.95,261.95,86.82Z"
                        />
                      </g>
                    </g>
                  </svg>
                  <i className="pe pe-7s-portfolio pe-3x color-1 icon center-xy"></i>
                </div>
                <h5 className="bold text-capitalize">CONSULTORÍA Y DESARROLLO</h5>
                <p>
                  Si quieres saber más de como blockchain puede ayudarte en tu industria,
                  o ya tienes una idea, nosotros te
                  ayudamos, con talleres de innovación y metodologías
                  ágiles.
                </p>
              </div>
            </a>
          </div>
          <div className="row gap-y text-center text-md-left">
            <a href="/contact?suministro" className="col-md-4 py-4 rounded shadow-hover text-center" style={{ color: 'inherit', textDecoration: 'none' }}>
              <div>
                <div className="icon-shape mb-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 276.1 281.01"
                    className="shape shape-xxl drop-shadow"
                    fill="#1ca1a4"
                  >
                    <defs>
                      <linearGradient
                        id="linear-gradient"
                        y1="140.5"
                        x2="276.1"
                        y2="140.5"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop offset="0" stopColor="#1ca1a4" />
                        <stop offset="1" stopColor="#05d5ff" />
                      </linearGradient>
                    </defs>
                    <g id="layer_2" data-name="layer 2">
                      <g id="OBJECTS">
                        <path
                          className="cls-1"
                          d="M261.95,86.82a83.45,83.45,0,0,0-8.57-10.71l0,0s-50.84-57.89-137-73.9l0,0A92.94,92.94,0,0,0,5,77.21c-17.58,85.56,16.63,154.31,16.83,154.72a83.17,83.17,0,0,0,6.67,11.93c21.11,31.38,60.18,43.83,96.07,33.7,0,0,58.12-14.28,123.33-79h0C278.76,169,285.58,121.95,261.95,86.82Z"
                        />
                      </g>
                    </g>
                  </svg>
                  <i className="pe pe-7s-share pe-3x color-1 icon center-xy"></i>
                </div>
                <h5 className="bold text-capitalize">LOGÍSTICA</h5>
                <p>
                  Trazabilidad de cadenas de suministro con blockchain,
                  demuestra la calidad y todas las medidas sanitarias tomadas 
                  de una manera segura y confiable.
                </p>
              </div>
            </a>
            <a href="/contact?donaciones" className="col-md-4 py-4 rounded shadow-hover text-center" style={{ color: 'inherit', textDecoration: 'none' }}>
              <div>
                <div className="icon-shape mb-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 276.1 281.01"
                    className="shape shape-xxl drop-shadow"
                    fill="#05d5ff"
                  >
                    <defs>
                      <linearGradient
                        id="linear-gradient"
                        y1="140.5"
                        x2="276.1"
                        y2="140.5"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop offset="0" stopColor="#1ca1a4" />
                        <stop offset="1" stopColor="#05d5ff" />
                      </linearGradient>
                    </defs>
                    <g id="layer_2" data-name="layer 2">
                      <g id="OBJECTS">
                        <path
                          className="cls-1"
                          d="M261.95,86.82a83.45,83.45,0,0,0-8.57-10.71l0,0s-50.84-57.89-137-73.9l0,0A92.94,92.94,0,0,0,5,77.21c-17.58,85.56,16.63,154.31,16.83,154.72a83.17,83.17,0,0,0,6.67,11.93c21.11,31.38,60.18,43.83,96.07,33.7,0,0,58.12-14.28,123.33-79h0C278.76,169,285.58,121.95,261.95,86.82Z"
                        />
                      </g>
                    </g>
                  </svg>
                  <i className="icon fas fa-2x fa-heartbeat center-xy color-1"></i>
                </div>
                <h5 className="bold text-capitalize">SEGUIMIENTO DE DONACIONES</h5>
                <p>
                  En una encuesta que realizamos, el 74% afirma que donaría más a las organizaciones que utilicen LifeChain,
                  para dar transparencia a sus donativos con blockchain.
                </p>
              </div>
            </a>
            <a href="/contact?gobierno" className="col-md-4 py-4 rounded shadow-hover text-center" style={{ color: 'inherit', textDecoration: 'none' }}>
              <div>
                <div className="icon-shape mb-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 276.1 281.01"
                    className="shape shape-xxl drop-shadow"
                    fill="#1ca1a4"
                  >
                    <linearGradient
                      id="linear-gradient"
                      gradientUnits="userSpaceOnUse"
                      x1="1041.6901"
                      y1="169.485"
                      x2="1383.9301"
                      y2="169.485"
                      gradientTransform="matrix(1 0 0 -1 -761.14 398.97)"
                    >
                      <stop offset="14%" stopColor="#2f343b" stopOpacity="0%" />
                      <stop offset="43%" stopColor="#337082" stopOpacity="41%" />
                      <stop offset="67%" stopColor="#369fb9" stopOpacity="73%" />
                      <stop offset="79%" stopColor="#37b1cf" stopOpacity="85%" />
                    </linearGradient>
                    <g id="layer_2" data-name="layer 2">
                      <g id="OBJECTS">
                        <path
                          className="cls-1"
                          d="M261.95,86.82a83.45,83.45,0,0,0-8.57-10.71l0,0s-50.84-57.89-137-73.9l0,0A92.94,92.94,0,0,0,5,77.21c-17.58,85.56,16.63,154.31,16.83,154.72a83.17,83.17,0,0,0,6.67,11.93c21.11,31.38,60.18,43.83,96.07,33.7,0,0,58.12-14.28,123.33-79h0C278.76,169,285.58,121.95,261.95,86.82Z"
                        />
                      </g>
                    </g>
                  </svg>
                  <i className="pe pe-7s-culture pe-3x color-1 icon center-xy"></i>
                </div>
                <h5 className="bold text-capitalize">GOBIERNO DIGITAL</h5>
                <p>
                  {" "}
                  Asesoría en la creación de leyes y políticas que fomenten el uso de blockchain,
                  dando transparencia y acabando con la desconfianza
                  en instituciones de gobierno.
                </p>
              </div>
            </a>
          </div>
        </div>
      </section>
      <Partners />
      <section className="section">
        <div className="shape-wrapper">
          <div className="shape shape-background shape-right"></div>
          <div className="shape shape-background top shape-left bg-4 op-1"></div>
        </div>
        <div className="container pb-9">
          <div className="row gap-y align-items-center py-5">
            <div className="col-md-6">
              <figure data-aos="fade-right">
                <img
                  src="images/landing/shps/payments/register.svg"
                  className="img-responsive w-50"
                  alt=""
                />
                <img
                  src="images/landing/shps/payments/register-click.svg"
                  className="img-responsive absolute w-50"
                  alt=""
                  style={{ bottom: "10%", left: "125px" }}
                  data-aos="fade-up"
                />
              </figure>
            </div>
            <div className="col-md-6">
              <div className="icon-xxl mb-4">
                <div className="icon-shape">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 219.98 213.35"
                    className="shape shape-xxl drop-shadow"
                    fill="#1ca1a4"
                  >
                    <g id="layer_1" data-name="layer_1">
                      <g id="OBJECTS">
                        <path
                          className="cls-shape"
                          d="M92.2.36c58.51-4,85.65,25.71,100.68,65.52s37.29,53.51,21.92,99.33-70.9,51.88-92.59,46.52S78,192.49,51,182.07C10.79,166.56.86,146.16.06,126.06-1.07,97.63,13.92,94.45,18.73,63.28,24.51,25.93,46.43,3.47,92.2.36Z"
                        />
                      </g>
                    </g>
                  </svg>
                  <i className="icon fas fa-2x fa-user center-xy color-1"></i>
                </div>
              </div>
              <h2 className="bold text-capitalize">Centrados en el Cliente</h2>
              <p className="regular">
                Antes de ofrecer cualquier solución, buscamos entender los retos,
                puntos de dolor, expectativas y requerimientos básicos buscados,
                comenzando con un análisis, para dar el mejor acompañamiento y
                asesoría entendiendo su situación particular a fondo.{" "}
              </p>
              <a
                href="/contact"
                className="btn gradient gradient-42 color-1 btn-rounded btn-outline-1 bw-2 bold mt-4"
              >
                Quiero mi análisis
              </a>
            </div>
          </div>
          <div className="row gap-y align-items-center py-5">
            <div className="col-md-6 order-md-2">
              <figure data-aos="fade-left">
                <img
                  src="images/landing/shps/payments/configure.svg"
                  className="img-responsive"
                  alt=""
                />
              </figure>
            </div>
            <div className="col-md-6">
              <div className="icon-xxl mb-4">
                <div className="icon-shape">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 219.98 213.35"
                    className="shape shape-xxl drop-shadow"
                    fill="#1ca1a4"
                  >
                    <g id="layer_1" data-name="layer_1">
                      <g id="OBJECTS">
                        <path
                          className="cls-shape"
                          d="M92.2.36c58.51-4,85.65,25.71,100.68,65.52s37.29,53.51,21.92,99.33-70.9,51.88-92.59,46.52S78,192.49,51,182.07C10.79,166.56.86,146.16.06,126.06-1.07,97.63,13.92,94.45,18.73,63.28,24.51,25.93,46.43,3.47,92.2.36Z"
                        />
                      </g>
                    </g>
                  </svg>
                  <i className="icon fas fa-2x fa-hand-holding-heart center-xy color-1"></i>
                </div>
              </div>
              <h2 className="bold text-capitalize">Explorar las soluciones</h2>
              <p className="regular">
                Contamos con varios desarrollos que podrían dar solución a tu problemática
                o alinearse a lo que buscas, bajando costos de desarrollo y permitiendo hacer
                adecuaciones y disfrutar en poco tiempo de un software personalizado.
                Trabajando siempre con metodologías ágiles.
              </p>
              <a
                href="/contact"
                className="btn gradient gradient-42 color-1 btn-rounded btn-outline-1 bw-2 bold mt-4"
              >
                Quiero ver demos
              </a>
            </div>
          </div>
          <div className="row gap-y align-items-center py-5">
            <div className="col-md-6">
              <figure data-aos="fade-right">
                <img
                  src="images/landing/shps/payments/receive.svg"
                  className="img-responsive"
                  alt=""
                />
              </figure>
            </div>
            <div className="col-md-6">
              <div className="icon-xxl mb-4">
                <div className="icon-shape">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 219.98 213.35"
                    className="shape shape-xxl drop-shadow"
                    fill="#1ca1a4"
                  >
                    <g id="layer_1" data-name="layer_1">
                      <g id="OBJECTS">
                        <path
                          className="cls-shape"
                          d="M92.2.36c58.51-4,85.65,25.71,100.68,65.52s37.29,53.51,21.92,99.33-70.9,51.88-92.59,46.52S78,192.49,51,182.07C10.79,166.56.86,146.16.06,126.06-1.07,97.63,13.92,94.45,18.73,63.28,24.51,25.93,46.43,3.47,92.2.36Z"
                        />
                      </g>
                    </g>
                  </svg>
                  <i className="icon fas fa-2x fa-project-diagram center-xy color-1"></i>
                </div>
              </div>
              <h2 className="bold text-capitalize">
                Mejora Continua
              </h2>
              <p className="regular">
                Lo que no se mide, no se puede mejorar, para nosotros es muy importante seguir
                analizando las soluciones, proponer mejoras y siempre estar en las tendencias,
                acercando la innovación y transformando las industrias.
              </p>
              <a
                href="/contact"
                className="btn gradient gradient-42 color-1 btn-rounded btn-outline-1 bw-2 bold mt-4"
              >
                Quiero innovar
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container pt-5">
          <div className="section-heading text-center">
            <h2 className="bold">
              ¿Qué es
              <span className="bold"> Blockchain?</span>
            </h2>
            <p className="lead color-2">
              Todos hablan de el, pero nadie lo conoce bien, aquí te explicamos
              el porque es tan poderoso usarlo y todos los beneficios que
              podemos obtener
            </p>
          </div>
          <div
            className="shadow rounded text-center overlay overlay-5 alpha-8 color-1 p-5 image-background cover"
            style={{
              backgroundImage:
                "url(https://picsum.photos/350/200/?random&gravity=south)",
            }}
          >
            <div className="content">
              <div className="section-heading">
                <h2 className="color-1">Conoce la tecnología que usamos</h2>
              </div>
              <p className="handwritten highlight font-md">Da click al video</p>

              <a
                href="https://www.youtube.com/watch?v=Esgm4wYXOps"
                className="modal-popup mfp-iframe btn btn-circle btn-lg btn-accent"
                data-effect="mfp-fade"
              >
                <i className="pe pe-7s-play pe-2x ml-2"></i>
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="section overflow-hidden">
        <div className="container pt-4 bring-to-front">
          <div className="row" style={{ display: "none" }}>
            <div className="col-md-10 mx-md-auto">
              <form
                action="srv/register.php"
                className="form"
                data-response-message-animation="slide-in-left"
                noValidate="novalidate"
              >
                <div className="input-group-register py-4">
                  <input
                    type="email"
                    name="Subscribe[email]"
                    className="form-control shadow-lg bg-1 form-control-rounded"
                    placeholder="escribe tu email"
                    required=""
                  />
                  <button
                    className="btn btn-rounded btn-accent btn-lg"
                    type="submit"
                  >
                    Quiero saber más
                  </button>
                </div>
              </form>
              <div className="response-message">
                <i className="fas fa-envelope font-lg"></i>
                <p className="font-md m-0">Checa tu correo</p>
                <p className="response">
                  Te enviamos un correo electrónico con información para
                  conocernos mejor
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section shape-testimonials edge bg-6 top-left">
        <div className="shape-wrapper">
          <svg
            className="shape h-100"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 249.28 253.71"
            fill="#1ca1a4"
          >
            <defs>
              <linearGradient
                id="linear-gradient"
                y1="126.86"
                x2="249.28"
                y2="126.86"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0" stopColor="#b620b4" />
                <stop offset="0.23" stopColor="#ad23b5" />
                <stop offset="0.6" stopColor="#952bb8" />
                <stop offset="1" stopColor="#7437bc" />
              </linearGradient>
            </defs>
            <title>shape</title>
            <g id="Capa_2" data-name="Capa 2">
              <g id="OBJECTS">
                <path
                  className="cls-1"
                  d="M236.5,78.38a75.26,75.26,0,0,0-7.74-9.67l0,0S182.91,16.47,105.14,2l0,0A83.91,83.91,0,0,0,4.53,69.71C-11.34,147,19.55,209,19.73,209.4a75,75,0,0,0,6,10.77c19.06,28.33,54.33,39.57,86.73,30.43,0,0,52.47-12.9,111.35-71.37h0C251.68,152.56,257.84,110.1,236.5,78.38Z"
                />
              </g>
            </g>
          </svg>
        </div>
        <div className="container bring-to-front">
          <div className="row">
            <div className="col-lg-6 ml-lg-auto">
              <div className="section-heading text-center text-lg-left">
                <h2 className="bold">¿Quiénes hablan de nosotros?</h2>
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div
              className="col-md-6 order-sm-2 image-background contain"
              style={{
                backgroundImage: "url(images/landing/shps/map-dots.svg)",
              }}
            >
              <div className="user-reviews" id="user-review-nav">
                <div
                  className="review absolute user nav-item active"
                  style={{ left: "calc(50% - 40px)", top: "75px" }}
                  data-step="1"
                >
                  <div className="text">
                    <img
                      src="images/landing/avatar/1.jpg"
                      className="img-responsive shadow rounded-circle"
                      alt="..."
                      data-aos="zoom-in"
                    />
                  </div>
                </div>
                <div
                  className="review absolute user nav-item"
                  style={{ left: 0, top: "140px" }}
                  data-step="2"
                >
                  <div className="text">
                    <img
                      src="images/landing/avatar/2.jpg"
                      className="img-responsive shadow rounded-circle"
                      alt="..."
                      data-aos="zoom-in"
                    />
                  </div>
                </div>
                <div
                  className="review absolute user nav-item"
                  style={{ left: "calc(100% - 80px)", top: "300px" }}
                  data-step="3"
                >
                  <div className="text">
                    <img
                      src="images/landing/avatar/3.jpg"
                      className="img-responsive shadow rounded-circle"
                      alt="..."
                      data-aos="zoom-in"
                    />
                  </div>
                </div>
                <div
                  className="review absolute user nav-item"
                  style={{ left: "calc(100% - 80px)", top: "200px" }}
                  data-step="4"
                >
                  <div className="text">
                    <img
                      src="images/landing/avatar/4.jpg"
                      className="img-responsive shadow rounded-circle"
                      alt="..."
                      data-aos="zoom-in"
                    />
                  </div>
                </div>
                <div
                  className="review absolute user nav-item"
                  style={{ left: "50%", top: "63%" }}
                  data-step="5"
                >
                  <div className="text">
                    <img
                      src="images/landing/avatar/5.jpg"
                      className="img-responsive shadow rounded-circle"
                      alt="..."
                      data-aos="zoom-in"
                    />
                  </div>
                </div>
                <div
                  className="review absolute user nav-item"
                  style={{ left: "27%", top: "30%" }}
                  data-step="6"
                >
                  <div className="text">
                    <img
                      src="images/landing/avatar/6.jpg"
                      className="img-responsive shadow rounded-circle"
                      alt="..."
                      data-aos="zoom-in"
                    />
                  </div>
                </div>
                <div
                  className="review absolute user nav-item"
                  style={{ left: "27%", top: "54%" }}
                  data-step="7"
                >
                  <div className="text">
                    <img
                      src="images/landing/avatar/7.gif"
                      className="img-responsive shadow rounded-circle"
                      alt="..."
                      data-aos="zoom-in"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div
                className="swiper-container"
                data-sw-navigation="#user-review-nav"
              >
                <div className="swiper-wrapper">
                  <div className="swiper-slide">
                    <blockquote>
                      <p className="lead bold">
                        Desarrollaron un programa capaz de rastrear los
                        impuestos que pagan los contribuyentes que, además,
                        atiende sus necesidades a través de la tecnología
                        blockchain.
                      </p>
                      <hr className="w-10 mt-5" />
                      <div className="small">
                        <a
                          href="http://www.entrepreneur.com/article/311681"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span className="bold d-block">Entrepreneur</span>
                        </a>
                      </div>
                    </blockquote>
                  </div>
                  <div className="swiper-slide">
                    <blockquote>
                      <p className="lead bold">
                        Tecnología para acabar con la corrupción y saber en
                        donde acaba cada centavo de nuestros impuestos y
                        donaciones
                      </p>
                      <hr className="w-10 mt-5" />
                      <div className="small">
                        <a
                          href="https://www.facebook.com/Talentnetworkmatrix/videos/2194542147228490/?v=2194542147228490"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span className="bold d-block">
                            Francisco Zea (Imagen)
                          </span>
                        </a>
                      </div>
                    </blockquote>
                  </div>
                  <div className="swiper-slide">
                    <blockquote>
                      <p className="lead bold">
                        Fue el mejor proyecto de la vertical &quot;Gobierno Digital&quot;,
                        patrocinado por la Secretaría de la Función Pública del
                        Gobierno Federal.
                      </p>
                      <hr className="w-10 mt-5" />
                      <div className="small">
                        <a
                          href="https://www.informador.mx/tecnologia/Impuestos-transparentes-y-combate-a-Fake-News-proyectos-ganadores-del-Talent-Hackaton-20180406-0086.html"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span className="bold d-block">El informador</span>
                        </a>
                      </div>
                    </blockquote>
                  </div>
                  <div className="swiper-slide">
                    <blockquote>
                      <p className="lead bold">
                        Generar confianza entre la población mexicana fue el
                        principal motor para los ganadores del hackathon de
                        Talent Land 2018
                      </p>
                      <hr className="w-10 mt-5" />
                      <div className="small">
                        <a
                          href="https://www.eleconomista.com.mx/tecnologia/Blockchain-y-combate-a-Fake-News-ganan-hackathon-en-Talent-Land-2018-20180406-0060.html"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span className="bold d-block">El Economista</span>
                        </a>
                      </div>
                    </blockquote>
                  </div>
                  <div className="swiper-slide">
                    <blockquote>
                      <p className="lead bold">
                        {" "}
                        Creada por jóvenes mexicanos que persiguen hacerle
                        frente al desvío de fondos mediante transacciones con
                        criptoactivos, además de disminuir la desconfianza
                        existente entre la población mexicana hacia sus
                        instituciones.
                      </p>
                      <hr className="w-10 mt-5" />
                      <div className="small">
                        <a
                          href="https://www.criptonoticias.com/entrevistas/iniciativa-peso-x-peso-nuestro-sueno-tokenizar-mexico/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span className="bold d-block">
                            Emily Faria (Criptonoticias)
                          </span>
                        </a>
                      </div>
                    </blockquote>
                  </div>
                  <div className="swiper-slide">
                    <blockquote>
                      <p className="lead bold">
                        Se sorprenderían de todo lo que se puede lograr con una
                        idea y con ganas de hacer algo
                      </p>
                      <hr className="w-10 mt-5" />
                      <div className="small">
                        <a
                          href="http://www.cucea.udg.mx/es/noticia/25-abr-2018/una-plataforma-que-pretende-seguir-el-camino-de-nuestros-impuestos"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span className="bold d-block">Dora Solís (UdG)</span>
                        </a>
                      </div>
                    </blockquote>
                  </div>
                  <div className="swiper-slide">
                    <blockquote>
                      <p className="lead bold">
                        A través de una plataforma de blockchain muestra a qué
                        se destinan los impuestos
                      </p>
                      <hr className="w-10 mt-5" />
                      <div className="small">
                        <a
                          href="https://www.facebook.com/Mural/videos/con-la-premiaci%C3%B3n-de-un-proyecto-de-presupuesto-participativo-y-una-app-para-det/1913446358690355/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span className="bold d-block">Mural</span>
                        </a>
                      </div>
                    </blockquote>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section bg-6 send-and-receive">
        <div className="container bring-to-front">
          <div className="row gap-y">
            <div className="col-md-6">
              <div
                className="rounded bg-1 shadow-box image-background contain off-left-background p-4 pl-6 pl-md-9"
                style={{
                  backgroundImage: "url(images/landing/shps/payments/send.svg)",
                }}
              >
                <h3>SaaS (Software as a Service)</h3>
                <p className="color-2 mb-0">
                  Ofrecemos la posibilidad de tener una suscripción para usar el sistema,
                  sin tener que mantener un desarrollo, entrando con un usuario y contraseña,
                  solo pagando lo que usas, sin mayores preocupaciones.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div
                className="rounded bg-1 shadow-box image-background contain off-left-background p-4 pl-6 pl-md-9"
                style={{
                  backgroundImage: "url(images/landing/shps/payments/get.svg)",
                }}
              >
                <h3>Desarrollo personalizado</h3>
                <p className="color-2 mb-0">
                  Nosotros te ayudamos a innovar y mejorar tus procesos, con talleres de innovación y
                  metodologías ágiles para que descubras como blockchain te puede ayudar y tengas el
                  mejor software a la medida.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section bg-1 edge top-left b-b">
        <div className="container pt-5">
          <div className="d-flex align-items-center flex-column flex-md-row">
            <div className="col-md-8 text-center text-md-left">
              <p className="light mb-0 accent lead">
                ¿Listo para innovar?
              </p>
              <h2 className="mt-0">Crea una cuenta y comienza <br />a explorar la plataforma </h2>
            </div>
            <a
              href="/signup"
              className="col-md-4 btn gradient gradient-42 color-1 btn-rounded btn-outline-1 bw-2 bold mt-4"
            >
              Crea tu usuario
            </a>
          </div>
        </div>
      </section>
      <FooterLanding />
    </div>
  );
}

export default LandingIndex;
