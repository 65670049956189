import swal from 'sweetalert2';

var Util = {
  rfcValido: (e, t) => {
    null === t && (t = !0);
    var o = e.match(
      /^([A-ZÑ&]{4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/
    );
    if (!o) return !1;
    var n,
      a,
      r = o.pop(),
      c = o.slice(1).join(""),
      i = c.length,
      f = i + 1;
    n = 12 === i ? 0 : 481;
    for (var l = 0; l < i; l++)
      n +=
        "0123456789ABCDEFGHIJKLMN&OPQRSTUVWXYZ Ñ".indexOf(c.charAt(l)) *
        (f - l);
    return (
      11 === (a = 11 - (n % 11)) ? (a = 0) : 10 === a && (a = "A"),
      !!(r === a || (t && c + r === "XAXX010101000")) &&
      !(!t && c + r === "XEXX010101000") &&
      c + r
    );
  },
  emailValido: (e) => {
    return /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      e
    );
  },
  formatDateShortNumbers: (date) => {
    var created_date = new Date(date);
    var year = created_date.getFullYear();
    var month = created_date.getMonth() + 1;
    date = created_date.getDate();
    return date + "/" + month + "/" + year;
  },
  formatDateShort: (date) => {
    if (!date) return "";
    var months = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];
    var created_date = new Date(date);
    var year = created_date.getFullYear();
    var month = created_date.getMonth() + 1;
    date = created_date.getDate();
    return date + " " + months[month - 1] + " " + year;
  },
  formatDate: (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  },
  compareString: (strA, strB) => {
    for (var result = 0, i = strA.length; i--;) {
      if (typeof strB[i] === "undefined" || strA[i] === strB[i]);
      else if (strA[i].toLowerCase() === strB[i].toLowerCase()) result++;
      else result += 4;
    }
    return (
      1 -
      (result + 4 * Math.abs(strA.length - strB.length)) /
      (2 * (strA.length + strB.length))
    );
  },
  numberWithCommas: (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  },
  getUrlVars: () => {
    var vars = {};
    window.location.href.replace(
      /[?&]+([^=&]+)=([^&]*)/gi,
      function (m, key, value) {
        vars[key] = value;
      }
    );
    return vars;
  },
  getNDeleteCookie: (name) => {
    const value = "; " + document.cookie;
    const parts = value.split("; " + name + "=");
    document.cookie = "";
    if (parts.length === 2) return parts.pop().split(";").shift();
  },
  bytesToSize: (bytes) => {
    if (bytes === undefined) return false;
    var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) return "0 Bytes";
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
  },
  requestSuccess: async (res, loginNecesario = false) => {
    if (res.status === 200 || res.status === 201) {
      return true;
    } else if (res.status === 401) {
      if (loginNecesario) {
        await swal.fire("¡Ups!", "Es necesario iniciar sesión", "error");
        window.location.href = "/login";
        return false;
      }

    } else if (res.status === 404) {
      await swal.fire("¡Ups!", "Recurso no encontrado", "error");
      return false;
    } else {
      return false;
    }
  },
  validarJWT: () => {
    const token = localStorage.getItem("JWT");
    if (!token || token === "undefined" || token === null) return false;
    var decodedToken = JSON.parse(decodeURIComponent(escape(atob(token.split(".")[1]))));
    var dateNow = new Date();

    if (decodedToken.exp < dateNow.getTime())
      return true;

    return false;
  }
};

export default Util;
