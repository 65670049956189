import React, { useEffect, Fragment } from "react";
import Navigation from "../../components/Navigation";
import FooterLanding from "../../components/FooterLanding";

function PoliticaPrivacidad() {
    useEffect(() => {
        document.title = "LifeChain - Politica privacidad";
    });

    return (
        <div className="PoliticaPrivacidad">
            <Fragment>
                <Navigation dark="true"/>
                <main>
                    <section className="section terms">
                        <div className="container pt-md-9">
                            <h1 style={{marginTop:"50px"}}>Política de privacidad</h1>
                            <p className="color-2 small bold">Úiltima actualización el 26 de julio, 2019.</p>
                            <hr className="my-4"/>
                            <p style={{textAlign:"justify"}}>En términos de lo previsto en la Ley Federal de Protección de Datos
                            Personales en Posesión de
                            Particulares (en adelante “Ley”), LifeChain como responsable de los datos personales tratados
                            en el presente sitio en línea o con motivo de sus actividades establece el presente Aviso de
                            Privacidad de acuerdo a lo siguiente: </p>

                            <h3>Términos y Condiciones</h3>
                            <p style={{textAlign:"justify"}}>1. El presente Aviso de Privacidad tiene como objeto la protección de
                            los datos
                            personales de los usuarios de los servicios prestados por LifeChain, mediante su
                            tratamiento en términos de lo establecido por la Ley con la finalidad de garantizar su
                            privacidad.</p>
                            <p style={{textAlign:"justify"}}>2. Dato Personal es cualquier información concerniente a una persona
                            física identificada
                            o identificable recabada por LifeChain.</p>
                            <p style={{textAlign:"justify"}}>3. Los datos personales tratados con motivo de los servicios
                            prestados por LifeChain son:
                            Nombre Completo, correo electrónico y teléfono.</p>
                            <p style={{textAlign:"justify"}}>4. En todo caso, la información personal es tratada únicamente para
                            los fines propios de
                            los servicios prestados por Lifechain como el seguimiento a las donaciones efectuadas,
                            aclaración de procesos y seguimiento a impuestos; la comunicación con donantes,
                            beneficiarios o personas que muestren interés en las actividades de LifeChain, y
                            ocasionalmente para publicitar o dar a conocer actividades de LifeChain.</p>
                            <p style={{textAlign:"justify"}}>5. LifeChain podrá remitir información para el procesamiento de datos
                            personales por
                            parte de encargados de tratamiento, para fines de gestión administrativa o de los
                            proyectos auspiciados, así como de transparencia, control y seguimiento puntual de
                            donativos y su impacto en comunidades o individuos en particular.Todo encargado
                            estará sujeto a las mismas obligaciones de confidencialidad y tratamiento de la
                            información personal en consistencia con el presente aviso de privacidad y las
                            disposiciones legales aplicables.</p>
                            <p style={{textAlign:"justify"}}>6. La temporalidad del manejo de sus datos personales será indefinida
                            a partir de la fecha
                            en que nos los proporciones.Usted podrá oponerse al manejo de los mismos en
                            cualquier momento, con las limitaciones de la Ley o en todo caso ejercer cualesquiera
                            de sus derechos de acceso, rectificación, oposición o cancelación previstos bajo la Ley.</p>
                            <p style={{textAlign:"justify"}}>7. Lo anterior mediante la solicitud vía correo electrónico
                            siguiente:
                            escribenos @lifechain.mx.A fin de poder atender según lo correspondiente a su
                            solicitud o mensaje, es necesario que Usted incluya una copia de su identificación
                            personal, y si actúa en representación de otra persona, la identificación de la persona a
                            nombre de quien actúa, y el poder correspondiente.</p>
                            <p style={{textAlign:"justify"}}>8. LifeChain podrá utilizar cookies, web beacons u otras tecnologías
                            durante la prestación
                            de servicios del sitio web.La utilización y navegación en el presente sitio constituye su
                            consentimiento para el uso de dichas tecnologías.</p>
                            <p style={{textAlign:"justify"}}>9. Cualquier cambio al presente Aviso de Privacidad será notificado a
                            través de la página
                            de Internet de LifeChain, y su publicación estará disponible en esta misma vía.</p>
                            <p style={{textAlign:"justify"}}>10. En caso de que Usted considere que su derecho a la protección de
                            datos personales ha
                            sido vulnerado, le asiste el derecho de acudir ante el Instituto Nacional de
                            Transparencia, Acceso a la Información y Protección de Datos Personales (INAI).Para
                            más información, visite www.inai.org.mx.</p>
                        </div>
                    </section>
                </main>
                <FooterLanding />
            </Fragment>
        </div>
    );
}

export default PoliticaPrivacidad;
