import React, { useEffect, Fragment } from "react";
import Navigation from "../../components/Navigation";
import BlogFooter from "../../components/BlogFooter";

function BlogDescargarResultadosDonadores() {

  useEffect(() => {
    document.title = "LifeChain - Blog";
  });

  return (
    <div className="BlogDescargarResultadosDonadores">
      <Fragment>
        <Navigation />
        <main>
			<header className="section header color-1 app-landing-header">
				<div className="shape-wrapper">
					<div className="shape shape-background shape-main gradient gradient-53"></div>
					<div className="shape shape-background shape-top gradient gradient-35"></div>
				</div>
				<div className="container overflow-hidden">
					<div className="row gap-y align-items-center">
						<div className="col-md-6 col-lg-6">
							<h1 className="bold display-lg-2 font-lg mb-5">¡Todo listo!
							</h1>
							<p className="lead">Ahora puedes acceder a los resultados de nuestra investigación en cualquier
								momento.</p>
							<div className="nav mt-5 align-items-center">
								<a href="/docs/Resultados estudio de percepcion donadores.pdf" target="_blank"
									className="btn btn-rounded btn-lg btn-4 shadow mr-3 px-4 text-capitalize">Descargar
									archivo PDF</a>
							</div>
						</div>
						<div className="col-md-6 col-lg-5 ml-lg-auto">
							<div data-aos="fade-left">
								<div className="mobile-device iphone-x">
									<div className="screen">
										<img src="/images/blog/screnshot - investigacion.jpg" className="img-responsive"
											alt=""/>
									</div>
									<div className="notch"></div>
								</div>
								<div className="absolute screen-highlight center-x w-100">
									<img src="/images/screens/app/2-highlight.png" alt=""
										className="mx-auto shadow-lg rounded img-responsive" data-aos-delay="1000"
										data-aos="zoom-in"/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</header>
			</main>
        <BlogFooter/>
      </Fragment>
    </div>
  );
}

export default BlogDescargarResultadosDonadores;