import React, { useEffect } from "react";
import Api from "../util/api";
import Util from "../util/util";
import swal from 'sweetalert2';

function ValidarEmail(props) {

    useEffect(() => {
        async function verificarEmail() {
            try {
                var tokenId = window.location.pathname.replace(
                    "/usuarios/verificar-email/",
                    ""
                );
                console.log(tokenId);
                var res = await Api.usuarios.validarEmail(tokenId);
                if (!await Util.requestSuccess(res)) {
                    await swal.fire("¡Ups!", 'Error al validar email', "error");
                    return;
                }
                res = await res.json();
                console.log(res);
                if (res.resp === 1) {
                    if (typeof (Storage) !== "undefined") {
                        localStorage.setItem("LCJWT", res.token);
                    }
                    window.location.href = "/app/mi-perfil";
                } else if (res.resp === 3) {
                    //$jq('#formPass').css("display", "block");
                    //$jq('#lblLoading').css("display", "none");
                    //$jq('#lblMsgSaludo').html("¡Hola " + res.usuario.nombre + "! para dar seguimiento a tu donación, ingresa una contraseña")
                    //passObligatorio = true;
                }
            } catch (e) {
                await swal.fire("¡Ups!", 'Error al validar email', "error");
                return;
            }
        }

        verificarEmail();

    }, []);


    return (
        <div className="container">
            <div className="row">
                <div className="col-md-4 col-xs-1">

                </div>
                <div className="col-md-4 col-xs-10" style={{ textAlign: "center" }}>
                    <img src="/images/logo_lifechain_negro.png" alt="LifeChain" style={{ width: "50%", margin: "30px" }} />
                    <div className="form" id="formPass" style={{ display: "none" }}>
                        <form className="login-form" id="login-form" method="post" autoComplete="off" action="/login">
                            <p id="lblMsgSaludo">¡Hola! para dar seguimiento a tu donación, ingresa una contraseña</p>
                            <p id="activation_msg"></p>
                            <input type="password" placeholder="Contraseña" name="pass" id="txtPass" />
                            <input type="password" placeholder="Confirmar contraseña" name="pass" id="txtPassConfirm" />
                            <div id="message">
                                <h4>Tu contraseña debe contener:</h4>
                                <p id="letter" className="invalid">Una letra <b>minúscula</b></p>
                                <p id="capital" className="invalid">Una letra <b>mayúscula</b></p>
                                <p id="number" className="invalid">Un <b>número</b></p>
                                <p id="length" className="invalid">Minimo <b>8 caracteres</b></p>
                            </div>
                            <button id="cmdOk">Continuar</button>
                            <p id="msgReg" style={{ color: "red" }}></p>
                        </form>
                    </div>

                    <h4 id="lblLoading"><b>Estamos verificando tu direccion de correo, en un momento podras dar segumiento a tu donación!</b></h4>
                </div>
            </div>
        </div>
    );
}

export default ValidarEmail;

