import React, { useEffect, Fragment } from "react";
import Navigation from "../../components/Navigation";
import BlogFooter from "../../components/BlogFooter";

function PrensaImagenTalent() {

  useEffect(() => {
    document.title = "LifeChain - Blog";
  });

  return (
    <div className="PrensaImagenTalent">
      <Fragment>
        <Navigation />
        <main>
			<header className="section color-1 fullscreen blog-single-header image-background"
				style={{"backgroundImage": "url('/images/blog/1.svg')", "backgroundColor": "#464685"}}>
				<div className="container text-center">
					<div className="row">
						<div className="col-md-10 mx-md-auto">
							<h1 className="post-title display-4">El rifado del día - Imágen</h1>
						</div>
					</div>
					<a href="#blog-post" className="scrollto mt-auto">
						<div className="arrow-down">
							<i className="pe pe-7s-angle-down pe-4x color-5"></i>
						</div>
					</a>
				</div>
			</header>
			<section className="blog-post" id="blog-post">
				<div className="container">
					<div className="row">
						<div className="col-md-8 mx-auto">
							<div className="media">
								<img className="mr-3 rounded-circle icon-md"
									src="https://thepoint.com.mx/www/wp-content/uploads/2017/06/contenidos.png" alt=""/>
								<div className="media-body small">
									<span className="author">por
										<a href=" #"> Francisco Zea</a>
									</span>
									<span className="d-block color-2 alpha-6">Abril 9, 2018</span>
								</div>
							</div>
							<hr className="mb-5"/>
							<video style={{width:"100%"}} controls>
								<source src="/videos/imagen_talent_land.mp4" type="video/mp4"/>
								Your browser does not support the video tag.
							</video>
							<br />
							<p>Link: <a href="https://www.entrepreneur.com/article/311681"
									target="_blank" rel="noreferrer">https://www.entrepreneur.com/article/311681</a></p>
						</div>
					</div>
				</div>
			</section>
			<section>
				<div className="container py-5 b-t b-2x">
					<div className="media align-items-center">
						<img className="d-flex mr-3 rounded-circle shadow" style={{"width": "100px", "height": "100px"}}
							src="https://thepoint.com.mx/www/wp-content/uploads/2017/06/contenidos.png" alt="..."/>
						<div className="media-body">
							<h5 className="my-0 bold">Francisco Zea</h5>
							<span className="color-2">Imágen</span>
							<hr/>
							<p className="small color-2 mb-0">Consejos, estrategias, perfiles y guías para emprendedores en
								todo el mundo. Casa de la revista Entrepreneur.</p>
						</div>
					</div>
				</div>
			</section>
			<section>
				<div className="container py-5 b-t">
					<ul className="list-unstyled d-flex flex-wrap flex-row align-items-center">
						<li className="mr-4">
							<i className="fas fa-tag color-2"></i>
						</li>
						<li>
							<a href=" #" className="badge badge-pill badge-outline-2 mr-2">blockchain</a>
						</li>
						<li>
							<a href=" #" className="badge badge-pill badge-outline-2 mr-2">talent land</a>
						</li>
						<li>
							<a href=" #" className="badge badge-pill badge-outline-2 mr-2">presupuesto participativo</a>
						</li>
						<li>
							<a href=" #" className="badge badge-pill badge-outline-2 mr-2">hackathon</a>
						</li>
						<li>
							<a href=" #" className="badge badge-pill badge-outline-2 mr-2">jalisco</a>
						</li>
					</ul>
					<ul className="list-unstyled d-flex flex-wrap flex-row align-items-center">
						<li className="mr-4">
							<i className="fas fa-bookmark color-2"></i>
						</li>
						<li>
							<a href=" #" className="btn btn-circle btn-sm brand-twitter mr-3">
								<i className="fab fa-twitter"></i>
							</a>
						</li>
						<li>
							<a href=" #" className="btn btn-circle btn-sm brand-facebook mr-3">
								<i className="fab fa-facebook"></i>
							</a>
						</li>
					</ul>
				</div>
			</section>
		</main>
        <BlogFooter/>
      </Fragment>
    </div>
  );
}

export default PrensaImagenTalent;