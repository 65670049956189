import React, { useState } from "react";
import Api from "../util/api";
import ReCAPTCHA from "react-google-recaptcha";
import Util from "../util/util";
import Settings from "../config/settings";
import swal from 'sweetalert2';
import useLoading from "../hooks/useLoading";
function FormSignUp(props) {
  const {startLoading, stopLoading } = useLoading();
  const recaptchaRef = React.useRef();
  const [state, setState] = useState({
    register: {
      nombre: "",
      apellidos: "",
      email: "",
      pass: "",
      passConfirm: "",
      captchaScore: "",
    },
    passSelected: false,
    error: null,
    loading: false,
  });
  function passValidarLetraMinuscula() {
    var lowerCaseLetters = /[a-z]/g;

    if (!state.register.pass) return "invalid";
    if (state.register.pass.match(lowerCaseLetters)) {
      return "valid";
    } else {
      return "invalid";
    }
  }
  function passValidarLetraMayuscula() {
    var upperCaseLetters = /[A-Z]/g;

    if (!state.register.pass) return "invalid";
    if (state.register.pass.match(upperCaseLetters)) {
      return "valid";
    } else {
      return "invalid";
    }
  }
  function passValidarNumero() {
    var numbers = /[0-9]/g;

    if (!state.register.pass) return "invalid";
    if (state.register.pass.match(numbers)) {
      return "valid";
    } else {
      return "invalid";
    }
  }
  function passValidadLogitud() {
    if (!state.register.pass) return "invalid";
    if (state.register.pass.length > 8) {
      return "valid";
    } else {
      return "invalid";
    }
  }
  const handleChange = (e) => {
    const { id, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      register: {
        ...prevState.register,
        [id]: value,
      },
    }));
  };

  function onChangeCaptcha(value) {
    console.log("Captcha value:", value);
    setState((prevState) => ({
      ...prevState,
      register: {
        ...prevState.register,
        captchaToken: value,
      },
    }));
  }

  const registerClick = async (e) => {
    if (e) e.preventDefault();
    try {
      startLoading();
      var res = await Api.usuarios.crear(state.register);
      var data = await res.json();
      if (await Util.requestSuccess(res)) {
        stopLoading();
        await swal.fire({
          icon: 'success',
          html: "Hemos enviado un email a la dirección " +
            state.register.email +
            " para confirmar que sea real, es necesario que confirmes tu cuenta."
        });
      } else {
        console.log(data.msg);
        await swal.fire("Ups!", "Error al registrar nuevo usuario. " + data.msg, "error");
        return;
      }
    } catch (e) {
      console.log(e);
      await swal.fire("Ups!", "Error al registrar nuevo usuario", "error");
      return;
    }
  };
  return (
    <form
      className="cozy"
      data-validate-on="submit"
      autoComplete="off"
      noValidate
    >
      <div className="form-group has-icon">
        <input
          type="text"
          id="nombre"
          className="form-control form-control-rounded"
          placeholder="Nombre"
          onChange={handleChange}
        />
        <i className="icon fas fa-user-plus"></i>
      </div>
      <div className="form-group has-icon">
        <input
          type="text"
          id="apellidos"
          className="form-control form-control-rounded"
          placeholder="Apellidos"
          onChange={handleChange}
        />
        <i className="icon fas fa-address-card"></i>
      </div>
      <div className="form-group has-icon">
        <input
          type="text"
          id="email"
          className="form-control form-control-rounded"
          placeholder="Correo electrónico"
          onChange={handleChange}
        />
        <i className="icon fas fa-envelope"></i>
      </div>
      <div className="form-group has-icon">
        <input
          type="password"
          id="pass"
          className="form-control form-control-rounded"
          placeholder="Contraseña"
          onChange={handleChange}
          onSelect={() => {
            setState((prevState) => ({
              ...prevState,
              passSelected: true,
            }));
          }}
        />
        <i className="icon fas fa-lock"></i>
        <input type="hidden" id="captchaScore" />
      </div>
      <div id="message">
        <h4>Tu contraseña debe contener:</h4>
        <p id="letter" className={passValidarLetraMinuscula()}>
          Una letra <b>minúscula</b>
        </p>
        <p id="capital" className={passValidarLetraMayuscula()}>
          Una letra <b>mayúscula</b>
        </p>
        <p id="number" className={passValidarNumero()}>
          Un <b>número</b>
        </p>
        <p id="length" className={passValidadLogitud()}>
          Minimo <b>8 caracteres</b>
        </p>
      </div>
      <div className="form-group has-icon">
        <input
          type="password"
          id="passConfirm"
          className="form-control form-control-rounded"
          placeholder="Confirmar contraseña"
          onChange={handleChange}
        />
        <i className="icon fas fa-lock"></i>
      </div>
      <ReCAPTCHA
        ref={recaptchaRef}
        onChange={onChangeCaptcha}
        sitekey={Settings.captcha_key}
      />
      <p className="color-2 mb-4">
        <input
          type="checkbox"
          name="aceptoPoliticaPrivacidad"
          id="aceptoPoliticaPrivacidad"
        />{" "}
        Acepto el{" "}
        <a href="/politica-privacidad" target="_blank" className="accent bold">
          Aviso de privacidad
        </a>
        {" y "}
        <a href="/terminos-condiciones" target="_blank" className="accent bold">
          Terminos y condiciones
        </a>
      </p>
      <p id="msgReg" style={{ color: "red" }}></p>
      <div className="d-flex align-items-center justify-content-between">
        <button
          id="cmdRegister"
          onClick={() => registerClick()}
          className="btn btn-accent btn-rounded ml-auto"
        >
          Crear una cuenta
          <i className="fas fa-long-arrow-alt-right ml-2"></i>
        </button>
      </div>
    </form>
  );
}

export default FormSignUp;
