//import { useHistory } from "react-router-dom";
import React, { useState } from "react";
import FileBase64 from "react-file-base64";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import CtlNuevoDocumento from "../CtlNuevoDocumento";
import Api from "../../util/api";
import Util from "../../util/util";
import swal from 'sweetalert2';
import useLoading from "../../hooks/useLoading";
function CertificarDocumentos(props) {
  //const history = useHistory();
  const { startLoading, stopLoading } = useLoading();
  const [state, setState] = useState({
    user: { saldo_certificar_docs: 0 },
    documentos_a_certificar: null,
    estados_documentos_a_certificar: null,
    loading: false,
    error: null,
  });
  async function getFiles(files) {
    startLoading();
    for (var i = 0; i < files.length; i++) {
      if (files[0].file.size >= 10485760) {
        stopLoading();
        await swal.fire("¡Ups!", 'Los archivos a certificar no pueden ser mayores a 10mb. "' +
          files[0].file.name +
          '" es un archivo demasiado grande.', "error");
        setState({
          ...state,
          documentos_a_certificar: null,
        });
        return;
      }
    }
    console.log(files);
    var arrayEstados = [];
    for (var j = 0; j < files.length; j++) {
      arrayEstados.push("En espera");
    }
    setState({
      ...state,
      documentos_a_certificar: files,
      estados_documentos_a_certificar: arrayEstados,
    });
    stopLoading();
  }
  function actualizarVigenciaDocumento(index, fechaVigencia) {
    state.documentos_a_certificar[index].vigencia = fechaVigencia;
    console.log(state.documentos_a_certificar[index].vigencia);
  }
  function actualizarPrivacidadDocumento(index, privado) {
    state.documentos_a_certificar[index].privado = privado;
    console.log(state.documentos_a_certificar[index].privado);
  }
  async function sendFiles() {
    var confirm = await swal.fire({
      title: '¿Estás seguro?',
      text: "Una vez que tus documentos sean certificados en Blockchain su fecha de vencimiento no podrá cambiar.",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      icon: "warning",
      buttons: {
        cancel: "Cancelar",
        ok: "Certificar",
      },
      dangerMode: true,
    });
    if (!confirm.isConfirmed) {
      return;
    }
    try {
      startLoading();
      var documentos_a_certificar = state.documentos_a_certificar;
      for (var i = 0; i < documentos_a_certificar.length; i++) {
        console.log(documentos_a_certificar[i]);
        let estados_documentos_a_certificar =
          state.estados_documentos_a_certificar;
        estados_documentos_a_certificar[i] = "En proceso";
        setState((prevState) => ({
          ...prevState,
          estados_documentos_a_certificar,
        }));
        var errorsCounter = 0;
        try {
          var res = await Api.documentos.certificar("", {
            nombre: documentos_a_certificar[i].file.name,
            contenido: documentos_a_certificar[i].base64.split("base64,")[1],
            vigencia: documentos_a_certificar[i].vigencia,
            privado: documentos_a_certificar[i].privado,
          });
          var jsonRes = await res.json();
          if (await Util.requestSuccess(res)) {
            estados_documentos_a_certificar[i] = "Aceptado";
          } else {
            errorsCounter++;
            estados_documentos_a_certificar[i] = "Error: " + jsonRes.msg;
          }
        } catch (e) {
          console.log(e);
          errorsCounter++;
          estados_documentos_a_certificar[i] = "Error: " + e;
        }

        setState((prevState) => ({
          ...prevState,
          estados_documentos_a_certificar,
        }));
        console.log(res);
      }
      stopLoading();
      if (documentos_a_certificar.length === 1) {
        if (errorsCounter > 0) {
          await swal.fire("¡Ups!", "Tu documento no pudo ser certificado", "error");
        } else {
          await swal.fire({
            icon: 'success',
            html: "Tu documento está siendo certificado"
          });
        }
      } else {
        if (errorsCounter === 0) {
          await swal.fire({
            icon: 'success',
            html: "Tus documentos están siendo certificado"
          });
        } else if (
          errorsCounter > 0 &&
          errorsCounter > documentos_a_certificar.length
        )
          await swal.fire("¡Ups!", "Tus documentos están siendo certificados, ocurrieron algunos errores", "error");
        else await swal.fire("¡Ups!", "Tus documentos no pudieron ser certificados", "error");
      }
      props.consultardocumentos();
      //Aqui no es necesario loading = false, props.consultardocumentos() ya lo hace
      props.onHide();
      setState((prevState) => ({
        ...prevState,
        documentos_a_certificar: null,
      }));
    } catch (e) {
      console.log(e);
    }
  }
  return (
    <div className="CertificarDocumentos">
      <Modal {...props} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Certificar documentos</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-2 col-xs-2"></div>
            <div className="col-md-8 col-xs-8">
              <form className="formUploadFile" style={{ width: "100%" }}>
                <FileBase64 multiple={true} onDone={getFiles.bind(this)} />
                <p>Arrastra aquí tus documentos.</p>
              </form>
            </div>
          </div>
          {state.documentos_a_certificar &&
            state.documentos_a_certificar.length > 0 ? (
            <div id="documentosPorSubirContainer">
              <p id="subtitle">Lista de documentos a certificar.</p>
              <div className="row" style={{ background: "#ccc" }}>
                <div className="col-md-4 col-xs-4">
                  <p>Nombre</p>
                </div>
                <div className="col-md-2 col-xs-2">
                  <p>Peso</p>
                </div>
                <div className="col-md-3 col-xs-3">
                  <p>Vigencia</p>
                </div>
                <div className="col-md-1 col-xs-1">
                  <p>Privado</p>
                </div>
                <div className="col-md-1 col-xs-1">
                  <p>Estado</p>
                </div>
              </div>
              <div id="filesContainer">
                {state.documentos_a_certificar.map((documento, index) => (
                  <CtlNuevoDocumento
                    key={
                      documento.file.last_modified + "/" + documento.file.size
                    }
                    index={index}
                    documento={documento}
                    onChangeVigencia={actualizarVigenciaDocumento}
                    onChangePrivacidad={actualizarPrivacidadDocumento}
                    state={
                      state.estados_documentos_a_certificar &&
                      state.estados_documentos_a_certificar[index]
                    }
                  />
                ))}
              </div>
            </div>
          ) : (
            <p>Aún no hay documentos seleccionados</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Cerrar
          </Button>
          {state.documentos_a_certificar &&
            state.documentos_a_certificar.length > 0 ? (
            <Button variant="primary" onClick={sendFiles}>
              Certificar documentos
            </Button>
          ) : null}
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default CertificarDocumentos;
