import { useEffect } from 'react';

const ReCaptchaV3 = ({ siteKey }) => {
  useEffect(() => {
    // Cargar el script de reCAPTCHA v3 al montar el componente
    if (!window.grecaptcha) {
      const script = document.createElement('script');
      script.src = `https://www.google.com/recaptcha/api.js?render=${siteKey}`;
      script.async = true;
      document.body.appendChild(script);
    }
  }, [siteKey]);

  return null;
};

export default ReCaptchaV3;
