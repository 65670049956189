import React, { useEffect, useState, Fragment } from "react";
import Navigation from "../../components/Navigation";
import BlogFooter from "../../components/BlogFooter";

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

function PreciosDocumentos() {
	const [state, setState] = useState({
		showQuestion: [true, false, false, false]
	});

	useEffect(() => {
		document.title = "LifeChain - Seguridad de Archivos";
	});

	function showFAQ(index) {
		var showQuestion = [false, false, false, false];
		try {
			showQuestion[index] = true;
		} catch (e) {
			console.log(e);
		}
		setState({
			showQuestion
		});
	}

	return (
		<div className="PreciosDocumentos">
			<Fragment>
				<Navigation />
				<main>
					<header className="page header color-1 overlay gradient gradient-43 alpha-8 image-background cover"
						style={{ "backgroundImage": "url('/images/landing/bg/waves.jpg')" }}>
						<div className="divider-shape">
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none"
								className="shape-waves" style={{ "left": "0", "transform": "rotate3d(0,1,0,180deg)" }}>
								<path className="shape-fill shape-fill-1"
									d="M790.5,93.1c-59.3-5.3-116.8-18-192.6-50c-29.6-12.7-76.9-31-100.5-35.9c-23.6-4.9-52.6-7.8-75.5-5.3c-10.2,1.1-22.6,1.4-50.1,7.4c-27.2,6.3-58.2,16.6-79.4,24.7c-41.3,15.9-94.9,21.9-134,22.6C72,58.2,0,25.8,0,25.8V100h1000V65.3c0,0-51.5,19.4-106.2,25.7C839.5,97,814.1,95.2,790.5,93.1z">
								</path>
							</svg>
						</div>
						<div className="container">
							<div className="row">
								<div className="col-md-8 col-xs-8">
									<h1 className="display-4 color-1 mb-4">Certificación de documentos y archivos digitales</h1>
									<p className="lead color-1">Certifica tus documentos de forma fácil y rápida con Blockchain.</p>
								</div>
							</div>
						</div>
					</header>
					<div className="container-fluid py-3 demo-blocks">
						<section className="section b-b">
							<div className="container">
								<div className="section-heading">
									<h2>
										<span className="bold d-block">Planes de precios</span>
									</h2>
									<p>Precios sin IVA</p>
								</div>
								<div className="row">
									<div className="col-md-12 mx-auto">
										<Tabs>
											<TabList>
												<Tab>Anual</Tab>
												<Tab>Mes</Tab>
											</TabList>
											<TabPanel>
												<div className="row">
													<div className="col-md-4 mt-5">
														<div className="card text-center">
															<div
																className="pricing card-header bg-6-gradient d-flex align-items-center flex-column">
																<h4>Personal</h4>
																<div className="pricing-value">
																	<span className="price yearly bold">2,380</span>
																</div>
															</div>

															<ul className="list-group list-group-flush">
																<li className="list-group-item">Protección de 120 archivos al año</li>
																<li className="list-group-item">Almacenamiento 1.5 GB</li>
																<li className="list-group-item">API disponible</li>
																<li className="list-group-item">Soporte por chat y correo</li>
																<li className="list-group-item strike-through">Creación de organizaciones</li>
																<li className="list-group-item strike-through">Aplicación de marca</li>
																<li className="list-group-item strike-through">Acompañamiento en integración de API</li>
															</ul>
															<div className="card-body">
																<a href="/login" className="btn btn-outline-2">Comienza ahora</a>
															</div>
														</div>
													</div>
													<div className="col-md-4 mt-5">
														<div className="card text-center">
															<div className="pricing card-header d-flex align-items-center flex-column"
																style={{ "background": "#fc6d57", "color": "#fff" }}>
																<h4>Business</h4>
																<div className="pricing-value">
																	<span className="price yearly bold">9,520</span>
																</div>
															</div>
															<ul className="list-group list-group-flush">
																<li className="list-group-item">Protección de 1200 archivos al año</li>
																<li className="list-group-item">Almacenamiento 17 GB</li>
																<li className="list-group-item">API disponible</li>
																<li className="list-group-item">Soporte por chat y correo</li>
																<li className="list-group-item">Creación de organizaciones</li>
																<li className="list-group-item">Aplicación de marca</li>
																<li className="list-group-item strike-through">Acompañamiento en integración de API</li>
															</ul>
															<div className="card-body">
																<a href="/login" className="btn btn-accent">Comienza ahora</a>
															</div>
														</div>
													</div>
													<div className="col-md-4 mt-5">
														<div className="card text-center">
															<div
																className="pricing card-header bg-6-gradient d-flex align-items-center flex-column">
																<h4>Enterprise</h4>
																<div className="pricing-value">
																	<span className="">A cotizar</span>
																</div>
															</div>
															<ul className="list-group list-group-flush">
																<li className="list-group-item">Protección de archivos ilimitados</li>
																<li className="list-group-item">Almacenamiento ilimitado</li>
																<li className="list-group-item">API/Iframe disponible</li>
																<li className="list-group-item">Soporte prioritario</li>
																<li className="list-group-item">Creación de organizaciones</li>
																<li className="list-group-item">Aplicación de marca</li>
																<li className="list-group-item">Acompañamiento en integración de API</li>
															</ul>
															<div className="card-body">
																<a href="/login" className="btn btn-outline-2">Comienza ahora</a>
															</div>
														</div>
													</div>
												</div>
											</TabPanel>
											<TabPanel>
												<div className="row">
													<div className="col-md-4 mt-5">
														<div className="card text-center">
															<div className="pricing card-header bg-6-gradient d-flex align-items-center flex-column">
																<h4>Personal</h4>
																<div className="pricing-value">
																	<span className="price bold">220</span>
																</div>
															</div>

															<ul className="list-group list-group-flush">
																<li className="list-group-item">Protección de 10 archivos al mes</li>
																<li className="list-group-item">Almacenamiento 1.5 GB</li>
																<li className="list-group-item">API disponible</li>
																<li className="list-group-item">Soporte por chat y correo</li>
																<li className="list-group-item strike-through">Creación de organizaciones</li>
																<li className="list-group-item strike-through">Aplicación de marca</li>
																<li className="list-group-item strike-through">Acompañamiento en integración de API</li>
															</ul>
															<div className="card-body">
																<a href="/login" className="btn btn-outline-2">Comienza ahora</a>
															</div>
														</div>
													</div>
													<div className="col-md-4 mt-5">
														<div className="card text-center">
															<div className="pricing card-header d-flex align-items-center flex-column"
																style={{ "background": "#fc6d57", "color": "#fff" }}>
																<h4>Business</h4>
																<div className="pricing-value">
																	<span className="price bold">880</span>
																</div>
															</div>
															<ul className="list-group list-group-flush">
																<li className="list-group-item">Protección de 100 archivos al mes</li>
																<li className="list-group-item">Almacenamiento 17 GB</li>
																<li className="list-group-item">API disponible</li>
																<li className="list-group-item">Soporte por chat y correo</li>
																<li className="list-group-item">Creación de organizaciones</li>
																<li className="list-group-item">Aplicación de marca</li>
																<li className="list-group-item strike-through">Acompañamiento en integración de API</li>
															</ul>
															<div className="card-body">
																<a href="/login" className="btn btn-accent">Comienza ahora</a>
															</div>
														</div>
													</div>
													<div className="col-md-4 mt-5">
														<div className="card text-center">
															<div
																className="pricing card-header bg-6-gradient d-flex align-items-center flex-column">
																<h4>Enterprise</h4>
																<div className="pricing-value">
																	<span className="">A cotizar</span>
																</div>
															</div>
															<ul className="list-group list-group-flush">
																<li className="list-group-item">Protección de archivos ilimitados</li>
																<li className="list-group-item">Almacenamiento ilimitado</li>
																<li className="list-group-item">API/Iframe disponible</li>
																<li className="list-group-item">Soporte prioritario</li>
																<li className="list-group-item">Creación de organizaciones</li>
																<li className="list-group-item">Aplicación de marca</li>
																<li className="list-group-item">Acompañamiento en integración de API</li>
															</ul>
															<div className="card-body">
																<a href="/login" className="btn btn-outline-2">Comienza ahora</a>
															</div>
														</div>
													</div>
												</div>
											</TabPanel>
										</Tabs>
									</div>
								</div>
							</div>
						</section>

						<section className="section b-b" hidden={true}>
							<div className="container">
								<div className="section-heading">
									<h2 id="examples">¿Cuántos archivos necesitas proteger?</h2>
									<p className="lead">Elige la red y el plan que mas se adecue a tus necesidades. Recuerda que los
										créditos
										que compres no caducan.</p>
									<div className="alert alert-danger alert-thin" role="alert">¡Aprovecha nuestros precios de
										introducción con la red de Lacchain!</div>
								</div>
								<div className="row">
									<div className="col-md-12 mx-auto">
										<Tabs>
											<TabList>
												<Tab>Lacchain</Tab>
												<Tab>Ethereum</Tab>
											</TabList>

											<TabPanel>
												<p className="lead" style={{ "textAlign": "center" }}>Más rápida y económica, implementa
													Hiperledger
													Besu, la cuál utiliza el protocolo de Ethereum (ETH). Es impulsada por
													el Banco Iberoamericano de Desarrollo (BID).</p>
												<div className="col-md-8 mx-auto">

													<table className="table table-striped">
														<thead>
															<tr>
																<th scope="col">Número de archivos</th>
																<th scope="col">Precio por archivo</th>
																<th scope="col">Precio total</th>
															</tr>
														</thead>
														<tbody>
															<tr>
																<td>10</td>
																<td>
																	<span>$87</span>
																</td>
																<td>
																	<span className="strike-through">($1,750)</span>
																	<span>$870</span>
																</td>
															</tr>
															<tr>
																<td>30</td>
																<td>
																	<span>$76</span>
																</td>
																<td>
																	<span className="strike-through">($2,850)</span>
																	<span>$2,280</span>
																</td>
															</tr>
															<tr>
																<td>50</td>
																<td>
																	<span>$66</span>
																</td>
																<td>
																	<span className="strike-through">($5,800)</span>
																	<span>$3,300</span>
																</td>
															</tr>
															<tr>
																<td>100</td>
																<td>
																	<span>$51</span>
																</td>
																<td>
																	<span className="strike-through">($8,800)</span>
																	<span>$5,100</span>
																</td>
															</tr>
														</tbody>
													</table>
												</div>
											</TabPanel>
											<TabPanel>
												<p className="lead" style={{ "textAlign": "center" }}>La segunda red más utilizada en el
													mundo, después de Bitcoin
													(BTC). La red mas utilizada para implementar aplicaciones
													descentralizadas.</p>
												<div className="col-md-8 mx-auto">

													<table className="table table-striped">
														<thead>
															<tr>
																<th scope="col">Número de archivos</th>
																<th scope="col">Precio por archivo</th>
																<th scope="col">Precio total</th>
															</tr>
														</thead>
														<tbody>
															<tr>
																<td>1</td>
																<td>
																	<span>$626</span>
																</td>
																<td>
																	<span>$626</span>
																</td>
															</tr>
															<tr>
																<td>10</td>
																<td>
																	<span>$401</span>
																</td>
																<td>
																	<span>$4,010</span>
																</td>
															</tr>
															<tr>
																<td>50</td>
																<td>
																	<span>$366</span>
																</td>
																<td>
																	<span>$18,300</span>
																</td>
															</tr>
															<tr>
																<td>100</td>
																<td>
																	<span>$326</span>
																</td>
																<td>
																	<span>$32,600</span>
																</td>
															</tr>
														</tbody>
													</table>
												</div>
											</TabPanel>
										</Tabs>
									</div>
								</div>
							</div>
						</section>
						<section>
							<div className="container">
								<div className="section-heading text-center">
									<h2>Todos nuestros paquetes incluyen</h2>
									<p className="lead color-2">Disfruta de todas estas características</p>
								</div>
								<div className="row gap-y text-center text-md-left">
									<div className="col-md-4">
										<i className="pe pe-3x color-4 pe-7s-browser icon m-0"></i>
										<h5 className="bold my-3">Alta disponibilidad</h5>
										<p className="my-0">Puedes utilizar nuestros servicios en cualquier momento 24/7.</p>
									</div>
									<div className="col-md-4">
										<i className="pe pe-3x color-4 pe-7s-door-lock icon m-0"></i>
										<h5 className="bold my-3">Protección a tu confidencialidad</h5>
										<p className="my-0">Solo escribimos el resumen criptográfico (SHA256) de tu archivo. Por lo
											que el contenido de tu documento sigue siendo privado.</p>
									</div>
									<div className="col-md-4">
										<i className="pe pe-3x color-4 pe-7s-door-lock icon m-0"></i>
										<h5 className="bold my-3">Infraestructura segura</h5>
										<p className="my-0">Estamos respaldados por expertos en seguridad, los cuales nos están
											evaluando continuamente con el objetivo de mitigar posibles vulnerabilidades.</p>
									</div>
									<div className="col-md-4">
										<i className="pe pe-3x color-4 pe-7s-plugin icon m-0"></i>
										<h5 className="bold my-3">Almacenamiento de tus archivos</h5>
										<p className="my-0">Respaldar tus documentos para futuras consultas por personas
											autorizadas.</p>
									</div>
									<div className="col-md-4">
										<i className="pe pe-3x color-4 pe-7s-airplay icon m-0"></i>
										<h5 className="bold my-3">Verificación en tiempo real</h5>
										<p className="my-0">Comprueba rápido y fácil que tus archivos no fueron modificados.</p>
									</div>
									<div className="col-md-4">
										<i className="pe pe-3x color-4 pe-7s-headphones icon m-0"></i>
										<h5 className="bold my-3">Soporte por Chat y correo</h5>
										<p className="my-0">Contáctate con nosotros en cualquier momento para resolver tus dudas o
											dejarnos tus comentarios.</p>
									</div>
								</div>
							</div>
						</section>
						<section className="section bg-6 edge bottom-right">
							<div className="container">
								<div className="row">
									<div className="col-md-4">
										<h2>¿Tienes{" "}
											<span className="bold">dudas</span>?
										</h2>
										<p className="lead">Aquí encontrarás las preguntas más comunes.</p>
										<p className="text-muted">Si tu duda no aparece en la siguiente lista, no dudes en
											contactarnos.</p>
									</div>
									<div className="col-md-8">
										<div className="accordion accordion-clean" id="common-faqs-accordion">
											<div className="card mb-3">
												<div className="card-header">
													<a onClick={() => showFAQ(0)} className="card-title btn" href=" #" data-toggle="collapse"
														data-target="#q1">¿Puedo ver mi archivo en blockchain?</a>
												</div>
												<div id="q1" className={"collapse " + ((state.showQuestion[0]) ? "show" : "")} data-parent="#common-faqs-accordion">
													<div className="card-body">
														<p>Puedes ver la transacción en Blockchain y consultar sólo el resumen
															criptográfico de tu archivo.</p>
														<p>Existe la opción de mostrar el archivo al verificarlo dentro de
															LifeChain, si es
															que así lo decides, para dar mayor transparencia y veracidad.</p>
													</div>
												</div>
											</div>
											<div className="card mb-3">
												<div className="card-header">
													<a onClick={() => showFAQ(1)} className="card-title btn" href=" #" data-toggle="collapse"
														data-target="#q2">¿Mis créditos para certificar archivos tienen
														vigencia?</a>
												</div>
												<div id="q2" className={"collapse " + ((state.showQuestion[1]) ? "show" : "")} data-parent="#common-faqs-accordion">
													<div className="card-body">No, una vez que compres tus créditos para certificar
														archivos, estos no tienen vencimiento.</div>
												</div>
											</div>
											<div className="card mb-3">
												<div className="card-header">
													<a onClick={() => showFAQ(2)} className="card-title btn" href=" #" data-toggle="collapse"
														data-target="#q3">¿Quién puede verificar mi archivo?</a>
												</div>
												<div id="q3" className={"collapse " + ((state.showQuestion[2]) ? "show" : "")} data-parent="#common-faqs-accordion">
													<div className="card-body">
														<p>Cualquier persona que tenga acceso a tu archivo puede
															verificar si fue certificado previamente.</p>
														<p>NOTA: Es responsabilidad del usuario configurar la privacidad de sus
															archivos.</p>
													</div>
												</div>
											</div>
											<div className="card">
												<div className="card-header">
													<a onClick={() => showFAQ(3)} className="card-title btn" href=" #" data-toggle="collapse"
														data-target="#q4">¿Cómo puedo verificar un archivo?</a>
												</div>
												<div id="q4" className={"collapse " + ((state.showQuestion[3]) ? "show" : "")} data-parent="#common-faqs-accordion">
													<div className="card-body">
														<p>Puedes entrar <a href="/app/documentos/validar">aquí</a> para ver las
															opciones para verificar un archivo. En casos de archivos PDF,
															también es posible verificar el archivo leyendo el código QR ubicado en
															la
															esquina
															inferior derecha.</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</section>
						<section className="section">
							<div className="container bring-to-front">
								<div className="shadow rounded text-center bg-4-gradient color-1 p-5">
									<h2 className="mb-5">¿Aún no estás listo para comprar?</h2>
									<p className="handwritten highlight font-md">¡Pruébalo gratis!</p>
									<a href="/register" className="btn btn-5 btn-rounded mt-4">Pruébalo ahora</a>
								</div>
							</div>
						</section>
					</div>
				</main>
				<BlogFooter />
			</Fragment>
		</div>
	);
}

export default PreciosDocumentos;