import React, { useEffect, Fragment } from "react";
import Navigation from "../../components/Navigation";
import BlogFooter from "../../components/BlogFooter";

function PrensaGanadoresTalent() {

  useEffect(() => {
    document.title = "LifeChain - Blog";
  });

  return (
    <div className="PrensaGanadoresTalent">
      <Fragment>
        <Navigation />
        <main>
			<header className="section color-1 fullscreen blog-single-header image-background"
				style={{"backgroundImage": "url('/images/blog/1.svg')", "backgroundColor": "#464685"}}>
				<div className="container text-center">
					<div className="row">
						<div className="col-md-10 mx-md-auto">
							<h1 className="post-title display-4">Estos son los proyectos ganadores del hackathon de Talent
								Land - Entrepreneur</h1>
						</div>
					</div>
					<a href="#blog-post" className="scrollto mt-auto">
						<div className="arrow-down">
							<i className="pe pe-7s-angle-down pe-4x color-5"></i>
						</div>
					</a>
				</div>
			</header>
			<section className="blog-post" id="blog-post">
				<div className="container">
					<div className="row">
						<div className="col-md-8 mx-auto">
							<div className="media">
								<img className="mr-3 rounded-circle icon-md" src="/images/landing/avatar/Entrepreneur.jpg"
									alt=""/>
								<div className="media-body small">
									<span className="author">por
										<a href="https://www.entrepreneur.com/" target="_blank" rel="noreferrer"> Entrepreneur</a>
									</span>
									<span className="d-block color-2 alpha-6">Abril 9, 2018</span>
								</div>
							</div>
							<hr className="mb-5"/>
							<p className="lead color-2 italic mb-5">De blockchain y fake news fueron los proyectos ganadores
								en el hackathon más grande del mundo, que se llevó a cabo en Jalisco Talent Land que
								tuvo lugar en Expo Guadalajara.</p>
							<figure className="shadow rounded mb-5">
								<img className="img-responsive rounded" style={{"width":"100%"}}
									src="/images/blog/PremiosJaliscoTalentLandhackathon.jpeg" alt=""/>
							</figure>
							<p>El hackathon más grande del mundo se llevó a cabo en <b className="bold">Jalisco Talent
									Land</b> que tuvo lugar en Expo Guadalajara. Ahí, dos proyectos fueron los grandes
								ganadores de este evento que potencia el talento.</p>
							<p>El primer lugar fue para el proyecto de <b className="bold">Presupuesto Participativo</b>.
								Sus creadores: Goretti Citlady Oronia Tamayo y Alejandro López Ochoa desarrollaron un
								programa capaz de rastrear los impuestos que pagan los contribuyentes que, además,
								atiende sus necesidades a través de la tecnología blockchain.</p>
							<p>El segundo lugar fue para la iniciativa <b className="bold">Little Bug</b>, que busca
								analizar si un medio en internet es falso para, así, identificar y evitar la propagación
								de noticias falsas (fake news). Erick Manuel Troyo del Campo y Miguel Eduardo Romero
								Ruiz, sus creadores, usan la tecnología de Google Cloud para conseguirlo.</p>
							<p>Los participantes presentaron proyectos relacionados con el aprovechamiento de datos,
								movilidad, salud, smart cities, inclusión financiera, criptomonedas, vivienda y fake
								news en la categoría de problemáticas públicas. Así como salud, inclusión financiera,
								cultura, smart cities, participación ciudadana, educación, anticorrupción, gobierno
								digital, contrataciones abiertas y seguridad ciudadana en la de problemáticas privadas.
							</p>
							<p>Durante los cinco días de la primera edición de Jalisco Talent Land se generaron más de
								300 propuestas y el hackatón repartió entre los ganadores de cada vertical y los de la
								final 100,000 pesos en premios. </p>
							<br />
							<p>Link: <a href="https://www.entrepreneur.com/article/311681"
									target="_blank" rel="noreferrer">https://www.entrepreneur.com/article/311681</a></p>
						</div>
					</div>
				</div>
			</section>
			<section>
				<div className="container py-5 b-t b-2x">
					<div className="media align-items-center">
						<img className="d-flex mr-3 rounded-circle shadow" style={{"width": "100px", "height": "100px"}}
							src="/images/landing/avatar/Entrepreneur.jpg" alt="..."/>
						<div className="media-body">
							<h5 className="my-0 bold">Entrepeneur</h5>
							<span className="color-2">Medio de comunicación/Noticias</span>
							<hr/>
							<p className="small color-2 mb-0">Consejos, estrategias, perfiles y guías para emprendedores en
								todo el mundo. Casa de la revista Entrepreneur.</p>
						</div>
					</div>
				</div>
			</section>
			<section>
				<div className="container py-5 b-t">
					<ul className="list-unstyled d-flex flex-wrap flex-row align-items-center">
						<li className="mr-4">
							<i className="fas fa-tag color-2"></i>
						</li>
						<li>
							<a href=" #" className="badge badge-pill badge-outline-2 mr-2">blockchain</a>
						</li>
						<li>
							<a href=" #" className="badge badge-pill badge-outline-2 mr-2">talent land</a>
						</li>
						<li>
							<a href=" #" className="badge badge-pill badge-outline-2 mr-2">presupuesto participativo</a>
						</li>
						<li>
							<a href=" #" className="badge badge-pill badge-outline-2 mr-2">hackathon</a>
						</li>
						<li>
							<a href=" #" className="badge badge-pill badge-outline-2 mr-2">jalisco</a>
						</li>
					</ul>
					<ul className="list-unstyled d-flex flex-wrap flex-row align-items-center">
						<li className="mr-4">
							<i className="fas fa-bookmark color-2"></i>
						</li>
						<li>
							<a href=" #" className="btn btn-circle btn-sm brand-twitter mr-3">
								<i className="fab fa-twitter"></i>
							</a>
						</li>
						<li>
							<a href=" #" className="btn btn-circle btn-sm brand-facebook mr-3">
								<i className="fab fa-facebook"></i>
							</a>
						</li>
					</ul>
				</div>
			</section>
		</main>
        <BlogFooter/>
      </Fragment>
    </div>
  );
}

export default PrensaGanadoresTalent;