import React, { useEffect, Fragment } from "react";
import Navigation from "../../components/Navigation";
import BlogFooter from "../../components/BlogFooter";

function BlogNFT() {
  useEffect(() => {
    document.title = "LifeChain - Blog";
  });

  return (
    <div className="BlogNFT">
      <Fragment>
        <Navigation />
        <main>
            <header className="section color-1 fullscreen blog-single-header image-background"
                style={{"backgroundImage": "url('/images/blog/1.svg')", "backgroundColor": "#464685"}}>
                <div className="container text-center">
                    <div className="row">
                        <div className="col-md-10 mx-md-auto">
                            <h1 className="post-title display-4">
                                NFT, IPFS y Criptoarte
                            </h1>
                        </div>
                    </div>
                    <a href="#blog-post" className="scrollto mt-auto">
                        <div className="arrow-down">
                            <i className="pe pe-7s-angle-down pe-4x color-5"></i>
                        </div>
                    </a>
                </div>
            </header>
            <section className="blog-post" id="blog-post">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 mx-auto">
                            <div className="media">
                                <img className="mr-3 rounded-circle icon-md"
                                    src="/images/landing/avatar/team/alex-profile.jpg" alt="Alejandro López Ochoa"/>
                                <div className="media-body small">
                                    <span className="author">por 
                                        <a href=" #"> Alejandro López Ochoa</a>
                                    </span>
                                    <span className="d-block color-2 alpha-6">6 de Abril, 2021</span>
                                </div>
                            </div>
                            <hr className="mb-5"/>
                            <p className="lead color-2 italic mb-5">Muy probablemente has escuchado hablar de los NFT o el
                                criptoarte, pero ¿que es un NFT? Empecemos por poner todo en contexto.</p>
                            <p>La propiedad intelectual de obras de arte, siempre ha sido de gran polémica, ya que para
                                asegurar su autenticidad se tenía que contactar a catadores expertos en arte, que
                                podrían fácilmente mentir y darle mayor o menor valor a las obras, dándoles todo el
                                poder de decidir si una obra es real o falsa, para solucionar esto se necesitaba crear
                                un ambiente de total confianza y transparencia entre los artistas y los compradores de
                                arte, donde se puedan asegurar que nadie pueda vender obras de arte falsas, es por eso
                                que el uso de <b className="bold">Tokens No Fungibles (NFT)</b> sobre Blockchain son
                                perfectos para
                                proteger el
                                ahora llamado criptoarte.</p>

                            <img className="img-responsive rounded" src="/images/blog/ntf/indice.jpeg" alt=""/>

                            <h4 className="bold"> ¿Qué significa fungible?
                            </h4>
                            <p>Ser fungible es sinónimo de ser reemplazable, por lo tanto, no fungible es ser
                                considerado único, algo interesante es que muchas veces la <b
                                    className="bold">fungibilidad</b> de un
                                activo
                                puede ser subjetiva. Por ejemplo, para un coleccionista de billetes, es posible que un
                                billete emitido en una fecha específica, o con un número de serie específico, sea más
                                valioso que otros billetes de su misma denominación, o que un viajante esté dispuesto a
                                pagar extra por un boleto de avión junto a la ventana.
                            </p>
                            <h4 className="bold"> ¿Qué es un token?
                            </h4>
                            <p>Es la representación de un activo u objeto sobre blockchain, han sido de gran ayuda para
                                simbolizar activos reales y funcionalidades del mundo real en el mundo digital.
                            </p>
                            <p>Existen diferentes tipos de Tokens, según su comportamiento, podemos clasificarlos de la
                                siguiente manera:
                            </p>
                            <ol>
                                <li className="m-1"><b className="bold">Tokens fungibles: </b>Son aquellos que fueron creados
                                    con la idea de
                                    que una unidad es igual a otra, y son fácilmente intercambiables. Por ejemplo
                                    <b className="bold">bitcoin</b>, o una moneda emitida por algún país. En este caso,
                                    aunque
                                    posiblemente
                                    exista un número de serie, este no es relevante para su uso ni pretende afectar el
                                    valor del activo. Su control es por balances, más no se le puede dar seguimiento
                                    puntual a cada token porque todos son iguales. El ejemplo más común de este tipo de
                                    tokens es el estándar <b className="bold">ERC-20</b>.
                                </li>
                                <li className="m-1"><b className="bold">Tokens no fungibles (NFT):</b> Estos activos tienen
                                    características
                                    o meta datos diferentes entre uno y otro, además de contar con un identificador
                                    único
                                    por unidad. Nos permite escribir información específica por unidad dentro y fuera de
                                    la cadena.
                                    Este tipo de tokens pueden representar obras de arte físicas o digitales. Podemos
                                    dar trazabilidad y ver el historial de dueños que ha tenido el activo con el tiempo.
                                    Los NFT regularmente utilizan el estándar <b className="bold">ERC-721</b>.</li>
                                <li className="m-1"><b className="bold">Tokens semi fungibles:</b> Existen casos especiales en
                                    donde hay
                                    diferentes clasificaciones de activos, pero dentro de cada clasificación cada
                                    entidad puede ser intercambiada por otra. Por ejemplo, en los boletos de avión,
                                    existen diferentes tipos de clases de asientos. Pero dentro de la primera clase, un
                                    boleto podría ser intercambiado por otro. Con ellos se puede tener un control de los
                                    balances por categoría. También puede ser utilizado en criptoarte cuando sacan un
                                    número determinado de copias de una obra. Un ejemplo de este tipo de tokens es el
                                    estándar <b className="bold">ERC-1155</b>.</li>
                            </ol>

                            <h4 className="bold">¿Para que me sirve crear un NFT de mi obra?</h4>
                            <p>Está claro que cualquier persona puede hacer y guardar una copia de un archivo digital
                                sin importar su formato (imagen, vídeo, audio, texto). Pero eso no significa que sean
                                los propietarios de la obra. Los NFT permiten convertir cualquier obra en un objeto
                                digital único, coleccionable y transferible, el cual garantiza la propiedad,
                                inmutabilidad, seguridad y disponibilidad de la obra a través del tiempo. En un caso
                                hipotético donde alguien quiera falsificar un NFT, es muy fácil darse cuenta, ya que el
                                token tiene una dirección de Smart contract, además de estar vinculada a la dirección
                                del creador de la obra.</p>
                            <h4 className="bold">¿Puede un NFT ser una prueba de existencia para mi obra?</h4>
                            <p>Si, al ser un registro escrito en Blockchain, tenemos la información del sello en el
                                tiempo y el emisor de la transacción. Además es posible agregar en los meta datos el
                                resumen criptográfico del documento original. Si en el futuro se quiere demostrar la
                                posesión del archivo en el pasado, basta con volver a generar el resumen criptográfico
                                del archivo en cuestión, y compararlo con el resumen relacionado al NFT. Esto es muy
                                importante para el resguardo de propiedad intelectual, donde el primero en tiempo es el
                                primero en derecho, si demuestras la posesión de la obra antes que otro, tienes los
                                derechos. </p>

                            <h4 className="bold">¿Cómo funcionan los NFT?</h4>
                            <p>Los Tokens no fungibles son Smart Contracts basados en el estándar ERC-721. Este estándar
                                permite almacenar información de las siguientes maneras:</p>
                            <ul>
                                <li><b className="bold">Dentro de la cadena:</b> Dentro del contrato en forma de variables,
                                    la
                                    información que sea escrita de esta manera, goza de la seguridad e inmutabilidad que
                                    Blockchain ofrece. No es posible eliminar o alterar algún dato sin dejar un rastro
                                    público del movimiento.</li>
                                <li><b className="bold">Fuera de la cadena:</b> Es posible escribir un URL como meta dato
                                    para
                                    hacer referencia a alguna API o archivo almacenado en algún servidor, por ejemplo el
                                    archivo completo de la obra de arte. Aquí es muy importante el medio de
                                    almacenamiento que se usará para la obra, ya que al ser un recurso fuera de la
                                    cadena y usar sistemas de almacenamiento convencionales, es posible alterar el
                                    recurso a través del tiempo, por lo que podría no garantizar la integridad y
                                    disponibilidad de la obra.</li>
                            </ul>
                            <p>Una manera de solucionar el problema de inmutabilidad con la información fuera de la
                                cadena, es utilizar el <b className="bold">sistema de archivos interplanetario (IPFS)</b>
                                como medio de
                                almacenamiento. Esta tecnología colaborativa permite subir información en múltiples
                                servidores, por lo que si el servidor o sistema que almacena el archivo deja de
                                funcionar, habrá otros servidores que garanticen la disponibilidad a través del tiempo.
                                Una vez que un archivo sea escrito en la red, no será posible eliminarlo. </p>
                            <p>En LifeChain te podemos ayudar a crear tu NFT, almacenando y respaldando tu obra en IPFS,
                                esto asegura que aunque el almacenamiento de tu obra sea guardada fuera de la cadena, no
                                se pueda modificar a través del tiempo.
                            </p>
                            <div className="text-center">
                                <a href="/contact" target="_blank" rel="noreferrer">
                                    <nav className="navigation"><button className="btn btn-rounded btn-solid px-3"
                                            style={{"backgroundColor":"#fc6d57", "color": "#fff", "margin": "auto"}}
                                            data-toggle="modal" data-target="#modalAudio">Contáctanos</button></nav>
                                </a>
                            </div>
                            <h4 className="bold">Referencias</h4>
                            <ul>
                                <li><a href="https://opensea.io/blog/guides/non-fungible-tokens/"
                                        target="_blank" rel="noreferrer">Finzer, D. (2021, 11
                                        febrero). The Non-Fungible Token Bible: Everything you need to know about NFTs.
                                        OpenSea Blog.</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="container py-5 b-t b-2x">
                    <div className="media align-items-center">
                        <img className="d-flex mr-3 rounded-circle shadow" style={{"width": "100px", "height": "100px"}}
                            src="/images/landing/avatar/team/alex-profile.jpg" alt="Alejandro López Ochoa"/>
                        <div className="media-body">
                            <h5 className="my-0 bold">Alejandro López Ochoa</h5>
                            <span className="color-2">Lifechain</span>
                            <hr/>
                            <p className="small color-2 mb-0">Ing. en Computación por la Universidad de Guadalajara,
                                desarrollador de software y soluciones basadas en blockchain.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="container py-5 b-t">
                    <ul className="list-unstyled d-flex flex-wrap flex-row align-items-center">
                        <li className="mr-4">
                            <i className="fas fa-tag color-2"></i>
                        </li>
                        <li>
                            <a href=" #" className="badge badge-pill badge-outline-2 mr-2">blockchain</a>
                        </li>
                        <li>
                            <a href=" #" className="badge badge-pill badge-outline-2 mr-2">emprendimiento social</a>
                        </li>
                        <li>
                            <a href=" #" className="badge badge-pill badge-outline-2 mr-2">NFT</a>
                        </li>
                        <li>
                            <a href=" #" className="badge badge-pill badge-outline-2 mr-2">IPFS</a>
                        </li>
                        <li>
                            <a href=" #" className="badge badge-pill badge-outline-2 mr-2">token no fungible</a>
                        </li>
                        <li>
                            <a href=" #" className="badge badge-pill badge-outline-2 mr-2">non fungible token</a>
                        </li>
                        <li>
                            <a href=" #" className="badge badge-pill badge-outline-2 mr-2">arte</a>
                        </li>
                        <li>
                            <a href=" #" className="badge badge-pill badge-outline-2 mr-2">cripto arte</a>
                        </li>
                        <li>
                            <a href=" #" className="badge badge-pill badge-outline-2 mr-2">criptoarte</a>
                        </li>
                        <li>
                            <a href=" #" className="badge badge-pill badge-outline-2 mr-2">ERC-721</a>
                        </li>
                        <li>
                            <a href=" #" className="badge badge-pill badge-outline-2 mr-2">ERC-20</a>
                        </li>
                        <li>
                            <a href=" #" className="badge badge-pill badge-outline-2 mr-2">ERC-1155</a>
                        </li>
                        <li>
                            <a href=" #" className="badge badge-pill badge-outline-2 mr-2">México</a>
                        </li>
                    </ul>
                </div>
            </section>
        </main>
        <BlogFooter/>
      </Fragment>
    </div>
  );
}

export default BlogNFT;