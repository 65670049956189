import React from "react";
import Util from "../util/util";
import Api from "../util/api";
import useLoading from "../hooks/useLoading";

function CtlNotificacion(props) {
    const { startLoading, stopLoading } = useLoading();
    const apiNotificacionLeida = async ()=>{
        try{
            startLoading();
            var res = await Api.notificaciones.notificacionLeida(props.notificacion._id);
            await Util.requestSuccess(res, true);
            window.location.href = props.notificacion.url;
        }catch(e){
            stopLoading();
            console.log(e);
        }
    }

    var created_date = new Date(props.notificacion.fecha_registro);
    var months = ['Enero', 'Febrero', 'marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
    //var year = created_date.getFullYear();
    var month = months[created_date.getMonth()];
    var date = created_date.getDate();
    var hour = created_date.getHours();
    var AmPm;
    if (hour < 12) {
        AmPm = 'a.m.';
    } else {
        hour -= 12;
        AmPm = 'p.m.';
    }
    var min = String(created_date.getMinutes());
    if(min.length===1) min = 0 + min;
    var timestamp = date + ' de ' + month + ' - ' + hour + ':' + min + ' ' + AmPm;
    return (
        <div>
            <a onClick={apiNotificacionLeida} href=" #" className="list-group-item list-group-item-action d-flex align-items-center">
                <span className="text-success">
                    <i className="icon fas fa-comment-alt"></i>
                </span>
                <p className="small bold mb-0 d-flex flex-column">
                    <span>{props.notificacion.msg}</span>
                    <span className="text-muted small">{timestamp}</span>
                </p>
            </a>
        </div>
    );
}

export default CtlNotificacion;
