import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Api from "../../util/api";
import Util from "../../util/util";
import swal from 'sweetalert2';
import useLoading from "../../hooks/useLoading";

function NuevoProspecto(props) {
  const { startLoading, stopLoading } = useLoading();
  const recaptchaRef = React.useRef();
  const [state, setState] = useState({
    register: {
      nombre: "",
      organizacion: "",
      correo: "",
      telefono: "",
    },
    passSelected: false,
    error: null,
    loading: false,
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      register: {
        ...prevState.register,
        [id]: value,
      },
    }));
  };

  const registerClick = async (e) => {
    if (e) e.preventDefault();
    try {
      startLoading();
      var res = await Api.prospecto.crear(state.register);
      stopLoading();
      var data = await res.json();
      if (await Util.requestSuccess(res)) {
        await swal.fire('¡Listo!', '¡Gracias por registrarte!', 'success');
      } else {
        await swal.fire("¡Ups!", 'Error al registrar prospecto', "error");
        console.log(data.msg);
        recaptchaRef.current.reset();
      }
    } catch (e) {
      console.log(e);
      await swal.fire("¡Ups!", 'Error al registrar prospecto', "error");
    }
  };

  return (
    <div className="NuevoProspecto">
      <Modal {...props} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Resultados de investigación de mercados a donadores -
            Estudio de percepción</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form action="srv/contact.php" method="post" className="form form-contact" name="form-contact"
            data-response-message-animation="slide-in-up">
            <div className="form-group">
              <label htmlFor="contact_email" className="bold mb-0">Nombre</label>
              <input type="text" id="nombre" className="form-control bg-1" placeholder="Nombre" onChange={handleChange}
                required />
            </div>
            <div className="form-group">
              <label htmlFor="contact_email" className="bold mb-0">Organización</label>
              <input type="text" id="organizacion" className="form-control bg-1" onChange={handleChange}
                placeholder="Organización" required />
            </div>
            <div className="form-group">
              <label htmlFor="contact_email" className="bold mb-0">Correo</label>
              <input type="email" name="Contact[email]" id="correo" className="form-control bg-1"
                placeholder="Email" onChange={handleChange} required />
            </div>
            <div className="form-group">
              <label htmlFor="contact_email" className="bold mb-0">Teléfono</label>
              <input type="text" name="Contact[email]" id="telefono" className="form-control bg-1"
                placeholder="Teléfono" onChange={handleChange} required />
            </div>
            <p className="color-2 mb-4"><input type="checkbox" name="suscripcion_blog"
              id="suscripcion_blog" checked /> Subscribirse al blog de LifeChain</p>
            <p className="mt-5">Tu privacidad es importante para nosotros. LifeChain utiliza la
              información que proporcionas para ponerse en contacto contigo en relación con
              contenido, productos y servicios relevantes para ti. Puedes darte de baja para dejar
              de recibir este tipo de comunicaciones en cualquier momento. Si deseas obtener más
              información sobre la protección de tus datos en LifeChain, consulta nuestra <a
                href="/politica-privacidad">Política de privacidad</a>.</p>
            <div className="form-group text-center">
              <button id="cmdNuevoProspecto" data-loading-text="Sending..." name="submit"
                type="submit" className="btn btn-accent btn-rounded"
                onClick={registerClick}>Descargar</button>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
          <Button variant="primary" onClick={registerClick}>
            Descargar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
export default NuevoProspecto;
