import React from "react";
import FormSignUp from "../../components/FormSignUp";
function SignUp(props) {
  return (
    <div className="SignUp">
      <main>
        <div className="container-fluid">
          <div className="row align-items-center">
            <div
              className="col-md-6 col-lg-7 fullscreen-md d-flex justify-content-center align-items-center overlay gradient gradient-53 alpha-7 image-background cover"
              style={{
                backgroundImage:
                  "url(https://picsum.photos/1920/1200/?random&gravity=south)",
              }}
            >
              <div className="content">
                <h2 className="display-4 display-md-3 color-1 mt-4 mt-md-0">
                  Tu cuenta de
                  <span className="bold d-block">LifeChain</span>
                </h2>
                <p className="lead color-1 alpha-5">
                  Se necesitan más personas como tú, que quieren hacer un cambio
                </p>
                <hr className="mt-md-6 w-25" />
                <div className="d-flex flex-column" style={{ display: "none" }}>
                  <p className="small bold color-1" style={{ display: "none" }}>
                    O inicia sesión con
                  </p>
                  <nav className="nav mb-4" style={{ display: "none" }}>
                    <a
                      className="btn btn-rounded btn-outline-2 brand-facebook mr-2"
                      href="# "
                    >
                      <i className="fab fa-facebook-f"></i>
                    </a>
                    <a
                      className="btn btn-rounded btn-outline-2 brand-twitter mr-2"
                      href="# "
                    >
                      <i className="fab fa-twitter"></i>
                    </a>
                    <a
                      className="btn btn-rounded btn-outline-2 brand-linkedin"
                      href="# "
                    >
                      <i className="fab fa-linkedin-in"></i>
                    </a>
                  </nav>
                </div>
              </div>
            </div>
            <div className="col-md-5 col-lg-4 mx-auto">
              <div className="login-form mt-5 mt-md-0">
                <a id="hrefIndex" href="/">
                  <img
                    src="images/landing/logo.png"
                    className="logo img-responsive"
                    alt="LifeChain"
                  />
                </a>
                <h1 className="color-4 bold">Registro</h1>
                <p className="color-2 mb-4">
                  ¿Listo para apoyar?{" "}
                  <a href="/login" className="accent bold">
                    Inicia sesión aqui
                  </a>
                </p>
                <FormSignUp />
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default SignUp;
