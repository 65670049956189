import React, { useState, useEffect, Fragment } from "react";
import Api from "../../util/api";
import Util from "../../util/util";
import Navigation from "../../components/Navigation";
import FooterLanding from "../../components/FooterLanding";
import ReCAPTCHA from "react-google-recaptcha";
import Settings from "../../config/settings";
import swal from 'sweetalert2';

function Contact() {
	const recaptchaRef = React.createRef();
	const [state, setState] = useState({
		productoSeleccionado: "",
		propspecto: {

		}
	});

	useEffect(() => {
		document.title = "LifeChain - Contacto";
		console.log(window.location.search);
		if (state.productoSeleccionado.length === 0) {
			var initialId = window.location.search.replace(
				"?",
				""
			);
			console.log(initialId);
			setState((prevState) => ({
				...prevState,
				productoSeleccionado: initialId,
			}));
		}
	});

	const handleChange = (e) => {
		const { id, value } = e.target;
		setState((prevState) => ({
			...prevState,
			propspecto: {
				...prevState.propspecto,
				[id]: value,
			},
		}));
	};

	function onChangeCaptcha(value) {
		console.log("Captcha value:", value);
		setState((prevState) => ({
			...prevState,
			propspecto: {
				...prevState.propspecto,
				captchaToken: value,
			},
		}));
	}

	async function crearProspecto() {
		try {
			var res = await Api.prospectos.crear(state.propspecto);
			if (!Util.requestSuccess(res)) {
				await swal.fire("Ups!", "Error al guardar prospecto", "error");
				return;
			}
			await swal.fire({
				icon: 'success',
				html: 'Gracias por compartirnos tu idea, en breve nos comunicaremos contigo'
			});
		} catch (e) {
			console.log(e);
		}
	}

	return (
		<div className="Contact">
			<Fragment>
				<Navigation />
				<main>
					<header className="page header color-1 overlay alpha-8 image-background cover gradient gradient-43"
						style={{ "backgroundImage": "url('images/landing/bg/waves.jpg')" }}>
						<div className="divider-shape">
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none"
								className="shape-waves" style={{ "left": "0", "transform": "rotate3d(0,1,0,180deg)" }}>
								<path className="shape-fill shape-fill-1"
									d="M790.5,93.1c-59.3-5.3-116.8-18-192.6-50c-29.6-12.7-76.9-31-100.5-35.9c-23.6-4.9-52.6-7.8-75.5-5.3c-10.2,1.1-22.6,1.4-50.1,7.4c-27.2,6.3-58.2,16.6-79.4,24.7c-41.3,15.9-94.9,21.9-134,22.6C72,58.2,0,25.8,0,25.8V100h1000V65.3c0,0-51.5,19.4-106.2,25.7C839.5,97,814.1,95.2,790.5,93.1z" />
							</svg>
						</div>
						<div className="container pb-9">
							<div className="row">
								<div className="col-md-6">
									<h1 className="regular display-4 color-1 mb-4">Escríbenos</h1>
									<p className="lead color-1">Ponte en contacto y dinos cómo podemos ayudarte. Rellena el
										formulario y nos pondremos en contacto lo antes posible.</p>
								</div>
							</div>
						</div>
					</header>
					<section className="section mt-7n">
						<div className="container bring-to-front pt-0">
							<div className="row align-items-center gap-y">
								<div className="col-md-6">
									<div className="shadow bg-1 p-4 rounded">
										<form className="form form-contact" name="form-contact" data-response-message-animation="slide-in-up">
											<div className="form-group">
												<label htmlFor="contact_email" className="bold mb-0">Nombre</label>
												<input type="text" id="nombre" className="form-control bg-1" placeholder="Nombre"
													onChange={handleChange} required />
											</div>
											<div className="form-group">
												<label htmlFor="contact_email" className="bold mb-0">Organización</label>
												<input type="text" id="organizacion" className="form-control bg-1"
													placeholder="Organización" onChange={handleChange} required />
											</div>
											<div className="form-group">
												<label htmlFor="contact_email" className="bold mb-0">Correo</label>
												<small id="emailHelp" className="form-text color-2 mt-0 mb-2 italic">Nunca
													compartiremos su correo electrónico con nadie más.</small>
												<input type="email" name="Contact[email]" id="email"
													className="form-control bg-1" placeholder="Email" onChange={handleChange} required />
											</div>
											<div className="form-group">
												<label htmlFor="contact_email" className="bold mb-0">Teléfono</label>
												<input type="text" name="Contact[email]" id="telefono"
													className="form-control bg-1" placeholder="Teléfono" onChange={handleChange} required />
											</div>
											<div className="form-group">
												<label htmlFor="contact_email" className="bold">¿Te interesa alguno de nuestros
													productos?</label>
												<select id="producto" className="form-control bg-1" onChange={handleChange} style={{ height: "50px" }} value={state.productoSeleccionado} defaultChecked={true}>
													<option value="archivos">Seguridad de Archivos</option>
													<option value="nft">Tokens no fungibles (NFT)</option>
													<option value="consultoria">Consultoría y desarrollo</option>
													<option value="suministro">Seguimiento en suministros/cadenas de producción
													</option>
													<option value="donaciones">Seguimiento de donaciones</option>
													<option value="gobierno">Gobierno Digital</option>


													<option value="n/a">Por ahora no, sólo quiero saber más</option>
												</select>
											</div>
											<div className="form-group">
												<label htmlFor="contact_email" className="bold">Cuéntanos como podemos ayudarte a
													mejorar el mundo</label>
												<textarea name="Contact[message]" id="idea" className="form-control bg-1"
													placeholder="Aquí escribe lo que quieras" onChange={handleChange} rows="8" required></textarea>
											</div>
											<p className="color-2 mb-4"><input type="checkbox" name="suscripcion_blog"
												id="suscripcion_blog" defaultChecked={true} /> Subscribirse al blog de LifeChain</p>
											<div className="form-group">
												<ReCAPTCHA
													ref={recaptchaRef}
													onChange={onChangeCaptcha}
													sitekey={Settings.captcha_key}
												/>
												<button onClick={crearProspecto} data-loading-text="Sending..." name="submit"
													type="submit" className="btn btn-accent btn-rounded">Enviar Mensaje</button>
											</div>
										</form>
										<div className="response-message">
											<div className="section-heading">
												<i className="fas fa-check font-lg"></i>
												<p className="font-md m-0">¡Gracias!</p>
												<p className="response">Tu mensaje ha sido enviado, nos pondremos en contacto
													contigo lo antes posible.</p>
											</div>
										</div>
									</div>
								</div>
								<div className="col-md-5 ml-md-auto">
									<div className="section-heading">
										<h2>Más información por acá...</h2>
									</div>
									<div className="media">
										<i className="fas fa-map-marker font-l color-3 mr-3"></i>
										<div className="media-body">Santa fé, CDMX
											<span className="d-block">México</span>
										</div>
									</div>
									<div className="media my-4">
										<i className="fas fa-phone font-l color-3 mr-3"></i>
										<div className="media-body">
											<span className="d-block">
												<a href="tel:+521-3322-536-790">(+521) 3322-536-790</a>
											</span>
											<span className="d-block">
												<a href="tel:+55-8503-2600">(55) 8503-2600 Ext. 43373</a>
											</span>
										</div>
									</div>
									<div className="media">
										<i className="fas fa-envelope font-l color-3 mr-3"></i>
										<div className="media-body">
											<a href="mailto:goretti@lifechain.mx">goretti@lifechain.mx</a>
										</div>
									</div>
									<hr className="my-4" />
									<nav className="nav justify-content-center justify-content-md-start">
										<a href="https://www.facebook.com/SeguimientoBlockchain"
											target="_blank" rel="noreferrer"
											className="btn btn-circle btn-sm brand-facebook mr-3">
											<i className="fab fa-lg fa-facebook"></i>
										</a>
										<a href="https://www.instagram.com/lifechain.mx/"
											target="_blank" rel="noreferrer"
											className="btn btn-circle btn-sm brand-instagram mr-3">
											<i className="fab fa-lg fa-instagram"></i>
										</a>
										<a href="https://www.youtube.com/channel/UCnaTcvaVLg6e8JAPVJiPgVg"
											target="_blank" rel="noreferrer"
											className="btn btn-circle btn-sm brand-youtube mr-3">
											<i className="fab fa-lg fa-youtube"></i>
										</a>
										<a href="https://www.linkedin.com/company/lifechain-cadena-de-vida/"
											target="_blank" rel="noreferrer"
											className="btn btn-circle btn-sm brand-linkedin mr-3">
											<i className="fab fa-lg fa-linkedin"></i>
										</a>
										<a href="https://twitter.com/LifeChain_mx"
											target="_blank" rel="noreferrer"
											className="btn btn-circle btn-sm brand-twitter mr-3">
											<i className="fab fa-lg fa-twitter"></i>
										</a>
									</nav>
								</div>
							</div>
						</div>
					</section>
				</main>
				<FooterLanding />
			</Fragment>
		</div>
	);
}

export default Contact;
