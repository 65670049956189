import React, { useState } from "react";
import Util from "../util/util";
import Api from "../util/api";
import Settings from "../config/settings";
import swal from 'sweetalert2';
import useLoading from "../hooks/useLoading";


function CtlDocumento(props) {
  const { startLoading, stopLoading } = useLoading();
  const [state, setState] = useState({
    documento: props.documento,
  });

  async function handleChangePrivacity(e) {
    console.log(state.documento.privado);
    console.log(!state.documento.privado);
    startLoading();
    try {
      var res = await Api.documentos.cambiarPrivacidad(props.documento._id, {
        privado: !state.documento.privado,
      });
      console.log(res);
      if (!await Util.requestSuccess(res)) {
        stopLoading();
        await swal.fire("¡Ups!", "no se pudo actualizar la privacidad de tu archivo", "error");
        return;
      }
      setState((prevState) => ({
        ...prevState,
        documento: {
          ...prevState.documento,
          privado: !prevState.documento.privado,
        },
      }));
      stopLoading();
      await swal.fire({
        icon: 'success',
        html: "La privacidad de tu archivo fue actualizada"
      });
    } catch (e) {
      console.log(e);
      stopLoading();
      await swal.fire("¡Ups!", "no se pudo actualizar la privacidad de tu archivo", "error");
    }
    stopLoading();
  }
  async function renderPdf() {
    console.log(props.documento);
    try {
      startLoading();
      var res = await Api.documentos.getUrl(props.documento._id);
      var data = await res.json();
      if (!await Util.requestSuccess(res)) {
        stopLoading();
        await swal.fire("¡Ups!", "Error al consultar URL", "error");
        return;
      }
      console.log(data);
      props.openModalDocument(data.url, props.documento.mime);
    } catch (e) {
      stopLoading();
      await swal.fire("¡Ups!", "Error al consultar URL", "error");
    }
    stopLoading();
  }

  async function renderPdfCert() {
    console.log(props.documento);
    try {
      startLoading();
      var res = await Api.documentos.getCertUrl(props.documento._id);
      var data = await res.json();
      if (!await Util.requestSuccess(res)) {
        stopLoading();
        await swal.fire("¡Ups!", "Error al consultar certificado, si tu archivo fué certificado antes de octubre de 2022, es posible que el certificado este anexo a tu archivo", "error");
        return;
      }
      console.log(data);
      props.openModalDocument(data.url, props.documento.mime);
    } catch (e) {
      stopLoading();
      await swal.fire("¡Ups!", "Error al consultar URL", "error");
    }
    stopLoading();
  }

  var scanerURL;
  if (props.documento.blockchain === "Polygon") {
    scanerURL = Settings.url.etherscan_explorer;
  } else scanerURL = Settings.url.lacchain_explorer;
  return (
    <div className="CtlDocumento">
      <div className="row stdBorder">
        <div className="col-md-2 col-xs-12">
          <p>
            {props.documento &&
              props.documento._id}
          </p>
        </div>
        <div className="col-md-2 col-xs-12">
          <p>
            {props.documento &&
              Util.formatDateShort(props.documento.fecha_certificacion)}
            {props.documento && props.documento.vigencia && " al "}
            {props.documento && Util.formatDateShort(props.documento.vigencia)}
          </p>
        </div>
        <div className="col-md-2 col-xs-12">
          <p>{props.documento && props.documento.nombre}</p>
        </div>
        <div className="col-md-2 col-xs-12">
          <p>
            {props.documento && props.documento.fileHash.substring(0, 15)}...
          </p>
        </div>
        <div className="col-md-1 col-sm-4 mb-3 mt-3">
          <a onClick={renderPdf} href=" #"><i className="icon fas fa-file center-xy color-2"></i></a>
        </div>
        <div className="col-md-1 col-sm-4 mb-3 mt-3">
          {props.documento?.certificadoPDFUrl ?
            <a onClick={renderPdfCert} href=" #"><i className="icon fas fa-stamp center-xy color-2"></i></a> :
            <p>Pendiente</p>
          }
        </div>
        <div className="col-md-1 col-sm-4 mb-3 mt-3">
          {props.documento?.txHash ? (
            <a
              target="_blank"
              rel="noreferrer"
              href={scanerURL + "/tx/" + props.documento.txHash}
            ><i className="icon fas fa-link center-xy color-2"></i></a>
          ) : <p>Pendiente</p>}
        </div>
        <div className="col-md-1 col-sm-12">
          {props.admin && (
            <input
              id="certificadoPrivado'+i+'"
              type="checkbox"
              onChange={handleChangePrivacity}
              checked={state.documento.privado}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default CtlDocumento;
