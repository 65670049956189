import React, { useEffect, Fragment } from "react";
import Navigation from "../../components/Navigation";
import FooterLanding from "../../components/FooterLanding";

function Blog() {
	useEffect(() => {
		document.title = "LifeChain - Blog";
	});

	return (
		<div className="Blog">
			<Fragment>
				<Navigation />
				<main>
					<header className="page header color-1 overlay gradient gradient-43 alpha-8 image-background cover"
						style={{ "backgroundImage": "url('/images/landing/bg/waves.jpg')" }}>
						<div className="container">
							<div className="row">
								<div className="col-md-6">
									<h1 className="display-4 color-1 mb-4">LifeChain Blog</h1>
									<p className="lead color-1">Tips, consejos y tecnologías que te ayudarán a mejorar tu
										organización</p>
								</div>
							</div>
						</div>
					</header>
					<section className="section">
						<div className="container">
							<div className="row gap-y">
								<div className="col-md-6 col-lg-4">
									<div className="card card-blog shadow-box shadow-hover border-0">
										<a href="/blog/nft-criptoarte">
											<img className="card-img-top img-responsive" src="/images/blog/ntf/indice.jpeg" alt="" />
										</a>
										<div className="card-body">
											<div className="d-flex align-items-center justify-content-between">
												<div className="author d-flex align-items-center">
													<img className="author-picture rounded-circle icon-md shadow-box"
														src="/images/landing/avatar/team/alex-profile.jpg" alt="..." />
													<p className="small bold my-0">Alejandro López</p>
												</div>
											</div>
											<hr />
											<p className="card-title regular">
												<a href="/blog/nft-criptoarte">NFT, IPFS y
													Criptoarte</a>
											</p>
											<p className="card-text color-2">Muy probablemente has escuchado hablar de los NFT o el
												criptoarte, pero ¿qué es un NFT? Empecemos por poner todo en contexto.</p>
											<p className="bold small color-2 my-0">
												<small>6 Abr 2021</small>
											</p>
										</div>
									</div>
								</div>
								<div className="col-md-6 col-lg-4">
									<div className="card card-blog shadow-box shadow-hover border-0">
										<a href="/blog/como-proteger-certificados-de-vacunacion">
											<img className="card-img-top img-responsive" src="/images/blog/vacunacion/indice.jpeg"
												alt="" />
										</a>
										<div className="card-body">
											<div className="d-flex align-items-center justify-content-between">
												<div className="author d-flex align-items-center">
													<img className="author-picture rounded-circle icon-md shadow-box"
														src="/images/landing/avatar/team/goretti-profile.jpg" alt="..." />
													<p className="small bold my-0">Goretti Oronia</p>
												</div>
											</div>
											<hr />
											<p className="card-title regular">
												<a href="/blog/como-proteger-certificados-de-vacunacion">Como proteger los
													certificados de
													vacunación de COVID-19</a>
											</p>
											<p className="card-text color-2">Gracias a la emergencia sanitaria del COVID-19 y las
												primeras etapas de vacunación, es necesario considerar que serán expedidos
												certificados
												o comprobantes de vacunación, los cuales entran en la lista de documentos
												oficiales que
												necesitan usar lo último en tecnología para protegerlos de falsificaciones.</p>
											<p className="bold small color-2 my-0">
												<small>28 Ene 2021</small>
											</p>
										</div>
									</div>
								</div>
								<div className="col-md-6 col-lg-4">
									<div className="card card-blog shadow-box shadow-hover border-0">
										<a href="/blog/investigacion-donadores">
											<img className="card-img-top img-responsive"
												src="/images/blog/investigacion_donadores/portada.jpg" alt="" />
										</a>
										<div className="card-body">
											<div className="d-flex align-items-center justify-content-between">
												<div className="author d-flex align-items-center">
													<img className="author-picture rounded-circle icon-md shadow-box"
														src="/images/landing/avatar/team/goretti-profile.jpg" alt="..." />
													<p className="small bold my-0">Goretti Oronia</p>
												</div>
											</div>
											<hr />
											<p className="card-title regular">
												<a href="/blog/investigacion-donadores">Resultados de investigación de mercados
													a DONADORES Estudio de percepción</a>
											</p>
											<p className="card-text color-2">Te compartimos una investigación que realizamos para
												ver si nuestra solución podría ayudar realmente a las fundaciones, dejando aun
												lado las suposiciones y explorando datos duros.</p>
											<p className="bold small color-2 my-0">
												<small>10 Nov 2020</small>
											</p>
										</div>
									</div>
								</div>
								<div className="col-md-6 col-lg-4">
									<div className="card card-blog shadow-box shadow-hover border-0">
										<a href="/blog/cadena-suministro">
											<img className="card-img-top img-responsive"
												src="/images/blog/suministro/blockchain.jpg" alt="" />
										</a>
										<div className="card-body">
											<div className="d-flex align-items-center justify-content-between">
												<div className="author d-flex align-items-center">
													<img className="author-picture rounded-circle icon-md shadow-box"
														src="/images/landing/avatar/team/goretti-profile.jpg" alt="..." />
													<p className="small bold my-0">Goretti Oronia</p>
												</div>
											</div>
											<hr />
											<p className="card-title regular">
												<a href="/blog/cadena-suministro">Blockchain: revolucionando la cadena de
													suministro</a>
											</p>
											<p className="card-text color-2">¿Conoces de una manera confiable todo el proceso por el
												que pasó un producto? ¿De dónde provino una pieza defectuosa o quién fue el
												responsable?
												¿Cómo aseguras la calidad del manejo y procesamiento de los productos?</p>
											<p className="bold small color-2 my-0">
												<small>20 Ago 2020</small>
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
				</main>
				<FooterLanding />
			</Fragment>
		</div>
	);
}

export default Blog;