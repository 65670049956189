import React from "react";
function FooterLanding() {

    return (
        <footer className="site-footer section">
            <div className="container pb-3">
                <div className="row gap-y text-center text-md-left">
                    <div className="col-md-4 mr-auto">
                        <img src="images/landing/logo.png" alt="" className="logo" />
                        <p>
                            Empresa que asesora, desarrolla y brinda servicios de Blockchain,
                            forman parte de la red <a href="https://www.lacchain.net" target="_blank" rel="noreferrer">LACChain</a>,
                            liderada por el <a href="https://bidlab.org/es" target="_blank" rel="noreferrer">BID Lab</a>, con el objetivo
                            de desarrollar el ecosistema blockchain para América Latina y el Caribe.
                        </p>
                        <nav className="nav justify-content-center justify-content-md-start">
                            <a href="https://www.facebook.com/SeguimientoBlockchain"
                                target="_blank" rel="noreferrer"
                                className="btn btn-circle btn-sm brand-facebook mr-3">
                                <i className="fab fa-lg fa-facebook"></i>
                            </a>
                            <a href="https://www.instagram.com/lifechain.mx/"
                                target="_blank" rel="noreferrer"
                                className="btn btn-circle btn-sm brand-instagram mr-3">
                                <i className="fab fa-lg fa-instagram"></i>
                            </a>
                            <a href="https://www.youtube.com/channel/UCnaTcvaVLg6e8JAPVJiPgVg"
                                target="_blank" rel="noreferrer"
                                className="btn btn-circle btn-sm brand-youtube mr-3">
                                <i className="fab fa-lg fa-youtube"></i>
                            </a>
                            <a href="https://www.linkedin.com/company/lifechain-cadena-de-vida/"
                                target="_blank" rel="noreferrer"
                                className="btn btn-circle btn-sm brand-linkedin mr-3">
                                <i className="fab fa-lg fa-linkedin"></i>
                            </a>
                            <a href="https://twitter.com/LifeChain_mx"
                                target="_blank" rel="noreferrer"
                                className="btn btn-circle btn-sm brand-twitter mr-3">
                                <i className="fab fa-lg fa-twitter"></i>
                            </a>
                        </nav>
                    </div>
                    <div className="col-md-2">
                        <h6 className="py-2 bold">Compañia</h6>
                        <nav className="nav flex-column">
                            <a className="nav-item py-2" href="/about">
                                Nosotros
                            </a>
                            <a className="nav-item py-2" href="/contact">
                                Contacto
                            </a>
                            <a className="nav-item py-2" href="/blog">Blog</a>
                        </nav>
                    </div>
                    <div className="col-md-2">
                        <h6 className="py-2 bold">Legal</h6>
                        <nav className="nav flex-column">
                            <a className="nav-item py-2" href="/terminos-condiciones">Terminos y condiciones</a>
                            {/*<a className="nav-item py-2" href="#">Preguntas frecuentes</a>*/}
                            <a className="nav-item py-2" href="/politica-privacidad">Aviso de privacidad</a>
                        </nav>
                    </div>
                </div>
            </div>
            <hr className="mt-5" />
            <div className="row small align-items-center">
                <div className="col-md-12">
                    <p className="mt-2 color-2 text-center" style={{ width: "100%" }}>
                        © 2022 LifeChain. Todos los derechos reservados
                    </p>
                </div>

            </div>
        </footer>
    );
}

export default FooterLanding;
