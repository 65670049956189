import React, { useEffect, Fragment } from "react";
import Navigation from "../../components/Navigation";
import FooterLanding from "../../components/FooterLanding";

function About() {
  useEffect(() => {
    document.title = "LifeChain - Nosotros";
  });

  return (
    <div className="About">
      <Fragment>
        <Navigation />
        <main>
          <header
            className="page header color-1 overlay alpha-8 image-background cover gradient gradient-43"
            style={{ backgroundImage: "url('images/landing/bg/waves.jpg')" }}
          >
            <div className="divider-shape">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1000 100"
                preserveAspectRatio="none"
                className="shape-waves"
                style={{ left: 0, transform: "rotate3d(0,1,0,180deg)" }}
              >
                <path
                  className="shape-fill shape-fill-1"
                  d="M790.5,93.1c-59.3-5.3-116.8-18-192.6-50c-29.6-12.7-76.9-31-100.5-35.9c-23.6-4.9-52.6-7.8-75.5-5.3c-10.2,1.1-22.6,1.4-50.1,7.4c-27.2,6.3-58.2,16.6-79.4,24.7c-41.3,15.9-94.9,21.9-134,22.6C72,58.2,0,25.8,0,25.8V100h1000V65.3c0,0-51.5,19.4-106.2,25.7C839.5,97,814.1,95.2,790.5,93.1z"
                />
              </svg>
            </div>
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <h1 className="regular display-4 color-1 mb-4">
                    ¿Quiénes somos?
                  </h1>
                  <p className="lead color-1">¿Qué hacemos y por qué?</p>
                </div>
              </div>
            </div>
          </header>
          <section className="section overview">
            <div className="container">
              <div className="row align-items-center gap-y">
                <div className="col-lg-5 mr-auto text-center text-md-left">
                  <div className="section-heading">
                    <h2>LifeChain</h2>
                    <p className="lead color-2">
                      Empresa que asesora, desarrolla y brinda servicios de Blockchain,
                      forman parte de la red <a href="https://www.lacchain.net" target="_blank" rel="noreferrer">LACChain</a>,
                      liderada por el <a href="https://bidlab.org/es" target="_blank" rel="noreferrer">BID Lab</a>, con el objetivo
                      de desarrollar el ecosistema blockchain para América Latina y el Caribe.
                    </p>
                  </div>
                  <p>
                    ¡Este es nuestro ADN, del cual queremos contagiar a todos!
                  </p>
                </div>
                <div className="col-lg-6">
                  <div className="row gap-y">
                    <div className="col-6 col-sm-5 col-md-6 mt-6 ml-lg-auto">
                      <div
                        className="rounded p-2 p-sm-4 shadow text-center text-md-left"
                        data-aos="fade-up"
                      >
                        <i className="pe pe-3x pe-7s-rocket color-3"></i>
                        <p className="bold mt-3">INNOVACIÓN</p>
                        <p className="op-7 small">
                          Buscar siempre la mejora continua y el uso de las
                          tecnologías emergentes, explorar e imaginar las
                          múltiples posibilidades.
                        </p>
                      </div>
                    </div>
                    <div className="col-6 col-sm-5 col-md-6 mr-lg-auto">
                      <div
                        className="rounded p-2 p-sm-4 shadow text-center text-md-left"
                        data-aos="fade-up"
                      >
                        <i className="fas pe-3x  fa-shoe-prints color-3"></i>
                        <p className="bold mt-3">EMPATÍA</p>
                        <p className="op-7 small">
                          Ponerse en los zapatos del otro es de vital
                          importancia para nosotros, solo así podemos asesorar
                          de la mejor manera.
                        </p>
                      </div>
                    </div>
                    <div className="col-6 col-sm-5 col-md-6 ml-lg-auto">
                      <div
                        className="rounded p-2 p-sm-4 shadow text-center text-md-left"
                        data-aos="fade-up"
                      >
                        <i className="pe pe-3x pe-7s-safe color-3"></i>
                        <p className="bold mt-3">CONFIANZA</p>
                        <p className="op-7 small">
                          Garantizar la seguridad en los servicios que
                          ofrecemos. Diseñar y ejecutar proyectos apegados a la
                          calidad que el cliente espera recibir de nosotros.
                        </p>
                      </div>
                    </div>
                    <div className="col-6 col-sm-5 col-md-6 mt-6n mr-lg-auto">
                      <div
                        className="rounded p-2 p-sm-4 shadow text-center text-md-left"
                        data-aos="fade-up"
                      >
                        <i className="pe pe-3x pe-7s-magic-wand color-3"></i>
                        <p className="bold mt-3">INSPIRACIÓN</p>
                        <p className="op-7 small">
                          Queremos que todas las personas que tengan un sueño,
                          puedan hacerlo realidad y demostrar que todo es
                          posible con trabajo y pasión.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="section">
            <div className="container bring-to-front">
              <div
                className="shadow rounded text-center overlay overlay-5 alpha-8 color-1 p-5 image-background cover"
                style={{
                  backgroundImage:
                    "url(https://picsum.photos/350/200/?random&gravity=south)",
                }}
              >
                <div className="content">
                  <div className="section-heading">
                    <h2 className="color-1">Así comenzó esta aventura</h2>
                  </div>
                  <p className="handwritten highlight font-md">
                    Dale click al video
                  </p>
                  <a
                    href="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Fwatch%2F%3Fv%3D2194542147228490&width=500&show_text=false&height=280&appId"
                    className="modal-popup mfp-iframe btn btn-circle btn-lg btn-accent"
                    data-effect="mfp-fade"
                  >
                    <i className="pe pe-7s-play pe-2x ml-2"></i>
                  </a>
                </div>
              </div>
            </div>
          </section>
          <section className="section bg-6 edge top-left">
            <div className="container bring-to-front">
              <div className="section-heading text-center">
                <h2>Nuestra Esencia</h2>
                <p className="lead">
                  Un día nos preguntamos porque emprender. Esto fué el
                  resultado:
                </p>
              </div>
              <div className="row align-items-center gap-y">
                <div className="col-md-6 col-lg-5 text-center text-md-left">
                  <ul className="list-unstyled">
                    <li className="media d-block d-lg-flex">
                      <i className="pe pe-3x pe-7s-smile accent icon-lg mr-3 text-lg-right"></i>
                      <div className="media-body mt-3 mt-lg-0 text-center text-md-left">
                        <h5 className="bold color-4">¿Por qué lo hacemos?</h5>
                        <p className="mt-0 mb-5">
                          {" "}
                          Queremos hacer un cambio, generar impacto positivo a nuestras familias, amigos y entorno, ayudando a la evolución humana.
                        </p>
                      </div>
                    </li>
                    <li className="media d-block d-lg-flex">
                      <i className="pe pe-3x pe-7s-science accent icon-lg mr-3 text-lg-right"></i>
                      <div className="media-body mt-3 mt-lg-0 text-center text-md-left">
                        <h5 className="bold color-4">¿Cómo lo hacemos?</h5>
                        <p className="mt-0 mb-5">
                          Con pasión, alianzas estratégicas, el uso de tecnología disruptiva y el pensamiento creativo.
                        </p>
                      </div>
                    </li>
                    <li className="media d-block d-lg-flex">
                      <i className="pe pe-3x pe-7s-map-2 accent icon-lg mr-3 text-lg-right"></i>
                      <div className="media-body mt-3 mt-lg-0 text-center text-md-left">
                        <h5 className="bold color-4">¿Qué hacemos?</h5>
                        <p className="mt-0">
                          Damos seguridad, confianza y transparencia con el uso de blockchain, asesorando y acercando la tecnología a todas las industrias
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="col-md-6 col-lg-5 ml-md-auto">
                  <div
                    className="card rounded p-2 p-sm-4 shadow "
                    data-aos="fade-up"
                  >
                    <img
                      alt="LifeChain Premio"
                      src="images/landing/bg/golden.png"
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="section bg-6">
            <div className="container bring-to-front pt-0 pb-5">
              <div className="section-heading text-center">
                <h2>Conoce al Equipo</h2>
                <p className="lead color-2">
                  No somos muchos pero trabajamos con pasión, cuestionamos todo
                  y nos divertimos en el camino, queremos no quedarnos con el
                  talvez o solo quejarnos sin intentar hacer un verdadero cambio
                  ¡ÚNETE A NUESTRA LUCHA!
                </p>
              </div>
              <div
                className="swiper-container pb-5"
                data-sw-centered-slides="false"
                data-sw-show-items="2"
                data-sw-space-between="30"
                data-sw-breakpoints='{"576": {"slidesPerView": 1, "spaceBetween": 10, "slidesPerGroup": 1}}'
              >
                <div className="swiper-wrapper">
                  <div className="swiper-slide">
                    <div className="card shadow">
                      <div className="container-fluid py-0">
                        <div className="row">
                          <div
                            className="col-md-5 py-9 py-sm-7 overlay overlay-5 alpha-2 image-background cover center-top"
                            style={{
                              backgroundImage:
                                "url(images/landing/avatar/team/1.jpg)",
                            }}
                          ></div>
                          <div className="col-md-7">
                            <div className="p-4">
                              <h6 className="bold font-l">
                                Alejandro López Ochoa
                              </h6>
                              <p className="small mt-0 accent text-uppercase mb-5">
                                Co-Founder DEVELOPER
                              </p>
                              <blockquote className="team-quote pt-1">
                                <p className="italic pl-4">
                                  Ing. en computación por la Universidad de
                                  Guadalajara, desarrollador de apps basadas en
                                  blockchain, fanático y partidario de la
                                  Inteligencia Artificial.{" "}
                                </p>
                              </blockquote>
                              <hr className="w-25 mt-5" />
                              <nav className="nav">
                                <a
                                  href="https://www.linkedin.com/in/alejandro-l%C3%B3pez-ochoa-04118b16a/"
                                  className="nav-item nav-link pb-0"
                                >
                                  <i className="fab fa-linkedin color-2"></i>
                                </a>
                              </nav>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="card shadow">
                      <div className="container-fluid py-0">
                        <div className="row">
                          <div
                            className="col-md-5 py-9 py-sm-7 overlay overlay-5 alpha-2 image-background cover center-top"
                            style={{
                              backgroundImage:
                                "url(images/landing/avatar/team/2.jpg)",
                            }}
                          ></div>
                          <div className="col-md-7">
                            <div className="p-4">
                              <h6 className="bold font-l">
                                Goretti Oronia Tamayo
                              </h6>
                              <p className="small mt-0 accent text-uppercase mb-5">
                                Co-Founder MARKETING
                              </p>
                              <blockquote className="team-quote pt-1">
                                <p className="italic pl-4">
                                  Lic. en Mercadotecnia con especialidad en
                                  investigación de mercados, por la Universidad
                                  de Guadalajara, traductora y evangelista de
                                  blockchain.
                                </p>
                              </blockquote>
                              <hr className="w-25 mt-5" />
                              <nav className="nav">
                                <a
                                  href="https://www.linkedin.com/in/goretti-citlady-oronia-tamayo-8174b1108/"
                                  className="nav-item nav-link pb-0"
                                >
                                  <i className="fab fa-linkedin color-2"></i>
                                </a>
                              </nav>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="swiper-button-next"></div>
                  <div className="swiper-button-prev"></div>
                  <div className="swiper-pagination"></div>
                </div>
              </div>
            </div>
          </section>
          <section className="section overflow-hidden" hidden={true}>
            <div className="container bring-to-front">
              <div className="row gap-y align-items-center">
                <div className="col-md-6 col-lg-5 mr-lg-auto">
                  <div className="center-xy op-1">
                    <div className="shape shape-background rounded-circle shadow-lg bg-4" style={{ width: "600px", height: "600px" }} data-aos="zoom-in"></div>
                  </div>
                  <div className="device-twin align-items-center">
                    <div className="mockup absolute" data-aos="fade-left">
                      <div className="screen">
                        <img src="img/screens/app/1.png" alt="..." />
                      </div>
                      <span className="button"></span>
                    </div>
                    <div className="iphone-x front mr-0">
                      <div className="screen shadow-box">
                        <img src="img/screens/app/2.png" alt="..." />
                      </div>
                      <div className="notch"></div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 text-center text-md-left">
                  <div className="section-heading">
                    <i className="fas fa-trophy fa-2x text-danger mb-3"></i>
                    <h2 className="bold font-md">Premios</h2>
                    <p>Hemos sido ganadores de algunos reconocimientos por sus proyectos basados en blockchain y tecnologías emergentes.</p>
                  </div>
                  <div className="row gap-y">
                    <div className="col-md-6">
                      <div className="media flex-column flex-lg-row align-items-center align-items-md-start">
                        <i className="mx-auto ml-md-0 mr-md-3 accent pe pe-7s-plugin pe-3x"></i>
                        <div className="media-body mt-3 mt-md-0">
                          <h5 className="bold mt-0 mb-1">Hackathon en Talent Land 2018</h5>
                          <p className="m-0 d-md-none d-lg-block">Donde se propuso votaciones en blockchain para propuestas de proyectos gubernamentales 
                          por medio de contratos inteligentes, así como su seguimiento y transparencia de impuestos dentro de la misma red.</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="media flex-column flex-lg-row align-items-center align-items-md-start">
                        <i className="mx-auto ml-md-0 mr-md-3 accent pe pe-7s-diamond pe-3x"></i>
                        <div className="media-body mt-3 mt-md-0">
                          <h5 className="bold mt-0 mb-1">Empowerment and Solidarity in Action</h5>
                          <p className="m-0 d-md-none d-lg-block">Destacando dentro de más de 12k participantes de más de 100 países. Cubierto por Forbes, 
                          Silicon Republic y CNN, con mentores de clase mundial, como Sam Altman, CEO de OpenAI, ex presidente de Estonia Toomas Hendrik Ilves.</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="media flex-column flex-lg-row align-items-center align-items-md-start">
                        <i className="mx-auto ml-md-0 mr-md-3 accent pe pe-7s-magic-wand pe-3x"></i>
                        <div className="media-body mt-3 mt-md-0">
                          <h5 className="bold mt-0 mb-1">Reto BlockchAngel</h5>
                          <p className="m-0 d-md-none d-lg-block">LACChain, el BID Lab y la Fundación Everis proponiendo la tecnología blockchain para 
                          registrar la violencia, acoso y abuso a mujeres, niños y ancianos en la región de América Latina y el Caribe.</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="media flex-column flex-lg-row align-items-center align-items-md-start">
                        <i className="mx-auto ml-md-0 mr-md-3 accent pe pe-7s-timer pe-3x"></i>
                        <div className="media-body mt-3 mt-md-0">
                          <h5 className="bold mt-0 mb-1">Derechos Humanos 2019 por Artículo 19</h5>
                          <p className="m-0 d-md-none d-lg-block">Se creó un sistema de seguimiento a casos impunes en México, 
                          enfocado a periodistas agredidos y amenazados de muerte.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="section" hidden={true}>
            <div className="shape-wrapper">
              <div className="shape-ring absolute right top" data-aos="fade-up"></div>
            </div>
            <div className="container bring-to-front">
              <div className="row gap-y align-items-center text-center text-lg-left">
                <div className="col-md-6 col-lg-5 ml-lg-auto order-md-2">
                  <div className="device-twin align-items-center">
                    <div className="mockup absolute right" data-aos="fade-right">
                      <div className="screen">
                        <img src="img/screens/app/1.png" alt="..." />
                      </div>
                      <span className="button"></span>
                    </div>
                    <div className="iphone-x front ml-0">
                      <div className="screen shadow-box">
                        <img src="img/screens/app/2.png" alt="..." />
                      </div>
                      <div className="notch"></div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 text-center text-md-left">
                  <div className="section-heading">
                    <i className="fas fa-trophy fa-2x text-danger mb-3"></i>
                    <h2 className="bold font-md">Mas premios</h2>
                  </div>
                  <ul className="list-unstyled">
                    <li className="media flex-column flex-md-row">
                      <i className="mx-auto mr-md-3 mx-auto mr-md-3 accent pe pe-7s-mail pe-3x"></i>
                      <div className="media-body mt-3 mt-md-0">
                        <h5 className="bold mt-0 mb-1">Hacklab anticorrupción 2019</h5>
                        <p className="m-0">Creamos un sistema de elecciones para integrantes de CPC donde un contrato inteligente elegía de manera autónoma y transparente al mejor candidato.</p>
                      </div>
                    </li>
                    <li className="media flex-column flex-md-row mt-3">
                      <i className="mx-auto mr-md-3 mx-auto mr-md-3 accent pe pe-7s-users pe-3x"></i>
                      <div className="media-body mt-3 mt-md-0">
                        <h5 className="bold mt-0 mb-1">Women that Build Awards - 2021</h5>
                        <p className="m-0">Categoría &quot;Rising Star&quot; - Mujeres talentosas con menos de 10 años de experiencia que estén iniciando una carrera en la industria tecnológica o utilizando la tecnología para generar un impacto positivo.</p>
                      </div>
                    </li>
                    <li className="media flex-column flex-md-row mt-3">
                      <i className="mx-auto mr-md-3 mx-auto mr-md-3 accent pe pe-7s-graph1 pe-3x"></i>
                      <div className="media-body mt-3 mt-md-0">
                        <h5 className="bold mt-0 mb-1">Women that Build Awards - 2022</h5>
                        <p className="m-0">Categoría “Tech Entrepreneur” - Mujeres disruptivas que fundaron o forman parte del directorio de una startup de base tecnológica o de impacto con al menos 3 años de funcionamiento.</p>
                      </div>
                    </li>
                    <li className="media flex-column flex-md-row mt-3">
                      <i className="mx-auto mr-md-3 mx-auto mr-md-3 accent pe pe-7s-graph1 pe-3x"></i>
                      <div className="media-body mt-3 mt-md-0">
                        <h5 className="bold mt-0 mb-1">Hackathon Campus Party 2016</h5>
                        <p className="m-0">Creamos un asistente virtual de construcción que usando machine learning recomendaciones para la Autoconstrucción y uso de materiales en diversos entornos y ambientes a personas que vivan en situaciones de pobreza urbana, con carencias de vivienda, sin los recursos para contratar especialistas.</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          {/*<!-- <section className="section bg-6">
			<div className="container bring-to-front py-0">
				<div className="shadow bg-1 p-5 rounded">
					<div className="row gap-y align-items-center text-center text-lg-left">
						<div className="col-12 col-md-6 py-4 px-5 b-md-r">
							<i className="pe pe-7s-science pe-3x color-3"></i>
							<a href="javascript:;" className="mt-4 color-3 d-flex align-items-center">
								<h4 className="mr-3">Alejandro López</h4>
								<i className="fas fa-long-arrow-alt-right"></i>
							</a>
							<p className="mt-4">Ing. en computación por la Universidad de Guadalajara, desarrollador de apps basadas en blockchain,  fanático y partidario de la Inteligencia Artificial. Experiencia en desarrollo de apps móviles y codificación de redes neuronales con algoritmos bio inspirados para la resolución de problemas con grandes volúmenes de datos.</p>
						</div>
						<div className="col-12 col-md-6 py-4 px-5">
							<i className="pe pe-7s-diamond pe-3x color-4"></i>
							<a href="javascript:;" className="mt-4 color-4 d-flex align-items-center">
								<h4 className="mr-3">Goretti Oronia</h4>
								<i className="fas fa-long-arrow-alt-right"></i>
							</a>
							<p className="mt-4">Lic. en Marketing con especialidad en investigación de mercados, por la Universidad de Guadalajara, técnica en comunicación por la Universidad Cuauhtémoc y Certificada en Marketing Inbound por hubspot academy. Experiencia en diseño de UX y edición audiovisual, programación de front end, análisis de datos y planeación estratégica de comunicación.</p>
						</div>
					</div>
				</div>
			</div>
  </section> -->*/}
          <section
            className="section bg-1 edge top-left b-b"
            style={{ display: "none" }}
          >
            <div className="container">
              <div className="row">
                <div className="col-md-6 b-md-r">
                  <h2>
                    Suscríbete a nuestro
                    <span className="bold">Newsletter</span>
                  </h2>
                </div>
                <div className="col-md-5 ml-md-auto">
                  <form
                    action="srv/register.php"
                    className="form"
                    data-response-message-animation="slide-in-left"
                  >
                    <div className="input-group">
                      <input
                        type="email"
                        name="Subscribe[email]"
                        className="form-control rounded-circle-left"
                        placeholder="Escribe tu correo electrónico"
                        required
                      />
                      <div className="input-group-append">
                        <button className="btn btn-rounded btn-4" type="submit">
                          Registrarme
                        </button>
                      </div>
                    </div>
                  </form>
                  <div className="response-message">
                    <i className="fas fa-envelope font-lg"></i>
                    <p className="font-md m-0">Checa tu correo</p>
                    <p className="response">
                      Te enviamos un correo electrónico con un enlace para
                      comenzar. Estarás en tu cuenta en un momento.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
        <FooterLanding />
      </Fragment>
    </div>
  );
}

export default About;
