import React, { useState } from "react";
import useUser from "../hooks/useUser";
function AppMenuUsuario(props) {

  const { user } = useUser();
  const [state, setState] = useState({
    display: "none",
  });
  function showUserMenu() {
    console.log("hover");
    setState({
      display: "block",
    });
  }
  function hideUserMenu() {
    console.log("not hover");
    setState({
      display: "none",
    });
  }
  return (
    <li
      className="nav-item account dropdown"
      onMouseEnter={showUserMenu}
      onMouseLeave={hideUserMenu}
    >
      <a
        href="# "
        className="nav-link dropdown-toggle pl-4"
        data-toggle="dropdown"
      >
        <img
          src={user?.foto || "/admin/user.png"}
          className="avatar user-avatar rounded-circle fotoPerfil"
          alt="..."
        />
        <i className="fas fa-angle-down"></i>
      </a>
      <div
        className="dropdown-menu dropdown-menu-right"
        style={{ display: state.display }}
      >
        <div className="dropdown-heading rounded-top bg-3 arrow top color-1">
          <div className="content py-1">
            <div className="media align-items-center">
              <img
                src={user?.foto || "/admin/user.png"}
                className="user-avatar shadow mr-2 rounded-circle fotoPerfil"
                style={{ width: "100px" }}
                alt=""
              />
              <div className="media-body">
                <span
                  className="bold color-1 user-name text-overflow"
                  id="nombreUsuario"
                >
                  {user?.nombre || "Sin sesión"}
                </span>
              </div>
            </div>
          </div>
        </div>
        <ul
          className="dropdown-body list list-group list-group-flush"
          id="menuSesionContainer"
        >
          <li className="list-group-item list-group-item-action">
            <a href="/app/mi-perfil">
              <i className="mr-3 fas fa-user"></i>Perfil
            </a>
          </li>
          {/*<!--<li className="list-group-item list-group-item-action">
								<a href="#">
									<i className="mr-3 fas fa-cogs"></i>Configuración</a>
							</li>
							<li className="list-group-item list-group-item-action">
								<a href="#">
									<i className="mr-3 fas fa-life-ring"></i>Ayuda</a>
</li>-->*/}
        </ul>
        <div className="dropdown-footer bg-1 d-flex justify-content-between align-items-center">
          <a
            href="/login"
            onClick={() => {
              localStorage.removeItem("JWT");
            }}
            className="btn btn-rounded btn-3"
            id="AbrirCerrarSesion"
          >
            <i className="mr-3 fas fa-sign-out-alt"></i>Cerrar sesión
          </a>
          {/*<!--<a href="#" className="text-danger">
								<i className="mr-3 fas fa-lock"></i>
</a>-->*/}
        </div>
      </div>
    </li>
  );
}

export default AppMenuUsuario;
