const settings = {
  env: process.env.REACT_APP_NODE_ENV,
  sendEmails: true,
  captcha_key: process.env.REACT_APP_CAPTCHA_SITE_KEY,
  auth: {
    google_oauth_client_id: process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID,
    captcha_key: process.env.REACT_APP_CAPTCHA_SITE_KEY,
  },
  url: {
    web: process.env.REACT_APP_URL_LANDING,
    api: process.env.REACT_APP_URL_API,
    etherscan_explorer: process.env.REACT_APP_URL_ETHERSCAN,
    lacchain_explorer: process.env.REACT_APP_URL_LACCHAIN_EXPLORER,
  },
  openpay:{
    id: process.env.REACT_APP_OPENPAY_ID,
    public_key: process.env.REACT_APP_OPENPAY_PUBLIC,
  }
};

module.exports = settings;