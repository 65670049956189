import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
function ViewPdf(props) {
  return (
    <div className="ViewPdf">
      <Modal {...props} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Mostrar PDF</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <object
            data={
              props.srcurl ||
              "https://s3.amazonaws.com/pesoxpesobucket/documentos/certificados/5cfb0b32dac5810049e2ef18/a44.pdf"
            }
            id="pdfViewer"
            type="application/pdf"
            width="100%"
            height="500px"
          >
            <p>
              <b>Error al mostrar archivo</b>: Este navegador no soporta PDFs.
              Por favor descarga el PDF para verlo:
              <a href={
                props.srcurl ||
                "https://s3.amazonaws.com/pesoxpesobucket/documentos/certificados/5cfb0b32dac5810049e2ef18/a44.pdf"
              } id="pdfDownload">
                Descargar PDF
              </a>
              .
            </p>
          </object>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
export default ViewPdf;
