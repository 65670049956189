var apiURL = process.env.REACT_APP_URL_API;
console.log(apiURL);

const genericRequestJson = () => {
  return {
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Authorization": localStorage.getItem("JWT"),
    },
    referrerPolicy: "no-referrer",
  };
};



const Api = {
  auth: {
    login: (data) => {
      return fetch(apiURL + "/auth/login", {
        ...genericRequestJson(),
        method: "POST",
        body: JSON.stringify(data),
      });
    },
    tokenValido: () => {
      return fetch(apiURL + "/auth/token-valido", {
        ...genericRequestJson(),
        method: "POST",
      });
    },
    forgotPass: (data) => {
      return fetch(apiURL + "/usuarios/olvide-contrasena/", {
        ...genericRequestJson(),
        method: "POST",
        body: JSON.stringify(data),
      });
    },
  },
  usuarios: {
    get: (id) => {
      return fetch(apiURL + "/usuarios/" + id, genericRequestJson());
    },
    crear: (data) => {
      return fetch(apiURL + "/usuarios/", {
        ...genericRequestJson(),
        method: "POST",
        body: JSON.stringify(data),
      });
    },
    actualizar: (data, id) => {
      return fetch(apiURL + "/usuarios/" + id, {
        ...genericRequestJson(),
        method: "PUT",
        body: JSON.stringify(data),
      });
    },
    actualizarApiKey: (id) => {
      return fetch(apiURL + "/usuarios/api-key/" + id, {
        ...genericRequestJson(),
        method: "PUT",
      });
    },
    validarEmail: (id) => {
      return fetch(apiURL + "/auth/validar-email/" + id, {
        ...genericRequestJson(),
        method: "POST",
      });
    },
    forgotPass: (data) => {
      return fetch(apiURL + "/auth/olvide-contrasena", {
        ...genericRequestJson(),
        method: "POST",
        body: JSON.stringify(data),
      });
    },
    CambiarPass: (tokenId, data) => {
      return fetch(apiURL + "/auth/restablecer-contrasena/" + tokenId, {
        ...genericRequestJson(),
        method: "POST",
        body: JSON.stringify(data),
      });
    },
  },
  notificaciones: {
    get: () => {
      return fetch(apiURL + "/notificaciones/", genericRequestJson());
    },
    notificacionLeida: (id) => {
      return fetch(apiURL + "/notificaciones/leido/" + id, {
        ...genericRequestJson(),
        method: "PUT",
      });
    },
  },
  pagos: {
    nuevo: (data) => {
      return fetch(apiURL + "/pagos/", {
        ...genericRequestJson(),
        method: "POST",
        body: JSON.stringify(data),
      });
    },
  },
  suscripciones: {
    activa: () => {
      return fetch(apiURL + "/suscripciones/activa", genericRequestJson());
    },
  },
  prospectos: {
    crear: (data) => {
      return fetch(apiURL + "/prospectos/", {
        ...genericRequestJson(),
        method: "POST",
        body: JSON.stringify(data),
      });
    },
  },
  causas: {
    list: () => {
      return fetch(apiURL + "/causas", genericRequestJson());
    },
    listByOrganization: (id) => {
      return fetch(apiURL + "/causas/fundacion/" + id, genericRequestJson());
    },
    get: (id) => {
      return fetch(apiURL + "/causas/" + id, genericRequestJson());
    },
    actualizaciones: {
      list: (causaId) => {
        return fetch(
          apiURL + "/causas/actualizaciones/" + causaId,
          genericRequestJson()
        );
      },
    },
  },
  documentos: {
    certificar: (id, data) => {
      return fetch(apiURL + "/documentos/" + id, {
        ...genericRequestJson(),
        method: "POST",
        body: JSON.stringify(data),
      });
    },
    list: (id) => {
      return fetch(apiURL + "/documentos/list/" + id, genericRequestJson());
    },
    getUrl: (id) => {
      return fetch(apiURL + "/documentos/url/" + id, genericRequestJson());
    },
    getCertUrl: (id) => {
      return fetch(apiURL + "/documentos/url/cert/" + id, genericRequestJson());
    },
    get: (idGeneric) => {
      return fetch(apiURL + "/documentos/" + idGeneric, genericRequestJson());
    },
    cambiarPrivacidad: (id, data) => {
      return fetch(apiURL + "/documentos/privacidad/" + id, {
        ...genericRequestJson(),
        method: "PUT",
        body: JSON.stringify(data),
      });
    },
  },
  organizaciones: {
    crear: (data) => {
      return fetch(apiURL + "/organizaciones/", {
        ...genericRequestJson(),
        method: "POST",
        body: JSON.stringify(data),
      });
    },
    mis_organizaciones: () => {
      return fetch(apiURL + "/usuarios/organizaciones/", genericRequestJson());
    },
    listaFundaciones: () => {
      return fetch(
        apiURL + "/organizaciones/fundaciones",
        genericRequestJson()
      );
    },
    get: (id) => {
      return fetch(apiURL + "/organizaciones/" + id, genericRequestJson());
    },
    actualizarApiKey: (id) => {
      return fetch(apiURL + "/organizaciones/api-key/" + id, {
        ...genericRequestJson(),
        method: "PUT",
      });
    },
    actualizar: (id, data) => {
      return fetch(apiURL + "/organizaciones/" + id, {
        ...genericRequestJson(),
        method: "PUT",
        body: JSON.stringify(data),
      });
    },
  },
  plantas_produccion: {
    listOrganizacion: (id) => {
      return fetch(apiURL + "/plantas-suministro/organizacion/" + id, genericRequestJson());
    },
    mis_plantas_produccion: () => {
      return fetch(apiURL + "/plantas-suministro/usuario/", genericRequestJson());
    },
    get: (id) => {
      return fetch(apiURL + "/plantas-suministro/" + id, genericRequestJson());
    },
  },
  certificaciones_produccion: {
    listOrganizacion: (id) => {
      return fetch(apiURL + "/certificaciones-suministro/organizacion/" + id, genericRequestJson());
    },
    mis_plantas_produccion: () => {
      return fetch(apiURL + "/certificaciones-suministro/usuario/", genericRequestJson());
    },
    get: (id) => {
      return fetch(apiURL + "/certificaciones-suministro/" + id, genericRequestJson());
    },
  },
  tarjetas_produccion: {
    certificar: (id, data) => {
      return fetch(apiURL + "/tarjetas-suministro/" + id, {
        ...genericRequestJson(),
        method: "POST",
        body: JSON.stringify(data),
      });
    },
    list: (query) => {
      if (query)
        return fetch(apiURL + "/tarjetas-suministro/" + query, genericRequestJson());
      else
        return fetch(apiURL + "/tarjetas-suministro/", genericRequestJson());
    },
    get: (id) => {
      return fetch(apiURL + "/tarjetas-suministro/" + id, genericRequestJson());
    },
    getTrazabilidad: (id) => {
      return fetch(apiURL + "/tarjetas-suministro/trazabilidad/" + id, genericRequestJson());
    },
    actualizarEstado: (id, data) => {
      return fetch(apiURL + "/tarjetas-suministro/" + id, {
        ...genericRequestJson(),
        method: "PUT",
        body: JSON.stringify(data),
      });
    },
  },
  productos_produccion: {
    crear: (idOrganizacion, data) => {
      return fetch(apiURL + "/productos-suministro/" + idOrganizacion, {
        ...genericRequestJson(),
        method: "POST",
        body: JSON.stringify(data),
      });
    },
    actualizar: (id, data) => {
      return fetch(apiURL + "/productos-suministro/" + id, {
        ...genericRequestJson(),
        method: "PUT",
        body: JSON.stringify(data),
      });
    },
    list: (id) => {
      return fetch(apiURL + "/productos-suministro/organizacion/" + id, genericRequestJson());
    },
    get: (id) => {
      return fetch(apiURL + "/tarjetas-suministro/" + id, genericRequestJson());
    },
    getTrazabilidad: (id) => {
      return fetch(apiURL + "/tarjetas-suministro/trazabilidad/" + id, genericRequestJson());
    },
  },
  procesos_produccion: {
    crear: (idOrganizacion, data) => {
      return fetch(apiURL + "/procesos-suministro/" + idOrganizacion, {
        ...genericRequestJson(),
        method: "POST",
        body: JSON.stringify(data),
      });
    },
    actualizar: (id, data) => {
      return fetch(apiURL + "/procesos-suministro/" + id, {
        ...genericRequestJson(),
        method: "PUT",
        body: JSON.stringify(data),
      });
    },
    list: (id) => {
      return fetch(apiURL + "/procesos-suministro/organizacion/" + id, genericRequestJson());
    },
    get: (id) => {
      return fetch(apiURL + "/procesos-suministro/" + id, genericRequestJson());
    },
    getTrazabilidad: (id) => {
      return fetch(apiURL + "/procesos-suministro/trazabilidad/" + id, genericRequestJson());
    },
  },
  facturas: {
    getUrl: (id) => {
      return fetch(apiURL + "/documentos/url/" + id, genericRequestJson());
    },
    listar_egresos: (id, tipoEntidad) => {
      return fetch(
        apiURL +
        "/facturas/" +
        tipoEntidad.toLowerCase() +
        "/egresos/" +
        id,/* +
          "?page=0&limit=1000",*/
        genericRequestJson()
      );
    },
    listar_ingresos: (id, tipoEntidad) => {
      return fetch(
        apiURL +
        "/facturas/" +
        tipoEntidad.toLowerCase() +
        "/ingresos/" +
        id,/* +
          "?page=0&limit=1000",*/
        genericRequestJson()
      );
    },
    donaciones_usuario: (idUsuario) => {
      return fetch(
        apiURL + "/facturas/usuario/egresos/" + idUsuario,
        genericRequestJson()
      );
    },
    cambiarPrivacidad: (id, data) => {
      return fetch(apiURL + "/facturas/" + id, {
        ...genericRequestJson(),
        method: "PUT",
        body: JSON.stringify(data),
      });
    },
  },
  buscar: {
    buscar_entidad: (usuarioOrganizacionId) => {
      return fetch(
        apiURL + "/buscar/id/" + usuarioOrganizacionId,
        genericRequestJson()
      );
    },
  },
};

export default Api;


