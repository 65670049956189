import React, { useEffect, Fragment } from "react";
import Navigation from "../../components/Navigation";
import FooterLanding from "../../components/FooterLanding";
import { CopyBlock, codepen } from "react-code-blocks";

function Docs() {
	useEffect(() => {
		document.title = "LifeChain - API";
	});

	return (
		<div classNameName="Docs">
			<Fragment>
				<Navigation dark={true} />
				<main>
					<section className="section terms">
						<div className="container pt-md-9">
							<h1 style={{ "marginTop": "50px" }}>Documentación de API</h1>
							<p className="color-2 small bold">Úiltima actualización el 25 de marzo, 2020.</p>
							<hr className="my-4" />
							<p style={{ "textAlign": "justify" }}>¡Bienvenido a LifeChain! aquí encontraras la documentación para
								integrar los productos de LifeChain a tu organización o proyecto personal.

								Antes de comenzar con cualquier proceso de integración con LifeChain, es necesario registrarse
								previamente y contar con tu API Key. Esta sirve para que puedas autentificarte con nosotros y
								correr procesos automatizados sin necesidad de iniciar sesión.
							</p>

							<h3 style={{ "marginTop": "50px" }}>¿Cómo consigo mi API KEY?</h3>
							<p style={{ "textAlign": "justify" }}>Todo depende de como quieras interactuar con nosotros, ya que tienes
								2 opciones: puedes hacerlo como persona, o como organización.</p>
							<ul>
								<li style={{ "textAlign": "justify" }}><b>API Key de usuario:</b> Para conseguirla, es necesario ir a{" "}
									<a id="hrefPerfil" href=" #" target="_blank">editar tu perfil</a>. Debajo del formulario, hay un botón
									que dice “generar/actualizar API Key”.</li>
								<li style={{ "textAlign": "justify" }}><b>API Key de organización:</b> Primero, es necesario <a
									id="hrefNuevaOrganizacion" href=" #" target="_blank">dar de alta la organización</a> en LifeChain
									y validar el RFC de la misma (Para esto haz click en el botón “Validar RFC”, y sube una
									constancia o cédula fiscal de la organización en formato pdf).
									Una vez teniendo tu organización registrada y con su RFC validado, debes ir a la página de
									la organización y buscar el botón "editar organización" para hacer click. Una vez en la
									página para editar la organización, debajo del formulario hay un botón que dice
									“generar/actualizar API Key”.</li>
							</ul>
							<p style={{ "textAlign": "justify" }}>En ambos casos, al dar click en el botón “generar/actualizar API
								Key”, la interfaz pedirá una confirmación al usuario para completar la acción. Dado que si
								anteriormente ya tenías una API Key asignada, esta será borrada y sustituida por una nueva.</p>
							<p style={{ "textAlign": "justify", "color": "red" }}>Notas:</p>
							<ul>
								<li style={{ "textAlign": "justify" }}>Debes copiar y guardar el API Key que generaste, dado que por
									motivos de seguridad nosotros solo guardamos la versión encriptada de tu API Key. Si no la
									copiaste y la perdiste, no te preocupes, puedes generarla de nuevo.</li>
								<li style={{ "textAlign": "justify" }}>Si tu tenias previamente configuradas tus peticiones a LifeChain
									con un Api Key, y das click en “generar/actualizar API Key”, tendrás que configurar
									nuevamente tus peticiones, dado que la API Key anterior será borrada y LifeChain no te
									reconocerá como usuario/organización. Esto es útil si tu API Key fué comprometida o robada
									por terceros y deseas destruirla y sustituirla por una nueva.</li>
							</ul>
							<h3 style={{ "marginTop": "50px" }}>¿Cómo configuro mi API Key dentro de mis peticiones a LifeChain?</h3>
							<p style={{ "textAlign": "justify" }}>Es necesario que incluyas tu API Key como cabecera en tu petición
								HTTP, asignándole el nombre “api_key”. Esto aplica para todas peticiones a LifeChain explicadas
								de ahora en adelante.</p>
							<p style={{ "textAlign": "justify" }}>El siguiente es un ejemplo ilustrativo de una petición utilizando un
								apikey de prueba.</p>
							<CopyBlock
								text={"curl 'https://api.lifechain.mx/...' \\\n" +
									"-H 'apikey: G8Jx0p0iYS.KpZYXHqChmEG58Lg9KpCD1O9CN0SY7oUrnmHQB5CYmXhbjO0GtirV'"}
								showLineNumbers={false}
								theme={codepen}
								wrapLines={true}
								codeBlock
							/><br />

							<h3 style={{ "marginTop": "50px" }}>Productos</h3>
							<p style={{ "textAlign": "justify" }}>Ahora, todo depende del producto que quieras utilizar, por el momento
								tenemos disponibles las siguientes APIs:</p>
							<ul>
								<li style={{ "textAlign": "justify" }}><a href="/docs/certificacion-documentos/">Certificación de
									documentos</a></li>
							</ul>
						</div>
					</section>
				</main>
				<FooterLanding/>
			</Fragment>
		</div>
	);
}

export default Docs;
