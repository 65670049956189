import React, { useEffect } from "react";
import FormLogin from "../../components/FormLogin";

function Login() {
  useEffect(() => {
    document.title = "LifeChain - Login";
  });

  return (
    <div className="Login">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div
            className="col-md-6 col-lg-7 fullscreen-md d-flex justify-content-center align-items-center overlay gradient gradient-54 alpha-7 image-background cover"
            style={{
              backgroundImage:
                "url(https://picsum.photos/1920/1200/?random&gravity=south)",
            }}
          >
            <div className="content">
              <h2 className="display-4 display-md-3 color-1 mt-4 mt-md-0">
                Bienvenido a<span className="bold d-block">LifeChain</span>
              </h2>
              <p className="lead color-1 alpha-5">Inicia sesión en tu cuenta</p>
              <hr className="mt-md-6 w-25" />
              {/*<div className="d-flex flex-column" style={{ display: "none" }}>
                <p className="small bold color-1" style={{ display: "none" }}>
                  O inicia sesión con
                </p>
                <nav className="nav mb-4">
                  <a
                    className="btn btn-rounded btn-outline-2 brand-facebook mr-2"
                    href="<%= url.api %>/auth/fb"
                    style={{ display: "none" }}
                  >
                    <i className="fab fa-facebook-f"></i>
                  </a>
                  <a
                    className="btn btn-rounded btn-outline-2 brand-twitter mr-2"
                    href="#"
                    style={{ display: "none" }}
                  >
                    <i className="fab fa-twitter"></i>
                  </a>
                  <a
                    className="btn btn-rounded btn-outline-2 brand-linkedin"
                    href="#"
                    style={{ display: "none" }}
                  >
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                </nav>
          </div>*/}
            </div>
          </div>
          <div className="col-md-5 col-lg-4 mx-auto">
            <div className="login-form mt-5 mt-md-0">
              <a id="hrefIndex" href="/">
                <img
                  src="images/landing/logo.png"
                  className="logo img-responsive"
                  alt="LifeChain"
                />
              </a>
              <h1 className="color-4 bold">Inicio de sesión</h1>
              <p className="color-2 mt-0 mb-4 mb-md-6">
                ¿Aún no tienes cuenta?
                <a href="/signup" className="accent bold ml-2">
                  Crea una aqui
                </a>
              </p>
              <FormLogin />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
