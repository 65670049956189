import React, { useEffect, Fragment } from "react";
import Navigation from "../../components/Navigation";
import BlogFooter from "../../components/BlogFooter";

function PrensaInformadorTalent() {

  useEffect(() => {
    document.title = "LifeChain - Blog";
  });

  return (
    <div className="PrensaInformadorTalent">
      <Fragment>
        <Navigation />
        <main>
			<header className="section color-1 fullscreen blog-single-header image-background"
				style={{"backgroundImage": "url('/images/blog/1.svg')", "backgroundColor": "#464685"}}>
				<div className="container text-center">
					<div className="row">
						<div className="col-md-10 mx-md-auto">
							<h1 className="post-title display-4">Impuestos transparentes y combate a Fake News, proyectos
								ganadores del Talent Hackatón - Informador</h1>
						</div>
					</div>
					<a href="#blog-post" className="scrollto mt-auto">
						<div className="arrow-down">
							<i className="pe pe-7s-angle-down pe-4x color-5"></i>
						</div>
					</a>
				</div>
			</header>
			<section className="blog-post" id="blog-post">
				<div className="container">
					<div className="row">
						<div className="col-md-8 mx-auto">
							<div className="media">
								<img className="mr-3 rounded-circle icon-md" src="/images/landing/avatar/Informador.jpg"
									alt=""/>
								<div className="media-body small">
									<span className="author">por
										<a href=" #" target="_blank" rel="noreferrer"> Andrés Gallegos</a>
									</span>
									<span className="d-block color-2 alpha-6">Abril 6, 2018</span>
								</div>
							</div>
							<hr className="mb-5"/>
							<p className="lead color-2 italic mb-5">&quot;Presupuesto Participativo&quot; fue el mejor proyecto de la
								vertical &quot;Gobierno Digital&quot;, patrocinado por la Secretaría de la Función Pública del
								Gobierno Federal.</p>
							<video style={{width:"100%"}} controls>
								<source src="/videos/informador_premiacion_talent_land.mp4" type="video/mp4"/>
								Your browser does not support the video tag.
							</video>
							<p>Alejandro López Ochoa y Goretti Citlady Oronia Tamayo, estudiantes de la Universidad de
								Guadalajara, crearon en apenas cuatro días <b className="bold">&quot;Presupuesto
									Participativo&quot;</b>, una plataforma que permitirá a los ciudadanos saber cómo y en
								qué se gastan sus impuestos, proponer políticas para invertirlos, y realizar denuncias
								ciudadanas en caso de algún mal manejo de los recursos públicos.</p>
							<p>&quot;Estamos contentos de que se haga un cambio, y que eso se implemente de verdad, para
								saber a dónde se van nuestros impuestos, y poder opinar sobre ello&quot;, manifestó Oronia,
								quien junto a Alejandro fueron uno de los dos proyectos ganadores del primer <b
									className="bold">Talent Hackatón</b>, dentro de las verticales o problemáticas sociales
								a resolver patrocinadas por el sector público.</p>
							<p>Con los 150 mil pesos que recibieron de premio (50 mil por ganar su vertical y 100 mil
								por ser uno de los dos ganadores de la final), ambos esperan impulsar su proyecto a
								niveles más altos. Presupuesto Participativo también permitirá procesar las denuncias y
								peticiones ciudadanas con sus impuestos mediante el auxilio de la tecnología BlockChain
								y la Inteligencia Artificial.</p>
							<p>&quot;La idea es que acepte también criptomonedas para el pago de impuestos y servicios,
								aparte de transferencias bancarias, y que todas las transacciones se suban a BlockChain
								para darles un seguimiento&quot;, explicó Goretti.</p>
							<p>&quot;Presupuesto Participativo&quot; fue el mejor proyecto de la vertical &quot;Gobierno Digital&quot;,
								patrocinado por la Secretaría de la Función Pública del Gobierno Federal.</p>
							<br />
							<p>Link: <a
									href="https://www.informador.mx/tecnologia/Impuestos-transparentes-y-combate-a-Fake-News-proyectos-ganadores-del-Talent-Hackaton-20180406-0086.html"
									target="_blank" rel="noreferrer">https://www.informador.mx/tecnologia/Impuestos-transparentes-y-combate-a-Fake-News-proyectos-ganadores-del-Talent-Hackaton-20180406-0086.html</a>
							</p>
						</div>
					</div>
				</div>
			</section>
			<section>
				<div className="container py-5 b-t b-2x">
					<div className="media align-items-center">
						<img className="d-flex mr-3 rounded-circle shadow" style={{"width": "100px", "height": "100px"}}
							src="/images/landing/avatar/Informador.jpg" alt="..."/>
						<div className="media-body">
							<h5 className="my-0 bold">Andrés Gallegos</h5>
							<span className="color-2">Informador</span>
							<hr/>
							<p className="small color-2 mb-0">Las noticias más relevantes de Jalisco, México, Deportes,
								Entretenimiento & Tecnología.</p>
						</div>
					</div>
				</div>
			</section>
			<section>
				<div className="container py-5 b-t">
					<ul className="list-unstyled d-flex flex-wrap flex-row align-items-center">
						<li className="mr-4">
							<i className="fas fa-tag color-2"></i>
						</li>
						<li>
							<a href=" #" className="badge badge-pill badge-outline-2 mr-2">blockchain</a>
						</li>
						<li>
							<a href=" #" className="badge badge-pill badge-outline-2 mr-2">talent land</a>
						</li>
						<li>
							<a href=" #" className="badge badge-pill badge-outline-2 mr-2">presupuesto participativo</a>
						</li>
						<li>
							<a href=" #" className="badge badge-pill badge-outline-2 mr-2">hackathon</a>
						</li>
						<li>
							<a href=" #" className="badge badge-pill badge-outline-2 mr-2">jalisco</a>
						</li>
					</ul>
					<ul className="list-unstyled d-flex flex-wrap flex-row align-items-center">
						<li className="mr-4">
							<i className="fas fa-bookmark color-2"></i>
						</li>
						<li>
							<a href=" #" className="btn btn-circle btn-sm brand-twitter mr-3">
								<i className="fab fa-twitter"></i>
							</a>
						</li>
						<li>
							<a href=" #" className="btn btn-circle btn-sm brand-facebook mr-3">
								<i className="fab fa-facebook"></i>
							</a>
						</li>
					</ul>
				</div>
			</section>
		</main>
        <BlogFooter/>
      </Fragment>
    </div>
  );
}

export default PrensaInformadorTalent;