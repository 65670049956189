import React from "react";
function AppLeftMenu(props) {
  return (
    <div className="AppLeftMenu">
      <nav
        className="side-nav side-nav overlay gradient gradient-53 alpha-9 image-background cover center-bottom"
        style={{
          height: "100%",
        }}
      >
        <div className="side-wrapper content">
          <div className="nav-wrapper">
            <ul className="nav flex-column">
              {/*{/*<!-- MAIN -->*/}
              <li className="nav-header pt-4">
                <span>Inicio</span>
              </li>
              <li className="nav-item">
                <a href="/app/mi-perfil" className="nav-link">
                  <i className="icon pe pe-7s-display1"></i>
                  <span className="title">Perfil</span>
                </a>
              </li>
              <li className="nav-item">
                <a href="/app/mis-organizaciones" className="nav-link">
                  <i className="icon pe pe-7s-display1"></i>
                  <span className="title">Mis organizaciones</span>
                </a>
              </li>
              {/*<!-- Layout -->*/}
              <li className="nav-item nav-dropdown">
                <a href="# " className="nav-link">
                  <i className="icon pe pe-7s-box2"></i>
                  <span className="title">Archivos</span>
                </a>
                <ul className="nav-dropdown-items">
                  <li>
                    <a href="/app/documentos" className="nav-link">
                      <b className="icon extra-bold">M</b>
                      <span className="title">Mis archivos</span>
                    </a>
                  </li>
                  <li>
                    <a href="/app/documentos/validar" className="nav-link">
                      <b className="icon extra-bold">V</b>
                      <span className="title">Verificar archivo</span>
                    </a>
                  </li>
                  <li>
                    <a href="/app/documentos/precios" className="nav-link">
                      <b className="icon extra-bold">P</b>
                      <span className="title">Paquetes</span>
                    </a>
                  </li>
                </ul>
              </li>
              <li className="nav-item nav-dropdown">
                <a href="# " className="nav-link">
                  <i className="icon pe pe-7s-box2"></i>
                  <span className="title">Donaciones</span>
                </a>
                <ul className="nav-dropdown-items">
                  <li>
                    <a href="/app/mis-donaciones" className="nav-link">
                      <b className="icon extra-bold">F</b>
                      <span className="title">Mis donaciones</span>
                    </a>
                  </li>
                  <li>
                    <a href="/app/fundaciones" className="nav-link">
                      <b className="icon extra-bold">F</b>
                      <span className="title">Fundaciones</span>
                    </a>
                  </li>
                  <li>
                    <a href="/app/causas" className="nav-link">
                      <b className="icon extra-bold">C</b>
                      <span className="title">Campañas</span>
                    </a>
                  </li>
                  {/*<!--<li>
							<a href="gradient-sidebar.html" className="nav-link">
								<b className="icon extra-bold">D</b>
								<span className="title">Donar</span>
							</a>
						</li>-->*/}
                </ul>
              </li>
              <li className="nav-item nav-dropdown">
                <a href="# " className="nav-link">
                  <i className="icon pe pe-7s-box2"></i>
                  <span className="title">Suministro</span>
                </a>
                <ul className="nav-dropdown-items">
                  <li>
                    <a href="/app/mis-plantas-produccion" className="nav-link">
                      <b className="icon extra-bold">F</b>
                      <span className="title">Mis plantas de producción</span>
                    </a>
                  </li>
                  {/*<li>
                    <a href="/organizaciones/fundaciones" className="nav-link">
                      <b className="icon extra-bold">F</b>
                      <span className="title">Mis tarjetas</span>
                    </a>
                  </li>*/}
                  <li>
                    <a href="/app/tarjetas-suministro/buscar" className="nav-link">
                      <b className="icon extra-bold">R</b>
                      <span className="title">Rastrear producto</span>
                    </a>
                  </li>
                </ul>
              </li>
              {/*<!-- Forms -->*/}
              {/*<!--<li className="nav-item nav-dropdown">
					<a href="javascript:;" className="nav-link">
						<i className="icon pe pe-7s-switch"></i>
						<span className="title">Administración</span>
					</a>
					<ul className="nav-dropdown-items">
						<li>
							<a href="forms/basic.html" className="nav-link">
								<b className="icon extra-bold">F</b>
								<span className="title">Mis Fundaciones</span>
							</a>
						</li>
						<li>
							<a href="forms/checkbox-radio.html" className="nav-link">
								<b className="icon extra-bold">C</b>
								<span className="title">Mis causas</span>
							</a>
						</li>
						<li>
							<a href="forms/input-groups.html" className="nav-link">
								<b className="icon extra-bold">I</b>
								<span className="title">Subir Facturas</span>
							</a>
						</li>
					</ul>
				</li>-->*/}
              {/*<!--<li>
					<a href="javascript:void(0)" className="nav-link">
						<i className="icon pe pe-7s-note2"></i>
						<span className="title">Pagos</span>
					</a>
				</li>-->*/}
              {/*<!-- Apps -->*/}
              <li className="nav-header">
                <span className="title">Menú</span>
              </li>
              {/*<!-- Pages -->*/}
              <li>
                <a href="/about" className="nav-link">
                  <i className="icon pe pe-7s-bookmarks"></i>
                  <span className="title">Nosotros</span>
                </a>
              </li>
              {/*<!-- Tables -->*/}
              {/*<!--<li className="nav-item nav-dropdown">
					<a href="javascript:void(0)" className="nav-link">
						<i className="icon pe pe-7s-browser"></i>
						<span className="title">Educación</span>
					</a>
					<ul className="nav-dropdown-items">
						<li>
							<a href="#" className="nav-link">
								 <b className="icon extra-bold">S</b>
								<span className="title">Uso sistema</span>
							</a>
						</li>
						<li>
							<a href="tables/basic.html" className="nav-link">
							    <b className="icon extra-bold">B</b>
								<span className="title">Blockchain</span>
							</a>
						</li>
						<li>
							<a href="tables/responsive.html" className="nav-link">
							    <b className="icon extra-bold">N</b>
								<span className="title">Noticias</span>
							</a>
						</li>
					</ul>
				</li>-->*/}
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default AppLeftMenu;
