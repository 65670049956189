import React, { useEffect, useState, Fragment } from "react";
import Navigation from "../../components/Navigation";
import BlogFooter from "../../components/BlogFooter";
import ModalNuevoProspecto from "../../components/modal/NuevoProspecto";

function BlogInvestigacionDonacion() {
    const [state, setState] = useState({
        admin: false,
        user: { saldo_certificar_docs: 0 },
        loading: false,
        error: null,
        showModaNuevoProspecto: false,
      });

      function openModalNuevoProspecto() {
        setState({
          ...state,
          showModaNuevoProspecto: true,
        });
      }

      function closeModals() {
        setState({
          ...state,
          showModaNuevoProspecto: false,
        });
      }
  useEffect(() => {
    document.title = "LifeChain - Blog";
  });

  return (
    <div className="BlogInvestigacionDonacion">
      <Fragment>
        <Navigation />
        <main>
			<header className="section color-1 fullscreen blog-single-header image-background"
				style={{"backgroundImage": "url('/images/blog/1.svg')", "backgroundColor": "#464685"}}>
				<div className="container text-center">
					<div className="row">
						<div className="col-md-10 mx-md-auto">
							<h1 className="post-title display-4">Investigación de mercados a donadores - Estudio de
								percepción</h1>
						</div>
					</div>
					<a href="#blog-post" className="scrollto mt-auto">
						<div className="arrow-down">
							<i className="pe pe-7s-angle-down pe-4x color-5"></i>
						</div>
					</a>
				</div>
			</header>
			<ModalNuevoProspecto
                show={state.showModaNuevoProspecto}
                onHide={closeModals}
            />
			<section className="blog-post" id="blog-post">
				<div className="container">
					<div className="row">
						<div className="col-md-8 mx-auto">
							<div className="media">
								<img className="mr-3 rounded-circle icon-md"
									src="/images/landing/avatar/team/goretti-profile.jpg" alt=""/>
								<div className="media-body small">
									<span className="author">por 
										<a href=" #"> Goretti Oronia</a>
									</span>
									<span className="d-block color-2 alpha-6">11 de Noviembre, 2020</span>
								</div>
							</div>
							<hr className="mb-5"/>
							<p className="lead color-2 italic mb-5">Te compartimos una investigación que realizamos para ver
								si nuestra solución podría ayudar realmente a las fundaciones, dejando a un lado las
								suposiciones y explorando datos duros.</p>
							<div className="text-center">
								<nav className="navigation"><button className="btn btn-rounded btn-solid px-3"
										style={{"backgroundColor":"#fc6d57", "color": "#fff", "margin": "auto"}} onClick={openModalNuevoProspecto}>Descargar resultados</button></nav>
							</div>
							<p>A continuación te hablaremos un poco de los aspectos que consideramos para diseñar la
								investigación, así como algunos datos relevantes que encontramos.</p>
							<p>¿Por qué realizarla?</p>
							<p>Estamos conscientes de la importancia de conocer a fondo a los usuarios, es por eso que
								realizamos una investigación de mercado, la cual según la Asociación Mexicana de
								Agencias de Investigación de Mercado (AMAI), es una herramienta que permite saber si tu
								proyecto de negocio va en la dirección correcta, de igual forma, también es el reflejo
								de una buena orientación con diseño de hipótesis sobre escenarios posibles así como el
								eje para el contacto con el público y el mercado meta. En pocas palabras si queremos
								ofrecer una buena propuesta de valor, debemos usar datos duros que decidan el camino de
								LifeChain.</p>
							<p>Nuestro objetivo principal es:</p>
							<blockquote className="blog-quote color-2 d-flex mx-auto">
								<p>Fortalecer, Ajustar o cambiar la oferta de valor de LifeChain dependiendo de los
									resultados obtenidos de fuentes primarias.</p>
							</blockquote>
							<img className="img-responsive rounded" src="/images/blog/investigacion_donadores/portada.jpg"
								alt=""/>
							<p>Objetivos específicos</p>
							<ul>
								<li>Medir la percepción hacia las organizaciones que reciben donativos y si se puede
									mejorar esta actitud con el uso de LifeChain</li>
								<li>Descubrir la disposición a usar LifeChain</li>
								<li>Saber si podría el uso de LifeChain motivar a donar más</li>
								<li>Conocer las asociaciones lingüísticas y percepciones de palabras clave</li>
								<li>Comprender que influye para que las personas decidan donar </li>
							</ul>
							<h4 className="bold">Al finalizar voy a saber:</h4>
							<ul>
								<li>La percepción que tienen las personas de las fundaciones</li>
								<li>Si podríamos mejorar la percepción de las fundaciones con LifeChain</li>
								<li>Si las personas estarían dispuestas a donar más a una fundación que sea transparente
									usando nuestro sistema</li>
								<li>El lenguaje que puedo utilizar al dar un pitch sobre mi fundación</li>
								<li>Qué aspectos tenemos que cuidar para que decidan donar más </li>
							</ul>
							<h4 className="bold">Marco teórico</h4>
							<p>Investigamos en fuentes secundarias información que nos ayude a tener un contexto del
								tema a continuación mostramos algunos datos relevantes de la Encuesta Nacional sobre
								Filantropía y Sociedad Civil (ENAFI): Donaciones en México en colaboración con el ITAM.
							</p>
							<p>Se encuentra que las creencias religiosas son parte de los principales motivadores para
								realizar contribuciones o donativos.</p>
							<div className="text-center" style={{"width":"100%"}}><img className="rounded"
									src="/images/blog/investigacion_donadores/grafica1.png" alt=""/>
								<img className="rounded" src="/images/blog/investigacion_donadores/grafica2.png" alt=""/>
							</div>
							<h4 className="bold">Perfil de Donador</h4>
							<p>El perfil de donantes en México son las mujeres entre 30 y 49 años, de zonas urbanas, con
								escolaridad media, que viven con una pareja, en un hogar cuyo nivel de ingreso perciben
								que con este les alcanza justo, sin grandes dificultades. </p>
							<p>A lo que más se dona en el país es a la limosna de misa y cuestiones religiosas, seguido
								por desastres naturales, el perfil en México según la “Encuesta Nacional de Filantropía
								y Sociedad Civil (ENAFI)”, aplicada por el Proyecto sobre Filantropía y
								Sociedad Civil del Departamento de Estudios Internacionales del Instituto Tecnológico
								Autónomo de México (ITAM).</p>
							<p>Un estudio de España dice que el perfil de los donantes en su país son personas con
								estudios universitarios (66%), con una edad media de 47 años, perfil tecnológico alto,
								casada o conviviendo en pareja (65%) y creencias religiosas (63%), en su mayoría
								católicas (94%). Se define como realista (68%), racional (53%) y más de izquierdas
								(58%). A. Medina (2014) Así es el perfil de los españoles que donan dinero a las ONG,
								Noticias Expansión </p>
							<h4 className="bold">Metodología</h4>
							<p>Algo que nos parece relevante conocer en esta investigación son las percepciones y
								actitudes, las cuales han sido definidas como aquellas formas de pensar, de sentir o de
								comportarse hacia a una idea, un objeto, persona o grupo de semejantes. (McConell,
								1978, p.91).</p>
							<p>Encontramos en esta definición tres elementos:</p>
							<ol>
								<li>Un elemento cognitivo.</li>
								<li>Un elemento afectivo.</li>
								<li>Un elemento de comporta mental.</li>
							</ol>
							<p>El elemento <b className="bold">cognitivo</b> sería aquello que se piensa acerca del objeto
								de la actitud.
								Desde un punto de vista cognitivo puede pensarse que algo está bien o está mal,
								independientemente de lo que luego se haga, porque puede suceder que afectivamente
								se sienta movido en otra dirección.
							</p>
							<p>El elemento <b className="bold">afectivo</b> haría referencia a aquello que se siente, que
								gusta o disgusta.
								Así pues, puede sentirse que algo gusta o disgusta, sin que necesariamente se
								actúe en la dirección que apunte ese sentimiento, porque racionalmente se considere que
								no es conveniente.
							</p>
							<p>Por último, cuando se habla de un elemento de <b className="bold">comportamiento</b> o
								reactivo en las
								actitudes, se quiere significar que no basta que algo parezca bien o mal, ya que estamos
								juzgando dentro de nuestra cognición.
							</p>
							<p>Al hablar de percepciones y actitudes debemos tomar en cuenta que tipo de técnicas de
								recolección de datos usaremos, es por eso que usaremos varias preguntas de investigación
								cualitativa, las cuales son "abiertas al final, evolutivas, y no direccional" (Creswell,
								1998, p. 99). Estas preguntas tienden a buscar, descubrir, explorar un proceso, o
								describir experiencias. </p>
							<p>Elegimos usar preguntas de correlación, y asociación de palabras, este tipo de pregunta
								de investigación cuantitativa está interesada en las relaciones de causalidad, las
								asociaciones, las tendencias y / o las interacciones entre dos o más variables.</p>
							<p>Estas t&eacute;cnicas proyectivas de&nbsp; asociaci&oacute;n de palabras y completar
								oraciones&nbsp; la analizaremos generando nube de palabras, as&iacute; como la
								frecuencia de oraciones y palabras. La asociaci&oacute;n de palabras nos permite dar un
								paso m&aacute;s hacia el camino de la comprensi&oacute;n de las representaciones
								mentales y conocer el campo sem&aacute;ntico que manejan.</p>
							<p>Para analizar los resultados y determinar correlaciones entre las respuestas, usaremos
								un:</p>
							<p><span style={{"fontWeight": "bold"}}>An&aacute;lisis factorial</span> la cual es una
								t&eacute;cnica estad&iacute;stica de reducci&oacute;n de datos usada para explicar las
								correlaciones entre las variables observadas en t&eacute;rminos de un n&uacute;mero
								menor de variables no observadas llamadas factores.</p>
							<p>Tambi&eacute;n aplicaremos el <span style={{"fontWeight": "bold"}}>Alfa de Cronbach</span>
								que es un coeficiente usado para saber cu&aacute;l es la fiabilidad de una escala o
								test. Un recurso muy utilizado en psicometr&iacute;a. La psicometr&iacute;a es la
								disciplina que se encarga de medir y cuantificar variables psicol&oacute;gicas de la
								psique humana.</p>
							<h4 className="bold">Variables y escalas</h4>
							<p>La medici&oacute;n puede definirse como la asignaci&oacute;n de n&uacute;meros a objetos
								y eventos de acuerdo con ciertas reglas</p>
							<p><span style={{"fontWeight": "bold"}}>Escala nominal. </span>En esta escala las unidades
								observacionales (UO) se agrupan en clases excluyentes seg&uacute;n determinada propiedad
							</p>
							<p><span style={{"fontWeight": "bold"}}>Escala ordinal: </span>Surge a partir de la
								operaci&oacute;n de ordenamiento; en esta escala se habla de primero, segundo,
								tercero.&nbsp;</p>
							<p><span style={{"fontWeight": "bold"}}>Escala de intervalos.</span> Esta escala representa
								magnitudes, con la propiedad de igualdad de la distancia entre puntos de escala de la
								misma amplitud. Aqu&iacute; puede establecerse orden entre sus valores, hacerse
								comparaciones de igualdad, y medir la distancia existente entre cada valor de la
								escala.&nbsp;</p>
							<p><span style={{"fontWeight": "bold"}}>Escala de raz&oacute;n. </span>Corresponde al nivel de
								medici&oacute;n m&aacute;s completo. Tiene las mismas propiedades que la escala
								intervalos, y adem&aacute;s posee el cero absoluto.</p>
							<table className="table table-striped my-5">
								<thead>
									<tr>
										<th scope="col">Tipo de escalas</th>
										<th scope="col">Estadísticas Descriptivas</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<th scope="row">Nominal</th>
										<td>Moda, Frecuencia y Porcentaje</td>
									</tr>
									<tr>
										<th scope="row">Ordinal</th>
										<td>Mediana, Rango y Percentil</td>
									</tr>
									<tr>
										<th scope="row">Razón</th>
										<td>Todas las Operaciones Aritméticas</td>
									</tr>
									<tr>
										<th scope="row">Intervalos</th>
										<td>Media, Desviación Estándar y varianza</td>
									</tr>
								</tbody>
							</table>

							<p><span style={{"fontWeight": "bold"}}>Escala de Likert - </span>En este m&eacute;todo se
								supone que todos los &iacute;tems miden con la misma intensidad la actitud que se desea
								medir y es el encuestado el que le da una puntuaci&oacute;n, normalmente de uno a cinco,
								en funci&oacute;n de su posici&oacute;n frente a la afirmaci&oacute;n sugerida por el
								&iacute;tem.</p>
							<div className="text-center" style={{"width":"100%"}}><img className="rounded"
									src="/images/blog/investigacion_donadores/escala_likert.png" alt="escala de Likert"/>
							</div>
							<small>Fuente de imágen: 5 ejemplos de escalas Likert para tu próxima encuesta -
								(https://www.questionpro.com/blog/es/ejemplos-de-escalas-likert/)</small>
							<p>Si quieres conocer los resultados de la investigación y a cuáles conclusiones pudimos
								llegar, para definir nuestra propuesta de valor, descarga los resultados.</p>
							<br />
							<br />
							<div className="text-center">
								<nav className="navigation"><button className="btn btn-rounded btn-solid px-3"
										style={{"backgroundColor":"#fc6d57", "color": "#fff", "margin": "auto"}} onClick={openModalNuevoProspecto}>Descargar resultados</button></nav>
							</div>
							<h4 className="bold">Referencias</h4>
							<ul>
								<li><a href="https://www.youtube.com/watch?v=VxqkayFztTA">Padilla, J.A. (2 de mayo del
										2019). Análisis factorial exploratorio, SPSS</a></li>
								<li><a href="https://eprints.rclis.org/12999/1/Ruiz_Bannos,_R.pdf">Ruiz-BAÑOS, R., &
										Bailón-Moreno, R. (1999). El método de las palabras asociadas (II). Los ciclos
										de vida de los temas de investigación. Boletín de la Asociación Andaluza de
										Bibliotecarios, 14(54), 59-71.</a></li>
								<li><a
										href="https://www.academia.edu/download/60682135/articulo_preguntas_de_investigacion120190923-120654-1lx6z8b.pdf">Abreu,
										J. L., Parra González, C., & Molina Arenas, E. H. (2012). El Rol de las
										Preguntas de Investigación en el Método Científico (The Role of Research
										Questions in the Scientific Method). Daena: International Journal of Good
										Conscience, 7(1), 169-187.</a></li>
								<li><a href="https://link.springer.com/article/10.1007/BF02310555">Cronbach, Lee J.
										(1951). «Coefficient alpha and the internal structure of tests».
										Psychometrika</a></li>
								<li><a
										href="https://www.researchgate.net/profile/Luis_Escurra/publication/320861746_Siegel_S_Castellan_N_J_1995_Estadistica_no_parametrica_aplicada_a_las_ciencias_de_la_conducta_4a_edicion_Mexico_Editorial_Trillas/links/5a2fff74a6fdccbf7ef12781/Siegel-S-Castellan-N-J-1995-Estadistica-no-parametrica-aplicada-a-las-ciencias-de-la-conducta-4a-edicion-Mexico-Editorial-Trillas.pdf">Siegel,
										S., & Castellan, N. J. (1995). Estadística no paramétrica: aplicada a las
										ciencias de la conducta (Vol. 4, pp. 195-196). México: Trillas.</a></li>
								<li><a href="https://revistascientificas.us.es/index.php/anduli/article/view/3728">Bozal,
										M. G. (2005). Escala mixta likert-thurstone. ANDULI, Revista Andaluza de
										Ciencias Sociales, (5), 81-95.</a></li>
							</ul>
						</div>
					</div>
				</div>
			</section>
			<section>
				<div className="container py-5 b-t b-2x">
					<div className="media align-items-center">
						<img className="d-flex mr-3 rounded-circle shadow" style={{"width": "100px", "height": "100px"}}
							src="/images/landing/avatar/team/goretti-profile.jpg" alt="..."/>
						<div className="media-body">
							<h5 className="my-0 bold">Goretti Oronia</h5>
							<span className="color-2">Lifechain</span>
							<hr/>
							<p className="small color-2 mb-0">Lic. en Mercadotecnia con especialidad en investigación de
								mercados, por la Universidad de Guadalajara, traductora y evangelista de blockchain.</p>
						</div>
					</div>
				</div>
			</section>
			<section>
				<div className="container py-5 b-t">
					<ul className="list-unstyled d-flex flex-wrap flex-row align-items-center">
						<li className="mr-4">
							<i className="fas fa-tag color-2"></i>
						</li>
						<li>
							<a href=" #" className="badge badge-pill badge-outline-2 mr-2">blockchain</a>
						</li>
						<li>
							<a href=" #" className="badge badge-pill badge-outline-2 mr-2">emprendimiento social</a>
						</li>
						<li>
							<a href=" #" className="badge badge-pill badge-outline-2 mr-2">donaciones</a>
						</li>
						<li>
							<a href=" #" className="badge badge-pill badge-outline-2 mr-2">fundaciones</a>
						</li>
						<li>
							<a href=" #" className="badge badge-pill badge-outline-2 mr-2">organizaciones</a>
						</li>
						<li>
							<a href=" #" className="badge badge-pill badge-outline-2 mr-2">innovacion</a>
						</li>
						<li>
							<a href=" #" className="badge badge-pill badge-outline-2 mr-2">investigacion de mercado</a>
						</li>
						<li>
							<a href=" #" className="badge badge-pill badge-outline-2 mr-2">investigacion</a>
						</li>
						<li>
							<a href=" #" className="badge badge-pill badge-outline-2 mr-2">mercado</a>
						</li>
						<li>
							<a href=" #" className="badge badge-pill badge-outline-2 mr-2">seguimiento</a>
						</li>
						<li>
							<a href=" #" className="badge badge-pill badge-outline-2 mr-2">transparencia</a>
						</li>
						<li>
							<a href=" #" className="badge badge-pill badge-outline-2 mr-2">rendicion de cuentas</a>
						</li>
						<li>
							<a href=" #" className="badge badge-pill badge-outline-2 mr-2">donatarias</a>
						</li>
					</ul>
					<ul className="list-unstyled d-flex flex-wrap flex-row align-items-center">
						<li className="mr-4">
							<i className="fas fa-bookmark color-2"></i>
						</li>
						<li>
							<a href=" #" className="btn btn-circle btn-sm brand-twitter mr-3">
								<i className="fab fa-twitter"></i>
							</a>
						</li>
						<li>
							<a href=" #" className="btn btn-circle btn-sm brand-facebook mr-3">
								<i className="fab fa-facebook"></i>
							</a>
						</li>
						<li>
							<a href=" #" className="btn btn-circle btn-sm brand-google mr-3">
								<i className="fab fa-google-plus"></i>
							</a>
						</li>
					</ul>
				</div>
			</section>
		</main>
        <BlogFooter/>
      </Fragment>
    </div>
  );
}

export default BlogInvestigacionDonacion;