import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareAlt, faHeart, faHandshake, faBriefcase, faMusic, faFile } from '@fortawesome/fontawesome-free-solid'

function Navigation(props) {
  var navigationStyle;
  var logo;
  if (props.dark) {
    navigationStyle = "st-nav navbar main-nav navigation fixed-top dark-link";
    logo = "/images/landing/logo.png";
  } else {
    navigationStyle = "st-nav navbar main-nav navigation fixed-top";
    logo = "/images/landing/logo-light.webp";
  }

  return (
    <nav
      id="headerIndex"
      className={navigationStyle}
    >
      <div className="container">
        <ul className="st-nav-menu nav navbar-nav">
          <li className="st-nav-section nav-item">
            <a href="/" className="navbar-brand">
              <img
                src="/images/landing/logo.png"
                alt="LifeChain"
                className="logo logo-sticky d-block d-md-none"
              />
              <img
                id="logoLight"
                src={logo}
                alt="LifeChain"
                className="logo d-none d-md-block"
              />
            </a>
          </li>
          <li className="st-nav-section st-nav-primary nav-item">
            <a className="st-root-link item-products nav-link" href="/">
              Home{" "}
            </a>
            <a className="st-root-link item-products nav-link" href="/about">
              Nosotros{" "}
            </a>
            <a
              className="st-root-link item-developers nav-link"
              href="/contact"
            >
              Contacto{" "}
            </a>
            <a
              href="# "
              className="st-root-link item-company st-has-dropdown nav-link"
              data-dropdown="apiDropdown"
            >
              API{" "}
            </a>
            <a
              href="# "
              className="st-root-link item-company st-has-dropdown nav-link"
              data-dropdown="preciosDropdown"
            >
              Productos
            </a>
            <a
              href="/blog"
              className="st-root-link item-company nav-link"
            >
              Blog
            </a>
            <a
              href="/prensa"
              className="st-root-link item-company nav-link"
            >
              Prensa
            </a>
          </li>
          <li className="st-nav-section st-nav-secondary nav-item">
            <a
              className="btn btn-rounded btn-outline mr-3 px-3"
              href="/login"
              target="_blank"
              rel="noreferrer"
              rounded="true"
            >
              <i className="fas fa-sign-in-alt d-none d-md-inline mr-md-0 mr-lg-2"></i>
              <span className="d-md-none d-lg-inline" style={{ "fontSize": "0.75rem" }}>Iniciar sesión</span>
            </a>
            <a
              className="btn btn-rounded btn-solid px-3"
              href="/signup"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fas fa-user-plus d-none d-md-inline mr-md-0 mr-lg-2"></i>
              <span className="d-md-none d-lg-inline" style={{ "fontSize": "0.75rem" }}>Registrarme</span>
            </a>
          </li>
          <li className="st-nav-section st-nav-mobile nav-item">
            <button className="st-root-link navbar-toggler" type="button">
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
            </button>
            <div className="st-popup">
              <div className="st-popup-container">
                <a href="# " className="st-popup-close-button">
                  Cerrar
                </a>
                <div className="st-dropdown-content-group">
                  <h4 className="text-uppercase regular">Páginas</h4>
                  <a className="regular text-primary" href="/about">
                    <i className="far fa-building icon"></i> Nosotros{" "}
                  </a>
                  <a className="regular text-success" href="/contact">
                    <i className="far fa-envelope icon"></i> Contacto{" "}
                  </a>
                  <a className="regular text-info" href="/faqs">
                    <i className="far fa-question-circle icon"></i> Preguntas
                    frecuentes
                  </a>
                </div>
                <div className="st-dropdown-content-group b-t bw-2">
                  <h4 className="text-uppercase regular">
                    <i className="fas fa-hand-holding-usd icon"></i> API
                  </h4>
                  <a href="/docs">¿Cómo comenzar?</a>
                  <a href="/certificacion-documentos/docs">
                    Certificación de Documentos
                  </a>
                  {/*<!--<a href="/certificacion-documentos/docs">Seguimiento de Producción</a>
                                <a href="/certificacion-documentos/docs">Seguimiento de Donaciones</a>-->*/}
                </div>
                {/*<!--<div className="st-dropdown-content-group b-t bw-2">
                                <h4 className="text-uppercase regular"><i className="fas fa-hand-holding-usd icon"></i> Precios</h4>
                                <a href="/certificacion-documentos/precios">Certificación de Documentos</a>
                                <a href="/certificacion-documentos/precios">Seguimiento de Producción</a>
                                <a href="/certificacion-documentos/precios">Seguimiento de Donaciones</a>
                            </div>-->*/}
                <div className="st-dropdown-content-group bg-6 b-t">
                  <a href="/login">
                    Iniciar Sesión
                    <i className="fas fa-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div className="st-dropdown-root">
        <div className="st-dropdown-bg">
          <div className="st-alt-bg"></div>
        </div>
        <div className="st-dropdown-arrow"></div>
        <div className="st-dropdown-container">
          <div className="st-dropdown-section" data-dropdown="apiDropdown">
            <div className="st-dropdown-content">
              <div className="st-dropdown-content-group">
                <a className="dropdown-item" href="/docs">
                  <div className="media mb-4">
                    <div className="media-body">
                      <h3 className="link-title m-0">¿Cómo comenzar?</h3>
                      <p className="m-0 color-2">
                        Aprende a consumir cualquiera de nuestras APIs
                      </p>
                    </div>
                  </div>
                </a>
                <a
                  className="dropdown-item"
                  href="/docs/certificacion-documentos"
                >
                  <div className="media mb-4">
                    <i className="fas fa-palette icon fa-2x"></i>
                    <div className="media-body">
                      <h3 className="link-title m-0">
                        Certificación de Documentos
                      </h3>
                      <p className="m-0 color-2">
                        Verifica la autenticidad de tus documentos
                      </p>
                    </div>
                  </div>
                </a>
                {/*<!--<a className="dropdown-item" href="/certificacion-documentos/docs">
                                <div className="media mb-4">
                                    <i className="fas fa-bars icon fa-2x"></i>
                                    <div className="media-body">
                                        <h3 className="link-title m-0">Seguimiento de Suministros</h3>
                                        <p className="m-0 color-2">Muestra la procedencia de tus productos</p>
                                    </div>
                                </div>
                            </a>
                            <a className="dropdown-item" href="/certificacion-documentos/docs">
                                <div className="media mb-4">
                                    <i className="fas fa-cookie-bite icon fa-2x"></i>
                                    <div className="media-body">
                                        <h3 className="link-title m-0">Seguimiento de donaciones</h3>
                                        <p className="m-0 color-2">Muestra hasta donde llegó la ayuda</p>
                                    </div>
                                </div>
                        </a>-->*/}
              </div>
            </div>
          </div>
          <div className="st-dropdown-section" data-dropdown="preciosDropdown">
            <div className="st-dropdown-content">
              <div className="st-dropdown-content-group">
                <a className="dropdown-item" href="/certificacion-documentos">
                  <div className="media mb-4">
                  <FontAwesomeIcon icon={faFile} className="m-1 mt-2 mr-3" size='2x'/>
                    <div className="media-body">
                      <h3 className="link-title m-0">
                        Seguridad de Archivos
                      </h3>
                      <p className="m-0 color-2">
                        Archivos infalsificables y fáciles de verificar
                      </p>
                    </div>
                  </div>
                </a>
                <a className="dropdown-item" href="https://nfticket.lifechain.mx" target="_blank" rel="noreferrer">
                  <div className="media mb-4">
                  <FontAwesomeIcon icon={faMusic} className="m-1 mt-2 mr-3" size='2x'/>
                    <div className="media-body">
                      <h3 className="link-title m-0">TICKETS PARA EVENTOS</h3>
                      <p className="m-0 color-2">Crea tickets seguros e infalsificables</p>
                    </div>
                  </div>
                </a>
                <a className="dropdown-item" href="/consultorias" hidden={true}>
                  <div className="media mb-4">
                    <FontAwesomeIcon icon={faBriefcase} className="m-1 mt-2 mr-3" size='2x'/>
                    <div className="media-body">
                      <h3 className="link-title m-0">Consultoría y Desarrollo</h3>
                      <p className="m-0 color-2">Usa metodologías ágiles para innovar con Blockchain</p>
                    </div>
                  </div>
                </a>
                <a className="dropdown-item" href="/seguimiento-suministros" hidden={true}>
                  <div className="media mb-4">
                    <FontAwesomeIcon icon={faShareAlt} className="m-1 mt-2 mr-3" size='2x'/>
                    <div className="media-body">
                      <h3 className="link-title m-0">
                        Seguimiento de Suministros
                      </h3>
                      <p className="m-0 color-2">
                        Ten la trazabilidad segura de tus productos con Blockchain
                      </p>
                    </div>
                  </div>
                </a>
                <a className="dropdown-item" href="/seguimiento-donaciones" hidden={true}>
                  <div className="media mb-4">
                  <FontAwesomeIcon icon={faHeart} className="m-1 mt-2 mr-3" size='2x'/>
                    <div className="media-body">
                      <h3 className="link-title m-0">Seguimiento de donaciones</h3>
                      <p className="m-0 color-2">Da confianza y transparencia con Blockchain</p>
                    </div>
                  </div>
                </a>
                <a className="dropdown-item" href="/gobierno-digital" hidden={true}>
                  <div className="media mb-4">
                  <FontAwesomeIcon icon={faHandshake} className="m-1 mt-2 mr-3" size='2x'/>
                    <div className="media-body">
                      <h3 className="link-title m-0">Gobierno Digital</h3>
                      <p className="m-0 color-2">Asesoría en la creación de leyes y políticas con Blockchain</p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navigation;
