import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
function ViewAudio(props) {
  return (
    <div className="ViewAudio">
      <Modal {...props} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Reproducir audio</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <audio controls autoPlay style={{ width: "100%" }} id="audioPlayer">
            <source src={props.srcurl} id="mp3Src" type="audio/mpeg" />
            Tu navegador no soporta elementos de audio.
          </audio>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Cerrar
          </Button>
          <a href={props.srcurl} id="cmdDescargarAudio" download>
            <Button variant="primary">Descargar</Button>
          </a>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
export default ViewAudio;
