import React from "react";
function Partners() {
    return (
        <section className="partners bg-6 alpha-4 b-b">
            <div className="section-heading mb-6 text-center">
                <h5 className="accent bold small text-uppercase">
                    Ellos confian en nosotros{" "}
                </h5>
                <h2>Aliados y clientes</h2>
            </div>
            <div className="container py-5 " style={{ backgroundColor: "#fc6d57" }}>
                <div className="swiper-container" data-sw-show-items="5" data-sw-space-between="30" data-sw-autoplay="2500" data-sw-loop="true">
                    <div className="swiper-wrapper align-items-center">
                        <div className="swiper-slide">
                            <a href="https://slide.com.mx/es" target="_blank" rel="noreferrer">
                                <img src="images/partners/slide.png" className="img-responsive" alt="Slide Technology" style={{ maxHeight: "80px", margin: "auto" }} />
                            </a>
                        </div>
                        <div className="swiper-slide">
                            <a href="https://californiavivelamusica.live/" target="_blank" rel="noreferrer">
                                <img src="images/partners/californiaViveLaMusica.png" className="img-responsive" alt="California Vive la Música" style={{ maxHeight: "80px", margin: "auto" }} />
                            </a>
                        </div>
                        <div className="swiper-slide">
                            <a href="https://bidlab.org/es" target="_blank" rel="noreferrer">
                                <img src="images/partners/bidlab.png" className="img-responsive" alt="BID LAB" style={{ maxHeight: "80px", margin: "auto" }} />
                            </a>
                        </div>
                        <div className="swiper-slide">
                            <a href="https://www.talent-network.org/" target="_blank" rel="noreferrer">
                                <img src="images/partners/talentNetwork.png" className="img-responsive" alt="Talent Network" style={{ maxHeight: "80px", margin: "auto" }} />
                            </a>
                        </div>
                        <div className="swiper-slide">
                            <a href="https://www.lacchain.net/" target="_blank" rel="noreferrer">
                                <img src="images/partners/lacchain.png" className="img-responsive" alt="LACChain" style={{ maxHeight: "80px", margin: "auto" }} />
                            </a>
                        </div>
                        <div className="swiper-slide">
                            <a href="https://www.kionetworks.com/" target="_blank" rel="noreferrer">
                                <img src="images/partners/kio.webp" className="img-responsive" alt="KIO Networks" style={{ maxHeight: "80px", margin: "auto" }} />
                            </a>
                        </div>
                        <div className="swiper-slide">
                            <a href="https://www.retozapopan.com.mx/" target="_blank" rel="noreferrer">
                                <img src="images/partners/retoZapopan.png" className="img-responsive" alt="Reto Zapopan" style={{ maxHeight: "80px", margin: "auto" }} />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Partners;

