import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
function ViewImg(props) {
  return (
    <div className="ViewImg">
      <Modal {...props} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Mostrar PDF</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img
            id="imgCert"
            style={{ width: "100%" }}
            src={props.srcurl}
            alt="Imágen certificada"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
export default ViewImg;
