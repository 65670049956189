import React, { useState, useEffect } from "react";
import Api from "../util/api";
import ReCAPTCHA from "react-google-recaptcha";
import Util from "../util/util";
import swal from 'sweetalert2';
import useLoading from "../hooks/useLoading";
import settings from "../config/settings";
import ReCaptchaV3 from "./ReCaptchaV3";

function FormLogin() {
  const { startLoading, stopLoading } = useLoading();
  const recaptchaRef = React.createRef();
  const [state, setState] = useState({
    auth: {
      email: "",
      pass: "",
    },
    error: null,
    loading: false,
  });
  const handleChange = (e) => {
    const { id, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      auth: {
        ...prevState.auth,
        [id]: value,
      },
    }));
  };

  function setCaptchaToken(value) {
    setState((prevState) => ({
      ...prevState,
      auth: {
        ...prevState.auth,
        captchaToken: value,
      },
    }));
  }

  useEffect(() => {
    validateToken();
  }, []);

  const handleCaptcha = async (e) => {
    e.preventDefault();
    window.grecaptcha.ready(() => {
      window.grecaptcha.execute(settings.captcha_key, { action: 'login' }).then((token) => {
        loginClick(token);
      });
    });
  }

  const loginClick = async (captchaToken) => {
    try {
      startLoading();
      console.log({
        captchaToken,
        ...state.auth
      });
      var res = await Api.auth.login({
        captchaToken,
        ...state.auth
      });
      console.log(res);
      var data = await res.json();
      console.log(data);
      if (await Util.requestSuccess(res)) {
        localStorage.setItem("JWT", data.token);
        localStorage.setItem("user", JSON.stringify(data.user));
        window.location.href = "/app/documentos/";
      } else {
        stopLoading();
        await swal.fire("¡Ups!", data.msg, "error");
        recaptchaRef.current.reset();
      }
    } catch (e) {
      console.log(e);
      stopLoading();
      await swal.fire("¡Ups!", "Error al iniciar sesión. Es posible que tengas problemas con tu conexión a internet.", "error");
      recaptchaRef.current.reset();
    }
  };

  const validateToken = async (e) => {
    var jwt = localStorage.getItem("JWT");
    if (jwt) {
      var res = await Api.auth.tokenValido();
      if (await Util.requestSuccess(res)) {
        window.location.href = "/app/documentos/";
      }
    }
  }

  return (
    <form
      className="cozy"
      action=""
      data-validate-on="submit"
      autoComplete="off"
      noValidate
    >
      <label className="control-label bold small text-uppercase color-2">
        Usuario / correo
      </label>
      <div className="form-group has-icon">
        <input
          type="text"
          id="email"
          name="Login[username]"
          className="form-control form-control-rounded"
          placeholder="Escribe tu correo "
          onChange={handleChange}
          required
        />
        <i className="icon fas fa-user"></i>
      </div>
      <label className="control-label bold small text-uppercase color-2">
        Contraseña
      </label>
      <div className="form-group has-icon">
        <input
          type="password"
          id="pass"
          name="Login[password]"
          className="form-control form-control-rounded"
          placeholder="Tu contraseña"
          onChange={handleChange}
          required
        />
        <i className="icon fas fa-lock"></i>
      </div>
      {/*<ReCAPTCHA
        ref={recaptchaRef}
        onChange={onChangeCaptcha}
        sitekey={Settings.captcha_key}
      />*/}
      <ReCaptchaV3
        siteKey={settings.captcha_key}
        action="login"
        onVerify={(token) => setCaptchaToken(token)}  // Guardar el token en el estado
      />
      <div className="form-group d-flex align-items-center justify-content-between">
        <a href="/forgot" className="text-warning small">
          ¿Olvidaste tu contraseña?
        </a>
        <div className="ajax-button">
          <div className="fas fa-check btn-status text-success success"></div>
          <div className="fas fa-times btn-status text-danger failed"></div>
          <button
            id="cmdLogin"
            className="btn btn-accent btn-rounded"
            onClick={handleCaptcha}
          >
            Inicio
            <i className="fas fa-long-arrow-alt-right ml-2"></i>
          </button>
        </div>
      </div>

    </form>
  );
}

export default FormLogin;
