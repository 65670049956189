import React, { useState } from "react";
import Util from "../util/util";
//import Api from "../util/api";
//import Settings from "../config/settings";

function CtlNuevoDocumento(props) {
  console.log(props);
  const [state, setState] = useState({
    documento: props.documento,
    privado: false,
    vigencia: null,
  });

  async function handleChangePrivacity(e) {
    console.log(e.target.value);
    setState(() => ({
      ...state,
      privado: !state.privado,
    }));
    props.onChangePrivacidad(props.index, !state.privado);
  }

  async function handleChangeVigencia(e) {
    setState((prevState) => ({
      ...prevState,
      vigencia: e.target.value,
    }));
    console.log(state);
    props.onChangeVigencia(props.index, e.target.value);
  }

  return (
    <div className="CtlNuevoDocumento">
      <div className="row stdBorder">
        <div className="col-md-4 col-xs-4">
          <p>{props.documento.file.name}</p>
        </div>
        <div className="col-md-2 col-xs-2">
          <p>{Util.bytesToSize(props.documento.file.size)}</p>
        </div>
        <div className="col-md-3 col-xs-3">
          <input
            id="txtDateindexArray+'"
            onChange={handleChangeVigencia}
            type="date"
          />
        </div>
        <div className="col-md-1 col-xs-1">
          <input
            id="checkPrivadoindexArray+'"
            onChange={handleChangePrivacity}
            type="checkbox"
            checked={state.privado}
          />
        </div>
        <div className="col-md-2 col-xs-2">
          {props.state === "En espera" ? <p>En espera</p> : null}
          {props.state === "En proceso" ? <p>En proceso</p> : null}
          {props.state === "Aceptado" ? (
            <p className="text-success">✔ Aceptado</p>
          ) : null}
          {props.state.search("Error") > -1 ? (
            <p className="text-danger">✖ {props.state}</p>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default CtlNuevoDocumento;
