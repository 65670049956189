import React, { useEffect, Fragment } from "react";
import Navigation from "../../components/Navigation";
import BlogFooter from "../../components/BlogFooter";

function Prensa() {
  useEffect(() => {
    document.title = "LifeChain - Prensa";
  });

  return (
    <div className="Prensa">
      <Fragment>
        <Navigation />
        <main>
			<header className="page header color-1 overlay gradient gradient-43 alpha-8 image-background cover">
				<div className="container">
					<div className="row">
						<div className="col-md-6">
							<h1 className="display-4 color-1 mb-4">LifeChain en las noticias</h1>
							<p className="lead color-1">Explora algunas noticias y artículos en los que mencionan a
								LifeChain</p>
						</div>
					</div>
				</div>
			</header>
			<section className="section">
				<div className="container">
					<div className="row gap-y">
						<div className="col-md-6 col-lg-4">
							<div className="card card-blog shadow-box shadow-hover border-0">
								<a href="/prensa/criptonoticias-donaciones-impuestos">
									<img className="card-img-top img-responsive" src="/images/blog/bandera-mex.png" alt=""/>
								</a>
								<div className="card-body">
									<div className="d-flex align-items-center justify-content-between">
										<div className="author d-flex align-items-center">
											<img className="author-picture rounded-circle icon-md shadow-box"
												src="/images/landing/avatar/Criptonoticias.jpg" alt="..."/>
											<p className="small bold my-0">Emily Faria - Criptonoticias</p>
										</div>
									</div>
									<hr/>
									<p className="card-title regular">
										<a href="/prensa/criptonoticias-donaciones-impuestos">Iniciativa Peso x Peso:
											“Nuestro sueño es tokenizar a México”</a>
									</p>
									<p className="card-text color-2">El proyecto está creado sobre la blockchain de
										Ethereum.</p>
									<p className="bold small color-2 my-0">
										<small>Enero 20, 2019</small>
									</p>
								</div>
							</div>
						</div>
						<div className="col-md-6 col-lg-4">
							<div className="card card-blog shadow-box shadow-hover border-0">
								<a href="/prensa/cucea-talent-land">
									<img className="card-img-top img-responsive"
										src="/images/blog/PremiosJaliscoTalentLandhackathon.jpeg" alt=""/>
								</a>
								<div className="card-body">
									<div className="d-flex align-items-center justify-content-between">
										<div className="author d-flex align-items-center">
											<img className="author-picture rounded-circle icon-md shadow-box"
												src="/images/landing/avatar/UdG.jpg" alt="..."/>
											<p className="small bold my-0">Dora Solís - Universidad de Guadalajara</p>
										</div>
									</div>
									<hr/>
									<p className="card-title regular">
										<a href="/prensa/cucea-talent-land">Una plataforma que pretende seguir el camino
											de nuestros impuestos</a>
									</p>
									<p className="card-text color-2">“Sabemos que atacamos un problema que muchos mexicanos
										tienen, y creemos que podemos provocar mucho impacto y mejorar la credibilidad
										en el gobierno y en sus procesos”</p>
									<p className="bold small color-2 my-0">
										<small>Abril 25, 2018</small>
									</p>
								</div>
							</div>
						</div>
						<div className="col-md-6 col-lg-4">
							<div className="card card-blog shadow-box shadow-hover border-0">
								<a href="/prensa/imagen-talent-land">
									<img className="card-img-top img-responsive" src="/images/blog/rifadodeldia.png" alt=""/>
								</a>
								<div className="card-body">
									<div className="d-flex align-items-center justify-content-between">
										<div className="author d-flex align-items-center">
											<img className="author-picture rounded-circle icon-md shadow-box"
												src="/images/landing/avatar/ImagenTelevision.jpg" alt="..."/>
											<p className="small bold my-0">Francisco Zea - Imágen Televisión</p>
										</div>
									</div>
									<hr/>
									<p className="card-title regular">
										<a href="/prensa/imagen-talent-land">El rifado del día</a>
									</p>
									<p className="card-text color-2">Conoce el proyecto ganador del Hackathon de Jalisco
										Talent Land &quot;Presupuesto Participativo&quot; para conocer en qué se gastan tus
										impuestos o donaciones.</p>
									<p className="bold small color-2 my-0">
										<small>Abril 23, 2018</small>
									</p>
								</div>
							</div>
						</div>
						<div className="col-md-6 col-lg-4">
							<div className="card card-blog shadow-box shadow-hover border-0">
								<a href="/prensa/ganadores-talent-land">
									<img className="card-img-top img-responsive"
										src="/images/blog/PremiosJaliscoTalentLandhackathon.jpeg" alt=""/>
								</a>
								<div className="card-body">
									<div className="d-flex align-items-center justify-content-between">
										<div className="author d-flex align-items-center">
											<img className="author-picture rounded-circle icon-md shadow-box"
												src="/images/landing/avatar/Entrepreneur.jpg" alt="..."/>
											<p className="small bold my-0">Entrepeneur</p>
										</div>
									</div>
									<hr/>
									<p className="card-title regular">
										<a href="/prensa/ganadores-talent-land">Estos son los proyectos ganadores del
											hackathon de Talent Land </a>
									</p>
									<p className="card-text color-2">De blockchain y fake news fueron los proyectos
										ganadores en el hackathon más grande del mundo, que se llevó a cabo en Jalisco
										Talent Land que tuvo lugar en Expo Guadalajara.</p>
									<p className="bold small color-2 my-0">
										<small>Abril 9, 2018</small>
									</p>
								</div>
							</div>
						</div>
						<div className="col-md-6 col-lg-4">
							<div className="card card-blog shadow-box shadow-hover border-0">
								<a href="/prensa/informador-talent-land">
									<img className="card-img-top img-responsive"
										src="/images/blog/PremiosJaliscoTalentLandhackathon2.jpeg" alt=""/>
								</a>
								<div className="card-body">
									<div className="d-flex align-items-center justify-content-between">
										<div className="author d-flex align-items-center">
											<img className="author-picture rounded-circle icon-md shadow-box"
												src="/images/landing/avatar/Informador.jpg" alt="..."/>
											<p className="small bold my-0">Andrés Gallegos - El Informador</p>
										</div>
									</div>
									<hr/>
									<p className="card-title regular">
										<a href="/prensa/informador-talent-land">Impuestos transparentes y combate a
											Fake News, proyectos ganadores del Talent Hackatón</a>
									</p>
									<p className="card-text color-2">&quot;Presupuesto Participativo&quot; fue el mejor proyecto de la
										vertical &quot;Gobierno Digital&quot;, patrocinado por la Secretaría de la Función Pública
										del Gobierno Federal.</p>
									<p className="bold small color-2 my-0">
										<small>Abril 6, 2018</small>
									</p>
								</div>
							</div>
						</div>
						<div className="col-md-6 col-lg-4">
						<div className="card card-blog shadow-box shadow-hover border-0">
							<a href="/prensa/economista-talent-land">
								<img className="card-img-top img-responsive" src="/images/blog/premiacion-talent.webp" alt=""/>
							</a>
								<div className="card-body">
								<div className="d-flex align-items-center justify-content-between">
									<div className="author d-flex align-items-center">
										<img className="author-picture rounded-circle icon-md shadow-box" src="/images/landing/avatar/Informador.jpg" alt="..."/>
										<p className="small bold my-0">Rodrigo Riquelme y Elizabeth Meza - El Economista</p>
									</div>
								</div>
								<hr/>
								<p className="card-title regular">
									<a href="/prensa/economista-talent-land">Blockchain y combate a Fake News ganan hackathon en Talent Land 2018</a>
								</p>
								<p className="card-text color-2">Participaron más de 300 proyectos que se desarrollaron en cuatro días de 18 verticales.</p>
								<p className="bold small color-2 my-0">
									<small>Abril 6, 2018</small>
								</p>
							</div>
						</div>
					</div>
					</div>

				</div>
			</section>
		</main>
		<BlogFooter/>
      </Fragment>
    </div>
  );
}

export default Prensa;