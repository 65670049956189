import React, { createContext, useState, useEffect } from 'react';

const UserContext = createContext();

function UserProvider({ children }) {
    const [stateApp, setStateApp] = useState({
        llave_publica_wallet: null,
        walletConnected: false,
    });

    const [showLoginModal, setShowLoginModal] = useState(false);
    const [user, setUser] = useState();

    useEffect(() => {
        try {
            const userString = localStorage.getItem("user");
            console.log(userString);
            if (!userString) return;
            const user = JSON.parse(userString);
            if (!user) return;
            console.log("user");
            console.log(user);
            setUser(user);
        } catch (e) {
            console.log(e);
        }

    }, [])

    const setWallet = (llave_publica, walletConnected) => {
        setStateApp((prevState) => ({
            ...prevState,
            llave_publica_wallet: llave_publica
        }));
    }

    return (
        <UserContext.Provider value={{ stateApp, setStateApp, setWallet, user, setUser, showLoginModal, setShowLoginModal }}>
            {children}
        </UserContext.Provider>
    );
}



export { UserContext, UserProvider };