import React, { useEffect, Fragment } from "react";
import Navigation from "../../components/Navigation";
import BlogFooter from "../../components/BlogFooter";

function PrensaCuceaTalent() {

  useEffect(() => {
    document.title = "LifeChain - Blog";
  });

  return (
    <div className="PrensaCuceaTalent">
      <Fragment>
        <Navigation />
        <main>
			<header className="section color-1 fullscreen blog-single-header image-background"
				style={{"backgroundImage": "url('/images/blog/1.svg')", "backgroundColor": "#464685"}}>
				<div className="container text-center">
					<div className="row">
						<div className="col-md-10 mx-md-auto">
							<h1 className="post-title display-4">Una plataforma que pretende seguir el camino de nuestros
								impuestos - Universidad de Guadalajara</h1>
						</div>
					</div>
					<a href="#blog-post" className="scrollto mt-auto">
						<div className="arrow-down">
							<i className="pe pe-7s-angle-down pe-4x color-5"></i>
						</div>
					</a>
				</div>
			</header>
			<section className="blog-post" id="blog-post">
				<div className="container">
					<div className="row">
						<div className="col-md-8 mx-auto">
							<div className="media">
								<img className="mr-3 rounded-circle icon-md" src="/images/landing/avatar/UdG.jpg" alt=""/>
								<div className="media-body small">
									<span className="author">por
										<a href=" #" target="_blank" rel="noreferrer"> Dora Solís</a>
									</span>
									<span className="d-block color-2 alpha-6">Abril 25, 2018</span>
								</div>
							</div>
							<hr className="mb-5"/>
							<p className="lead color-2 italic mb-5">A pesar de ser de Centros Universitarios diferentes,
								Goretti Oronia, egresada del CUCEA y Alejandro López, estudiante del CUCEI, comparten el
								pensamiento de que los estudiantes “se sorprenderían de todo lo que se puede lograr con
								una idea y con ganas de hacer algo”.</p>
							<figure className="shadow rounded mb-5">
								<img className="img-responsive rounded" style={{"width":"100%"}}
									src="/images/blog/PremiosJaliscoTalentLandhackathon.jpeg" alt=""/>
							</figure>
							<p>Precisamente, la búsqueda de ideas para crecer como personas y profesionistas, así como
								las ganas de contribuir a que el país avance en cuestión de tecnología, fueron los
								elementos que llevaron a Alejandro y a Goretti a ser el equipo ganador de la categoría
								“Participación Ciudadana” de la clasificación “Problemáticas Públicas”, en la primera
								edición del Hackathon en Jalisco Talent Land 2018.</p>
							<p>El proyecto con el que obtuvieron el triunfo y que desarrollaron en conjunto ambos
								estudiantes, se denomina “Presupuesto Participativo”. Se trata de una plataforma que
								pretende trazar, por medio de Blockchain, el camino de los impuestos desde que los
								contribuyentes pagan, hasta la obra o proyecto en el que se destina el dinero.</p>
							<p>Presupuesto Participativo también posibilita que los ciudadanos, a través de hashtags,
								demanden en sus redes sociales las necesidades de la colonia en que viven y mediante
								Inteligencia Artificial, se rastrean, procesan y enlistan las propuestas para que los
								ciudadanos voten por las que les parezcan más pertinentes. Cada propuesta y cada voto
								que el sistema reciba, será enviado a un Contrato Inteligente dentro de la Blockchain y
								al final de cada mes, el Contrato Inteligente revisa la lista de propuestas y votos para
								tomar una decisión objetiva y sin intervención humana, enviando los fondos
								presupuestales a la institución encargada de llevar a cabo el proyecto ganador.</p>
							<p>Goretti y Alejandro comentan que el interés por el tema de los impuestos surgió porque el
								papá de Goretti trabaja en una institución de gobierno, y muchas veces ella nota que la
								gente no cree en ese tipo de instituciones aunque estas hagan bien su trabajo, por lo
								que ambos piensan que al aumentar la transparencia en los procesos de las instituciones
								gubernamentales, se “puede cambiar la perspectiva de la gente hacia el gobierno”.</p>
							<p>Para el desarrollo de Presupuesto Participativo contaron con la ayuda de la unidad de
								Gobierno Digital, perteneciente al Gobierno Federal. “Ellos nos ayudaron tanto en el
								lado de aterrizar la idea, como en el lado técnico, a introducirnos en el mundo del
								Blockchain y los contratos inteligentes”. </p>
							<p>Al desarrollar el proyecto, los estudiantes se encontraron con dos dificultades
								principales. En primer lugar, las nuevas tecnologías con las que trabajaron. El esquema
								de Blockchain que utilizaron, junto con los contratos inteligentes, son tecnologías que
								no tienen más de cinco años existiendo, por lo que requirieron de mucha investigación
								para posteriormente poder implementarlas. En segundo lugar, la falta de tiempo: “El
								Hackathon sólo dura cinco días, y nuestro proyecto es ambicioso”.</p>
							<p>El siguiente paso de Goretti y Alejandro es darle continuidad a Presupuesto
								Participativo. “La idea es terminar el desarrollo del proyecto y sacarlo a la luz”,
								mencionan.</p>
							<p>“Sabemos que atacamos un problema que muchos mexicanos tienen, y creemos que podemos
								provocar mucho impacto y mejorar la credibilidad en el gobierno y en sus procesos”,
								añaden.</p>
							<br />
							<p>Link: <a
									href="http://www.cucea.udg.mx/es/noticia/25-abr-2018/una-plataforma-que-pretende-seguir-el-camino-de-nuestros-impuestos"
									target="_blank" rel="noreferrer">http://www.cucea.udg.mx/es/noticia/25-abr-2018/una-plataforma-que-pretende-seguir-el-camino-de-nuestros-impuestos</a>
							</p>
						</div>
					</div>
				</div>
			</section>
			<section>
				<div className="container py-5 b-t b-2x">
					<div className="media align-items-center">
						<img className="d-flex mr-3 rounded-circle shadow" style={{"width": "100px", "height": "100px"}}
							src="/images/landing/avatar/UdG.jpg" alt="..."/>
						<div className="media-body">
							<h5 className="my-0 bold">Dora Solís</h5>
							<span className="color-2">CUCEA / Universidad de Guadalajara</span>
							<hr/>
							<p className="small color-2 mb-0">Fotografía: Foto EE: Elizabeth Meza. tomada del portal <a
									href="www.eleconomista.com.mx">www.eleconomista.com.mx</a></p>
						</div>
					</div>
				</div>
			</section>
			<section>
				<div className="container py-5 b-t">
					<ul className="list-unstyled d-flex flex-wrap flex-row align-items-center">
						<li className="mr-4">
							<i className="fas fa-tag color-2"></i>
						</li>
						<li>
							<a href=" #" className="badge badge-pill badge-outline-2 mr-2">blockchain</a>
						</li>
						<li>
							<a href=" #" className="badge badge-pill badge-outline-2 mr-2">talent land</a>
						</li>
						<li>
							<a href=" #" className="badge badge-pill badge-outline-2 mr-2">presupuesto participativo</a>
						</li>
						<li>
							<a href=" #" className="badge badge-pill badge-outline-2 mr-2">hackathon</a>
						</li>
						<li>
							<a href=" #" className="badge badge-pill badge-outline-2 mr-2">jalisco</a>
						</li>
					</ul>
					<ul className="list-unstyled d-flex flex-wrap flex-row align-items-center">
						<li className="mr-4">
							<i className="fas fa-bookmark color-2"></i>
						</li>
						<li>
							<a href=" #" className="btn btn-circle btn-sm brand-twitter mr-3">
								<i className="fab fa-twitter"></i>
							</a>
						</li>
						<li>
							<a href=" #" className="btn btn-circle btn-sm brand-facebook mr-3">
								<i className="fab fa-facebook"></i>
							</a>
						</li>
					</ul>
				</div>
			</section>
		</main>
        <BlogFooter/>
      </Fragment>
    </div>
  );
}

export default PrensaCuceaTalent;