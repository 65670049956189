import React, { useState } from "react";
import Api from "../util/api";
import ReCAPTCHA from "react-google-recaptcha";
import Util from "../util/util";
import Settings from "../config/settings";
import swal from 'sweetalert2';

function BlogFooter(props) {
  const recaptchaRef = React.createRef();
  const [state, setState] = useState({
    auth: {
      email: "",
      captchaToken: "",
    },
    error: null,
    loading: false,
  });
  const handleChange = (e) => {
    const { id, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      auth: {
        ...prevState.auth,
        [id]: value,
      },
    }));
  };

  function onChangeCaptcha(value) {
    console.log("Captcha value:", value);
    setState((prevState) => ({
      ...prevState,
      auth: {
        ...prevState.auth,
        captchaToken: value,
      },
    }));
  }

  const suscribeClick = async (e) => {
    e.preventDefault();
    try {
      var res = await Api.prospectos.crear(
        state.auth
      );
      var data = await res.json();
      if (await Util.requestSuccess(res)) {
        await swal.fire("¡Listo!", '¡Gracias por registrarte!', "success");
      } else {
        console.log(data.msg);
        recaptchaRef.current.reset();
        await swal.fire("¡Ups!", 'Error al registrarte', "error");
      }
    } catch (err) {
      console.log(err);
      await swal.fire("¡Ups!", 'Error al registrarte', "error");
    }
  };

  return (
    <footer className="site-footer section text-center bg-6">
      <div className="container pb-4">
        <div className="section-heading">
          <h2 className="font-md">Mantente actualizado</h2>
          <p className="lead color-2">Suscribete al blog de LifeChain y recibe directamente en tu correo electrónico los
            últimos tips y consejos para innovar acercándote al futuro.</p>
        </div>
        <div className="row">
          <div className="col-12 col-md-6 mx-auto overflow-hidden">
            <form className="form" data-response-message-animation="slide-in-left" noValidate="novalidate">
              <div className="input-group">
                <input type="hidden" id="captchaScore" />
                <input type="email" id="txtEmailFooter" name="Subscribe[email]"
                  className="form-control rounded-circle-left" placeholder="Ingresa tu email" required="" onChange={handleChange} />
                <div className="input-group-append">
                  <button className="btn btn-rounded btn-accent" type="submit"
                    style={{ "fontSize": "0.75rem" }} onClick={suscribeClick}>Registrarme</button>
                </div>

              </div>
              <br />
              <ReCAPTCHA
                ref={recaptchaRef}
                onChange={onChangeCaptcha}
                sitekey={Settings.captcha_key}
              />
            </form>
            <div className="response-message">
              <i className="fas fa-check-circle font-lg accent"></i>
              <p className="font-md m-0">¡Gracias!</p>
              <p className="bold message">Ya estas suscrito a nuestro newsletter.</p>
            </div>
          </div>
        </div>
        <hr className="mt-5" />
        <div className="row align-items-center">
          <div className="col-md-2 text-center mx-auto">
            <img src="/images/landing/logo.png" alt="" className="logo" />
            <p className="mt-2 mb-0 color-2 small">© 2022{" "}
              <a className="brand bold" target="_blank" rel="noreferrer" href="https://lifechain.mx">lifechain.mx</a>. Todos los
              derechos reservados
            </p>
          </div>

        </div>
      </div>
    </footer>
  );
}

export default BlogFooter;
