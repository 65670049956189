import React, { useEffect, Fragment } from "react";
import Navigation from "../../components/Navigation";
import BlogFooter from "../../components/BlogFooter";

function PrensaCriptonoticiasPesoxPeso() {

  useEffect(() => {
    document.title = "LifeChain - Blog";
  });

  return (
    <div className="PrensaCriptonoticiasPesoxPeso">
      <Fragment>
        <Navigation />
		<main>
			<header className="section color-1 fullscreen blog-single-header image-background"
				style={{"backgroundImage": "url('/images/blog/1.svg')", "backgroundColor": "#464685"}}>
				<div className="container text-center">
					<div className="row">
						<div className="col-md-10 mx-md-auto">
							<h1 className="post-title display-4">Iniciativa Peso x Peso: “Nuestro sueño es tokenizar a
								México” - Criptonoticias</h1>
						</div>
					</div>
					<a href="#blog-post" className="scrollto mt-auto">
						<div className="arrow-down">
							<i className="pe pe-7s-angle-down pe-4x color-5"></i>
						</div>
					</a>
				</div>
			</header>
			<section className="blog-post" id="blog-post">
				<div className="container">
					<div className="row">
						<div className="col-md-8 mx-auto">
							<div className="media">
								<img className="mr-3 rounded-circle icon-md" src="/images/landing/avatar/Criptonoticias.jpg"
									alt=""/>
								<div className="media-body small">
									<span className="author">por
										<a href="https://www.entrepreneur.com/" target="_blank" rel="noreferrer"> Emily Faria</a>
									</span>
									<span className="d-block color-2 alpha-6">Enero 20, 2019</span>
								</div>
							</div>
							<hr className="mb-5"/>
							<figure className="shadow rounded mb-5">
								<img className="img-responsive rounded" style={{"width":"100%"}}
									src="/images/blog/bandera-mex.png" alt=""/>
							</figure>
							<p>Hacerle seguimiento a cada centavo de los impuestos y las donaciones, desde sus
								contribuyentes hasta su destino final, ha sido el motivo detrás de Peso x Peso, una
								iniciativa de tecnología blockchain creada por jóvenes mexicanos que persiguen hacerle
								frente al desvío de fondos mediante transacciones con criptoactivos, además de disminuir
								la desconfianza existente entre la población mexicana hacia sus instituciones.</p>
							<p>Con este proyecto, Oronia y López obtuvieron el primer lugar en el hackaton Talend Land
								2018, celebrado en Jalisco, México, en el pasado mes de abril. El proyecto se presentó
								en el evento con el nombre de Presupuesto Participativo.</p>
							<p>Actualmente existe un producto mínimo viable de la iniciativa, que lleva por nombre Peso
								x Peso, y el cual fue desarrollado gracias al respaldo del laboratorio tecnológico
								mexicano para emprendedores, Alchemy Labs, el cual a su vez forma parte de KIO Networks.
							</p>
							<p>Según la página web de Peso x Peso, a través de este sistema, el dinero fiduciario
								recolectado en impuestos y donaciones es cambiado a tokens. Seguidamente, dichos
								criptoactivos serán recibidos por la respectiva institución o agencia gubernamental, y
								posteriormente serán cambiados a pesos mexicanos por sus empleados o proveedores. De
								esta forma, los ciudadanos podrían darle seguimiento a su dinero.</p>
							<p>Cuando surgen iniciativas que utilizan la tecnología de criptoactivos, también surgen
								muchas preguntas en torno a tales proyectos. Para ahondar más en sus detalles, nos
								comunicamos con el equipo de Peso x Peso y KIO Networks. A continuación presentamos la
								información obtenida: </p>
							<p className="bold">¿Sobre cuál blockchain está construida Peso x Peso? ¿Una blockchain propia?
								¿Una blockchain existente? ¿Es un contrato inteligente de Ethereum o alguna otra
								blockchain? ¿Será una blockchain pública o privada? </p>
							<p>Por ahora está escrito sobre la blockchain de Ethereum. Por varias razones, entre ellas
								está la confianza de estar en una red pública difícil de manipular, al igual que la
								creación de contratos inteligentes. Sin embargo, no estamos casados con esta tecnología,
								de hecho estamos analizando y probando con otras blockchain para ver si nos dan más
								beneficios, como Cardano o Hyperledger.</p>
							<p className="bold">En el caso del pago de impuestos es necesario que los contribuyentes se
								identifiquen ante las autoridades para declararlos. Por otra parte, en el caso de las
								donaciones, no es obligatorio que la persona se identifique. ¿Cómo harán para abordar el
								tema de la identidad en la plataforma? ¿Aplicarán normas KYC? ¿Compartirán toda la
								información de los usuarios de Peso x Peso con las instituciones y agencias
								gubernamentales? </p>
							<p>Queremos tener una única plataforma para pagar los impuestos y donaciones, por lo que el
								contribuyente tendría que identificarse a través de la norma KYC a la hora de pagar
								impuestos por primera vez. Por lo tanto, mientras que el usuario no pague impuestos y
								sólo done, no será necesario comprobar su identidad por medio de la norma.</p>
							<p>Otra alternativa es la posibilidad de conectarnos con el SAT y que sea posible ingresar a
								nuestro sistema a través de la e-firma o RFC.</p>
							<p className="bold">En su página web mencionan que utilizarán tokens para darle seguimiento al
								dinero. ¿Cuál token o tokens utilizarán? ¿Un token propio de Peso x Peso? ¿Un token ya
								existente? </p>
							<p>Actualmente tenemos un token propio basado en el estándar ERC721 de la red de Ethereum,
								aunque dependiendo del caso, estamos pensando en crear también un segundo token basado
								en el estándar ERC20. Así podríamos dar diferentes tipos de seguimiento al dinero que el
								contribuyente o donador entrega.</p>
							<p className="bold">¿El código será abierto? para quien desee auditarlo y asegurarse de que el
								token no pueda ser modificado </p>
							<p>Nuestro fin es que se pueda revisar la trazabilidad del dinero dentro de cualquier
								escáner de red, no solo a través de nosotros, por lo tanto buscamos que cualquier
								persona, mediante un dispositivo con internet, pueda auditar el movimiento de su dinero
								en cualquier momento.</p>
							<p>Tenemos que aclarar que al tener un modelo de negocio, no somos una empresa de código
								abierto, pero si damos las herramientas para transparentar los procesos.</p>
							<p className="bold">¿Con cuáles herramientas podría dar seguimiento un usuario de Peso x Peso a
								los tokens que representan su aporte? </p>
							<p>Actualmente por razones de prototipo, tenemos una página web donde pueden ver los
								movimientos de su dinero (al igual que comprobarlo en cualquier escáner de red), también
								nos podemos adaptar a las necesidades de los usuarios y clientes, por medio de un módulo
								que se pueda incorporar al portal oficial de gobierno, o la página web de la fundación a
								la que apoyaron, entre otras opciones. Es decir, no está limitado.</p>
							<p>¿Cuáles factores regirán la tasa de cambio de esos tokens a pesos? ¿Con cuáles casas de
								cambio operarán en caso de ser un nuevo token? </p>
							<br />
							<p>Link: <a
									href="https://www.criptonoticias.com/entrevistas/iniciativa-peso-x-peso-nuestro-sueno-tokenizar-mexico/"
									target="_blank" rel="noreferrer">https://www.criptonoticias.com/entrevistas/iniciativa-peso-x-peso-nuestro-sueno-tokenizar-mexico/</a>
							</p>
						</div>
					</div>
				</div>
			</section>
			<section>
				<div className="container py-5 b-t b-2x">
					<div className="media align-items-center">
						<img className="d-flex mr-3 rounded-circle shadow" style={{"width": "100px", "height": "100px"}}
							src="/images/landing/avatar/Criptonoticias.jpg" alt="..."/>
						<div className="media-body">
							<h5 className="my-0 bold">Emily Faria</h5>
							<span className="color-2">Criptonoticias</span>
							<hr/>
							<p className="small color-2 mb-0">Imágen destacada por promesaartstudio y sashkin / <a
									href="//stock.adobe.com">stock.adobe.com</a></p>
						</div>
					</div>
				</div>
			</section>
			<section>
				<div className="container py-5 b-t">
					<ul className="list-unstyled d-flex flex-wrap flex-row align-items-center">
						<li className="mr-4">
							<i className="fas fa-tag color-2"></i>
						</li>
						<li>
							<a href=" #" className="badge badge-pill badge-outline-2 mr-2">blockchain</a>
						</li>
						<li>
							<a href=" #" className="badge badge-pill badge-outline-2 mr-2">talent land</a>
						</li>
						<li>
							<a href=" #" className="badge badge-pill badge-outline-2 mr-2">presupuesto participativo</a>
						</li>
						<li>
							<a href=" #" className="badge badge-pill badge-outline-2 mr-2">hackathon</a>
						</li>
						<li>
							<a href=" #" className="badge badge-pill badge-outline-2 mr-2">jalisco</a>
						</li>
					</ul>
					<ul className="list-unstyled d-flex flex-wrap flex-row align-items-center">
						<li className="mr-4">
							<i className="fas fa-bookmark color-2"></i>
						</li>
						<li>
							<a href=" #" className="btn btn-circle btn-sm brand-twitter mr-3">
								<i className="fab fa-twitter"></i>
							</a>
						</li>
						<li>
							<a href=" #" className="btn btn-circle btn-sm brand-facebook mr-3">
								<i className="fab fa-facebook"></i>
							</a>
						</li>
					</ul>
				</div>
			</section>
		</main>
        <BlogFooter/>
      </Fragment>
    </div>
  );
}

export default PrensaCriptonoticiasPesoxPeso;