import React, { useEffect, Fragment } from "react";
import Navigation from "../../components/Navigation";
import FooterLanding from "../../components/FooterLanding";
import { CopyBlock, codepen } from "react-code-blocks";

function DocsCertificacionDocumentos() {
	useEffect(() => {
		document.title = "LifeChain - API";
	});

	return (
		<div className="DocsCertificacionDocumentos">
			<Fragment>
				<Navigation dark={true} />
				<main>
					<section className="section terms">
						<div className="container pt-md-9">
							<h1 style={{ "marginTop": "50px" }}>Documentación de API para certificación de documentos</h1>
							<p className="color-2 small bold">Úiltima actualización el 23 de marzo, 2024.</p>
							<hr className="my-4" />
							<p style={{ "textAlign": "justify" }}>¡Bienvenido a LifeChain! aquí encontraras la documentación necesaria
								para certificar y validar tus documentos vía API, así como consultar tus créditos disponibles
								para certificar nuevos documentos.</p>

							<p style={{ "textAlign": "justify", "color": "red" }}>Nota:</p>
							<ul>
								<li style={{ "textAlign": "justify" }}>Cada certificación de documento por individual consume un
									crédito. El número de créditos disponibles depende del tipo de plan que tengas contratado
									para LifeChain Documentos. No nos hacemos responsables por el mal uso o uso irresponsable de
									los créditos una vez asignados a su usuario u organización.</li>
								<li style={{ "textAlign": "justify" }}>Para comenzar a trabajar con la API de LifeChain Documentos, es
									necesario que cuentes con tu apikey. Da click <a href="/docs">aquí</a> para saber como
									obtenerla.</li>
							</ul>

							<h3 style={{ "marginTop": "50px" }}>Consultar créditos disponibles</h3>
							<p style={{ "textAlign": "justify" }}>Con ayuda de esta petición podrás consultar tus créditos disponibles.
								Cada crédito sirve para certificar un documento dentro de LifeChain.</p>
							<ul>
								<li style={{ "textAlign": "justify" }}><b>Endpoint:</b> https://api.lifechain.mx/documentos/creditos/</li>
								<li style={{ "textAlign": "justify" }}><b>Verbo HTTP:</b> GET</li>
								<li style={{ "textAlign": "justify" }}><b>Ejemplo de petición:</b></li>
							</ul>

							<CopyBlock
								text={"curl 'https://api.lifechain.mx/documentos/creditos/' \\\n" +
									"-H 'apikey: G8Jx0p0iYS.KpZYXHqChmEG58Lg9KpCD1O9CN0SY7oUrnmHQB5CYmXhbjO0GtirV'"}
								showLineNumbers={false}
								theme={codepen}
								wrapLines={true}
								codeBlock
							/><br />

							<ul>
								<li style={{ "textAlign": "justify" }}><b>Ejemplo de respuesta exitosa:</b></li>
							</ul>
							<CopyBlock
								languaje={"javascript"}
								text={'{ "creditos": 5 }'}
								showLineNumbers={false}
								theme={codepen}
								wrapLines={true}
								codeBlock
							/><br />

							<h3 style={{ "marginTop": "50px" }}>Certificar un documento</h3>
							<p style={{ "textAlign": "justify" }}>Para certificar un documento es necesario mandar en el cuerpo de la
								petición el contenido del documento a certificar codificado en base64, asignándole el nombre
								“contenido”; además del nombre con el que se desea guardar el archivo (incluyendo el formato del
								mismo). Como opción adicional, puede asignarle privacidad a tu documento (si tu documento es
								privado, podrá ser validado por terceros, pero ningún usuario sin permisos podrá acceder al
								contenido de tu documento).</p>

							<ul>
								<li style={{ "textAlign": "justify" }}><b>Endpoint:</b> https://api.lifechain.mx/documentos/</li>
								<li style={{ "textAlign": "justify" }}><b>Endpoint de prueba (no consume créditos):</b>
									https://api.lifechain.mx/documentos/test/</li>
								<li style={{ "textAlign": "justify" }}><b>Verbo HTTP:</b> POST</li>
								<li style={{ "textAlign": "justify" }}><b>Ejemplo de petición:</b></li>
							</ul>
							<CopyBlock
								text={"curl 'https://api.lifechain.mx/documentos/test/' \\\n" +
									"-H 'apikey: G8Jx0p0iYS.KpZYXHqChmEG58Lg9KpCD1O9CN0SY7oUrnmHQB5CYmXhbjO0GtirV' \\\n" +
									"-H 'Content-Type: application/json' \\\n" +
									'-d \'{JSON.stringify({ "nombre": "prueba.txt", "privado": "false", "contenido": "SG9sYSBtdW5kbyEgc29tb3MgTGlmZUNoYWluIQo=", "extraData": "datos extra", "vigencia": "2021-10-06T00:00:00.000Z" })}\''}
								showLineNumbers={false}
								theme={codepen}
								wrapLines={true}
								codeBlock
							/><br />
							<ul>
								<li style={{ "textAlign": "justify" }}><b>Ejemplo de respuesta exitosa:</b></li>
							</ul>
							<CopyBlock
								text={JSON.stringify({
									"documento": {
										"txHashConfirmed": false,
										"privado": false,
										"_id": "5e7bf16dc713c40010cc8f34",
										"fecha_certificacion": "2020-03-26T00:03:57.257Z",
										"firmasECDSA": [
											{
												"_id": "5e7bf16dc713c40010cc8f35",
												"firma": "3044022046dee86dbfb1b8b60095672ea8c40f118848f50cf325b7ee84d19fa4b542b58302203ee23aa1e96342ffc5338af4e1f2622595ca4f6e3c8e94d125740bf2617049d3",
												"llave_publica": "039fb32f306a6bd7a97e5edc870ca7a53b49abde71ed4f0e74a6f6ad1842352111"
											}
										],
										"nombre": "prueba.txt",
										"fileHash": "028b2ea3ff771ea61bcbead7f71241999a78c92438734a331517b0ad020ce3e2",
										"originalHash": "028b2ea3ff771ea61bcbead7f71241999a78c92438734a331517b0ad020ce3e2",
										"extraData": "datos extra",
										"emisor": "5ceb04712f762724b6d528d6",
										"onModelEmisor": "Usuario",
										"vigencia": "2021-10-06T00:00:00.000Z"
									},
									"contenido": "SG9sYSBtdW5kbyEgc29tb3MgTGlmZUNoYWluIQo="
								}, null, '	')}
								showLineNumbers={false}
								theme={codepen}
								wrapLines={true}
								codeBlock
							/><br />
							<h3 style={{ "marginTop": "50px" }}>Consultar lista de documentos certificados</h3>
							<p style={{ "textAlign": "justify" }}>Para consultar la lista de los documentos certificados por medio de
								LifeChain.</p>
							<p style={{ "textAlign": "justify" }}>NOTA: Si quieres consultar la lista de documentos de una organización
								de la cuál eres administrador, es necesario que uses el apikey de la organización, y no el de tu
								usuario.</p>
							<ul>
								<li style={{ "textAlign": "justify" }}><b>Endpoint:</b> https://api.lifechain.mx/documentos/list/</li>
								<li style={{ "textAlign": "justify" }}><b>Verbo HTTP:</b> GET</li>
								<li style={{ "textAlign": "justify" }}><b>Ejemplo de petición:</b></li>
							</ul>
							<CopyBlock
								text={"curl 'https://api.lifechain.mx/documentos/list/' \\\n" +
									"-H 'apikey: G8Jx0p0iYS.KpZYXHqChmEG58Lg9KpCD1O9CN0SY7oUrnmHQB5CYmXhbjO0GtirV' "}
								showLineNumbers={false}
								theme={codepen}
								wrapLines={true}
								codeBlock
							/><br />
							<ul>
								<li style={{ "textAlign": "justify" }}><b>Ejemplo de respuesta exitosa:</b></li>
							</ul>
							<CopyBlock
								text={JSON.stringify([
									{
										"txHashConfirmed": true,
										"privado": false,
										"_id": "5e7bfa47591bc1001af7e8e9",
										"fecha_certificacion": "2020-03-26T00:41:43.069Z",
										"nombre": "prueba.pdf",
										"fileHash": "6e7610e40a578cbe3fdf8f1774b5d54e18afb50cf3cda431bacd3157b123ceb5",
										"originalHash": "ff33b356674b61ca0d8780f264d3146f7bcbed35616e512c33a31f2da728a7c5",
										"emisor": "5ceb04712f762724b6d528d6",
										"onModelEmisor": "Usuario",
										"txHash": "0x81a4aa8a0d13b76a6793d32a35e912570bf73485ebb37cdd6e349bb5eaa30ee5",
										"firmasECDSA": []
									},
									{
										"txHashConfirmed": true,
										"privado": false,
										"_id": "5e7bf16dc713c40010cc8f34",
										"fecha_certificacion": "2020-03-26T00:03:57.257Z",
										"firmasECDSA": [
											{
												"_id": "5e7bf16dc713c40010cc8f35",
												"firma": "3044022046dee86dbfb1b8b60095672ea8c40f118848f50cf325b7ee84d19fa4b542b58302203ee23aa1e96342ffc5338af4e1f2622595ca4f6e3c8e94d125740bf2617049d3",
												"llave_publica": "039fb32f306a6bd7a97e5edc870ca7a53b49abde71ed4f0e74a6f6ad1842352111"
											}
										],
										"nombre": "prueba.txt",
										"fileHash": "028b2ea3ff771ea61bcbead7f71241999a78c92438734a331517b0ad020ce3e2",
										"originalHash": "028b2ea3ff771ea61bcbead7f71241999a78c92438734a331517b0ad020ce3e2",
										"extraData": "datos extra",
										"emisor": "5ceb04712f762724b6d528d6",
										"onModelEmisor": "Usuario",
										"txHash": "0x68108e2876b53238c4920e271f5fc050355ae875c98df7206c1f1b6c802ca9d1"
									}
								], null, '	')}
								showLineNumbers={false}
								theme={codepen}
								wrapLines={true}
								codeBlock
							/><br />
							<h3 style={{ "marginTop": "50px" }}>Consultar contenido de un documento</h3>
							<p style={{ "textAlign": "justify" }}>Por seguridad, los documentos almacenados en LifeChain son
								encriptados mientras están en reposo en nuestros servidores. Además, estos se encuentran en
								repositorios propios y privados.</p>
							<p style={{ "textAlign": "justify" }}>Para consultar el contenido de un documento certificado en LifeChain,
								es necesario realizar esta peticion para obtener una URL, la cuál te dará acceso al documento
								por sólo 15 minutos.</p>
							<p style={{ "textAlign": "justify" }}>NOTA: Es necesario tomar en cuenta la privacidad y los permisos que
								tienes sobre el documento, ya que si el documento es privado y es de un usuario u organización
								ageno a ti, tu acceso será denegado.</p>
							<ul>
								<li style={{ "textAlign": "justify" }}><b>Endpoint:</b> https://api.lifechain.mx/documentos/url/*id de
									archivo o folio de certificación*/</li>
								<li style={{ "textAlign": "justify" }}><b>Verbo HTTP:</b> GET</li>
								<li style={{ "textAlign": "justify" }}><b>Ejemplo de petición:</b></li>
							</ul>
							<CopyBlock
								text={"curl 'https://api.lifechain.mx/documentos/url/*id de archivo o folio de certificación*/' \\\n" +
									"-H 'apikey: G8Jx0p0iYS.KpZYXHqChmEG58Lg9KpCD1O9CN0SY7oUrnmHQB5CYmXhbjO0GtirV'"}
								showLineNumbers={false}
								theme={codepen}
								wrapLines={true}
								codeBlock
							/><br />
							<ul>
								<li style={{ "textAlign": "justify" }}><b>Ejemplo de respuesta exitosa:</b></li>
							</ul>
							<CopyBlock
								text={JSON.stringify({
									"url": "https://lifechainprivate..."
								}, null, '	')}
								showLineNumbers={false}
								theme={codepen}
								wrapLines={true}
								codeBlock
							/><br />
							<h3 style={{ "marginTop": "50px" }}>Validar documento</h3>
							<p style={{ "textAlign": "justify" }}><b>Para validar un documento vía API, existen 3 alternativas:</b></p>
							<p style={{ "textAlign": "justify" }}>1 - Validar vía folio de certificación (Sólo disponible para archivos
								PDF)</p>
							<p style={{ "textAlign": "justify" }}>El folio de certificación es una cadena alfanumérica de 24 dígitos
								que se anexa en los documentos certificados por LifeChain, lo puedes encontrar en el pie de cada
								página del documento a validar.</p>
							<ul>
								<li style={{ "textAlign": "justify" }}><b>Endpoint:</b> https://api.lifechain.mx/documentos/*folio de
									certificación*/</li>
								<li style={{ "textAlign": "justify" }}><b>Verbo HTTP:</b> GET</li>
								<li style={{ "textAlign": "justify" }}><b>Ejemplo de petición:</b></li>
							</ul>
							<CopyBlock
								text={"curl 'https://api.lifechain.mx/documentos/5d6871762767ac003320966d' \\\n" +
									"H 'apikey: G8Jx0p0iYS.KpZYXHqChmEG58Lg9KpCD1O9CN0SY7oUrnmHQB5CYmXhbjO0GtirV'"}
								showLineNumbers={false}
								theme={codepen}
								wrapLines={true}
								codeBlock
							/><br />
							<p style={{ "textAlign": "justify" }}>2 - Validar vía hash 256</p>
							<p style={{ "textAlign": "justify" }}>Esta función ayuda a validar el hash sha256 de un archivo. Para
								utilizar esta función, es necesario primero calcular el hash sha256 del documento (puede usar
								cualquier procesador online).</p>
							<ul>
								<li style={{ "textAlign": "justify" }}><b>Endpoint:</b>
									https://api.lifechain.mx/documentos/file-hash/*hash*</li>
								<li style={{ "textAlign": "justify" }}><b>Verbo HTTP:</b> GET</li>
								<li style={{ "textAlign": "justify" }}><b>Ejemplo de petición:</b></li>
							</ul>
							<CopyBlock
								text={"curl 'https://api.lifechain.mx/documentos/file-hash/de7784a10b6ee32fdba72ee5a5a60b671a2b8afd6d6c416ebf387f12a5e686a8' \\\n" +
									"-H 'apikey: G8Jx0p0iYS.KpZYXHqChmEG58Lg9KpCD1O9CN0SY7oUrnmHQB5CYmXhbjO0GtirV'"}
								showLineNumbers={false}
								theme={codepen}
								wrapLines={true}
								codeBlock
							/><br />
							<p style={{ "textAlign": "justify" }}>3 - Validar vía documento (base64)</p>
							<p style={{ "textAlign": "justify" }}>Esta función ayuda a validar un documento por medio de su contenido
								codificado en base 64. La cadena con el contenido codificado debe de agregarse en el cuerpo de
								la petición con el nombre “contenido”.</p>
							<ul>
								<li style={{ "textAlign": "justify" }}><b>Endpoint:</b> https://api.lifechain.mx/documentos/base64/</li>
								<li style={{ "textAlign": "justify" }}><b>Verbo HTTP:</b> POST</li>
								<li style={{ "textAlign": "justify" }}><b>Ejemplo de petición:</b></li>
							</ul>
							<CopyBlock
								text={"curl 'https://api.lifechain.mx/documentos/base64/' \\\n" +
									"-H 'apikey: G8Jx0p0iYS.KpZYXHqChmEG58Lg9KpCD1O9CN0SY7oUrnmHQB5CYmXhbjO0GtirV' \\\n" +
									"-H 'Content-Type: application/json' \\\n" +
									"-d '"+JSON.stringify({ "contenido": "SG9sYSBtdW5kbyEgc29tb3MgTGlmZUNoYWluIQo=" })+"'"}
								showLineNumbers={false}
								theme={codepen}
								wrapLines={true}
								codeBlock
							/><br />
							<pre className="language-bash"><code className="language-bash">
								curl 'https://api.lifechain.mx/documentos/base64/' \
								-H 'apikey: G8Jx0p0iYS.KpZYXHqChmEG58Lg9KpCD1O9CN0SY7oUrnmHQB5CYmXhbjO0GtirV' \
								-H 'Content-Type: application/json' \
								-d '{JSON.stringify({ "contenido": "SG9sYSBtdW5kbyEgc29tb3MgTGlmZUNoYWluIQo=" })}'
							</code></pre>

							<ul>
								<li style={{ "textAlign": "justify" }}><b>Ejemplo de respuesta exitosa:</b></li>
							</ul>
							<CopyBlock
								text={JSON.stringify({
									"txHashConfirmed": true,
									"_id": "5d6f2667e3e98401c24baa59",
									"fecha_certificacion": "2019-09-04T02:50:15.579Z",
									"nombre": "prueba.pdf",
									"fileHash": "b97df9574e60f159064c9b0fcf40565110f0225d4004aedb2b007d5d79d2d5d8",
									"emisor": {
										"_id": "5ceb04712f762724b6d528d6",
										"nombre": "Alejandro"
									},
									"onModelEmisor": "Usuario",
									"createdAt": "2019-09-04T02:50:15.908Z",
									"updatedAt": "2019-09-04T02:50:49.881Z",
									"__v": 0,
									"txHash": "0x3696296895bb660d4fc32ad44876ad158c0d1823fe977b8a17a5f91e0eabac17"
								}, null, '	')}
								showLineNumbers={false}
								theme={codepen}
								wrapLines={true}
								codeBlock
							/><br />

							<h3 style={{ "marginTop": "50px" }}>Validar hash de transacción de blockchain</h3>
							<p style={{ "textAlign": "justify" }}>Esta función no válida el contenido de un documento, si no una firma
								regresada por Blockchain al momento de certificar un archivo. Si la certificación fue emitida
								por medio de LifeChain, regresará el JSON del documento, en caso contrario, el documento no será
								encontrado.</p>
							<ul>
								<li style={{ "textAlign": "justify" }}><b>Endpoint:</b>
									https://api.lifechain.mx/documentos/tx-hash/*hash de transaccion*</li>
								<li style={{ "textAlign": "justify" }}><b>Verbo HTTP:</b> GET</li>
								<li style={{ "textAlign": "justify" }}><b>Ejemplo de petición:</b></li>
							</ul>
							<CopyBlock
								text={"curl 'https://api.lifechain.mx/documentos/tx-hash/0x56af9e0049477ac5e0d6f348edb877164f34b765f5225ac539791a85eb9cd04d' \\\n" +
									"-H 'apikey: G8Jx0p0iYS.KpZYXHqChmEG58Lg9KpCD1O9CN0SY7oUrnmHQB5CYmXhbjO0GtirV' \\\n"}
								showLineNumbers={false}
								theme={codepen}
								wrapLines={true}
								codeBlock
							/><br />

							<h3 style={{ "marginTop": "50px" }}>Otros productos</h3>
							<p style={{ "textAlign": "justify" }}>Es posible que quieras conocer la documentación de APIs de nuestos
								otros productos, te los dejamos por aquí:</p>
							<ul>
								<li style={{ "textAlign": "justify" }}><a href="/docs/">Primeros pasos</a></li>
								<li style={{ "textAlign": "justify" }}><a href="/docs/certificacion-documentos/">Certificación de
									documentos</a></li>
							</ul>
						</div>
					</section>
				</main>
				<FooterLanding/>
			</Fragment>
		</div>
	);
}

export default DocsCertificacionDocumentos;
