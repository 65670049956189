import React, { useEffect, useState } from "react";
import Api from "../util/api";
import Util from "../util/util";
import CtlNotificacion from "./CtlNotificacion";
import useLoading from "../hooks/useLoading";

function AppNotificaciones(props) {
    const { startLoading, stopLoading } = useLoading();
    const [state, setState] = useState({
        notificaciones: null,
        display: "none",
    });

    useEffect(() => {
        const consultarNotificaciones = async () => {
            try {
                startLoading();
                var res = await Api.notificaciones.get();
                await Util.requestSuccess(res);
                var notificaciones = (await res.json()).notificaciones;
                console.log(notificaciones);
                setState({
                    notificaciones
                });
                stopLoading();
            } catch (e) {
                console.log(e);
                stopLoading();
                console.log("Problemas para consultar notificaciones");
                
            }
            console.log(state);

        }
        consultarNotificaciones();
    }, []);

    function showUserMenu() {
        setState(prevState => ({
            ...prevState,
            display: "block",
        }));
    }
    function hideUserMenu() {
        setState(prevState => ({
            ...prevState,
            display: "none",
        }));
    }
    return (
        <li
            className="nav-item notifications dropdown"
            onMouseEnter={showUserMenu}
            onMouseLeave={hideUserMenu}
            data-rel="notifications"
            id="cmdNotificacionesContainer"
        >
            <a
                href="# "
                className="nav-link dropdown-toggle pl-4"
                data-toggle="dropdown"
            >
                <i className="pe pe-7s-bell"></i>
                <span
                    className="badge badge-pill badge-info"
                    id="numeroNotificaciones"
                >
                    {(state.notificaciones && state.notificaciones.length) || 0}
                </span>
            </a>
            <div
                className="dropdown-menu dropdown-menu-right"
                id="notificacionesContainer"
                style={{ maxHeight: "400px", overflow: "auto", display: state.display }}
            >

                <div className="list list-unstyled list-hover list-bordered dropdown-body load-content">
                    <div className="loader-container">
                        <div className="loader-8">Cargando...</div>
                    </div>
                </div>
                {state.notificaciones &&
                    state.notificaciones.map &&
                    state.notificaciones.map((notificacion) => (
                        <CtlNotificacion
                            key={notificacion._id}
                            notificacion={notificacion}
                        />
                    ))}

            </div>
        </li>
    );
}

export default AppNotificaciones;
