import React, { useState, useEffect } from "react";
import Api from "../util/api";
import Util from "../util/util";
import swal from 'sweetalert2';
import useLoading from "../hooks/useLoading";

function CambiarPass(props) {
    const { startLoading, stopLoading } = useLoading();
    const [state, setState] = useState({
        validMinuscula: "invalid",
        validMayuscula: "invalid",
        validLongitud: "invalid",
        validNumero: "invalid",
        pass: "",
        passConfirm: "",
        tokenId: null
    });

    useEffect(() => {
        async function validarToken() {
            var tokenId = window.location.pathname.replace(
                "/usuarios/olvide-contrasena/",
                ""
            );
            if (!tokenId || tokenId.length !== 64) {
                await swal.fire("Ups!", "No cuentas con un token válido para cambiar tu contraseña", "error");
                window.location.href = "/";
                return;
            }
            setState((prevState) => ({
                ...prevState,
                tokenId
            }));
        }

        validarToken();
    }, []);

    function validarPass(pass) {
        var validMinuscula = "invalid";
        var validMayuscula = "invalid";
        var validLongitud = "invalid";
        var validNumero = "invalid";
        var passValido = true;
        var lowerCaseLetters = /[a-z]/g;
        //Validar minúsculas
        if (pass.match(lowerCaseLetters))
            validMinuscula = "valid";
        else
            passValido = false;

        //Validar mayúsculas
        var upperCaseLetters = /[A-Z]/g;
        if (pass.match(upperCaseLetters))
            validMayuscula = "valid";
        else
            passValido = false;

        // Validar números
        var numbers = /\d/g;
        if (pass.match(numbers))
            validNumero = "valid";
        else
            passValido = false;

        // Validate length
        if (pass.length >= 8)
            validLongitud = "valid";
        else
            passValido = false;
        setState((prevState) => ({
            ...prevState,
            validMinuscula,
            validMayuscula,
            validNumero,
            validLongitud,
            passValido
        }));
    }

    const handleChange = (e) => {
        const { id, value } = e.target;
        setState((prevState) => ({
            ...prevState,
            [id]: value,
        }));

        if (id === "pass") validarPass(value);
    };


    async function saveClick() {
        try {
            if (!state.passValido) {
                await swal.fire("Ups!", "Tu contraseña debe cumplir con todos los niveles de seguridad", "error");
                return;
            }
            if (state.pass !== state.passConfirm) {
                await swal.fire("Ups!", "Las contraseñas con coinciden", "error");
                return;
            }
            startLoading();

            var res = await Api.usuarios.CambiarPass(state.tokenId, { pass: state.pass });
            console.log(res);
            if (!await Util.requestSuccess(res)) {
                stopLoading();
                await swal.fire("Ups!", "Error al actualizar contraseña", "error");
                return;
            }
            stopLoading();
            await swal.fire({ icon: 'success', html: "Tu contraseña fué actualizada" });
            window.location.href = "/login";
        } catch (e) {
            console.log(e);
            stopLoading();
            await swal.fire("Ups!", "Error al actualizar contraseña", "error");
            return;
        }
    }


    return (
        <div className="container">
            <div className="row">
                <div className="col-md-4 col-xs-1">

                </div>
                <div className="col-md-4 col-xs-10" style={{ textAlign: "center" }}>
                    <img src="/images/logo_lifechain_negro.png" alt="LifeChain" style={{ width: "50%", margin: "30px" }} />
                    <div className="form" id="formPass">
                        <form className="login-form" autoComplete="off">
                            <p id="lblMsgSaludo">¡Hola! para dar seguimiento a tu donación, ingresa una contraseña</p>
                            <p id="activation_msg"></p>
                            <input type="password" placeholder="Contraseña" id="pass" onChange={handleChange} />
                            <input type="password" placeholder="Confirmar contraseña" id="passConfirm" onChange={handleChange} />
                            <div id="message">
                                <br />
                                <p>Tu contraseña debe contener:</p>
                                <p id="letter" className={state.validMinuscula}>Una letra <b>minúscula</b></p>
                                <p id="capital" className={state.validMayuscula}>Una letra <b>mayúscula</b></p>
                                <p id="number" className={state.validNumero}>Un <b>número</b></p>
                                <p id="length" className={state.validLongitud}>Minimo <b>8 caracteres</b></p>
                            </div>
                            <button onClick={saveClick}>Continuar</button>
                            <p id="msgReg" style={{ color: "red" }}></p>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CambiarPass;

