import React, { useState, useEffect, Fragment } from "react";
import Navigation from "../../components/Navigation";
import BlogFooter from "../../components/BlogFooter";
import ModalNuevoProspecto from "../../components/modal/NuevoProspecto";
import sha256 from "sha256";

function BlogProtegerCertificadosVacunacion() {

	const [state, setState] = useState({
		demoHash: "0x..."
	});

	useEffect(() => {
		document.title = "LifeChain - Blog";
	});

	function generateDemoHash(e) {
		const { value } = e.target;
		var demoHash = sha256(value);
		console.log(demoHash);
		setState({
			demoHash
		});
	}

	return (
		<div className="BlogProtegerCertificadosVacunacion">
			<Fragment>
				<Navigation />
				<main>
					<header className="section color-1 fullscreen blog-single-header image-background"
						style={{ "backgroundImage": "url('/images/blog/1.svg')", "backgroundColor": "#464685" }}>
						<div className="container text-center">
							<div className="row">
								<div className="col-md-10 mx-md-auto">
									<h1 className="post-title display-4">Como proteger los certificados de vacunación de COVID-19</h1>
								</div>
							</div>
							<a href="#blog-post" className="scrollto mt-auto">
								<div className="arrow-down">
									<i className="pe pe-7s-angle-down pe-4x color-5"></i>
								</div>
							</a>
						</div>
					</header>
					<section className="blog-post" id="blog-post">
						<div className="container">
							<div className="row">
								<div className="col-md-8 mx-auto">
									<div className="media">
										<img className="mr-3 rounded-circle icon-md"
											src="/images/landing/avatar/team/goretti-profile.jpg" alt="" />
										<div className="media-body small">
											<span className="author">por
												<a href=" #"> Goretti Oronia</a>
											</span>
											<span className="d-block color-2 alpha-6">28 de Enero, 2021</span>
										</div>
									</div>
									<hr className="mb-5" />
									<p className="lead color-2 italic mb-5">Gracias a la emergencia sanitaria del COVID-19 y las
										primeras etapas de vacunación, es necesario considerar que serán expedidos certificados
										o comprobantes de vacunación, los cuales entran en la lista de documentos oficiales que
										necesitan usar lo último en tecnología para protegerlos de falsificaciones.</p>
									<p>Actualmente, una incontable cantidad de documentos oficiales son expedidos por diferentes
										organizaciones. Sin embargo existe una gran falta de mecanismos de validación de
										integridad para muchos de esos documentos, siendo fácilmente falsificados, vulnerados o
										modificados ilícitamente.</p>
									<p>Solo en México, en los últimos cuatro años, la Procuraduría General de la República, ha
										iniciado más de 3 mil carpetas de investigación por el delito de falsificación de
										documentos, pero solo ha podido detener a 749 personas por dicho delito.</p>
									<p>Ante esta gran problemática, es necesario implementar sistemas de registros digitales,
										seguros y transparentes que no comprometan la confidencialidad de la información de las
										personas, y que cualquier ciudadano pueda demostrar en cualquier momento la veracidad de
										sus archivos, como su certificado de vacunación contra el COVID-19, o su historial
										médico, en aduanas o ingresos a eventos.</p>
									<p>
										Una de las mejores soluciones para proteger estos archivos digitales, está en la
										poderosa combinación de tecnologías, como Blockchain con las pruebas de existencia,
										criptografía y sellos de tiempo.</p>

									<h4 className="bold">¿Qué es una prueba de existencia?</h4>
									<p>
										Es un proceso que da como resultado una constancia descentralizada de la existencia de
										un archivo en un momento en el tiempo, mediante la generación de un resumen
										criptográfico o huella digital, y la escritura del mismo en algún medio inmutable. Esta
										escritura debe contener un timestamp o sello en el tiempo. El cual hace referencia al
										momento específico en que se llevó a cabo la acción. <b className="bold">La seguridad e
											inmutabilidad necesaria para realizar este proceso la podemos obtener de
											Blockchain.</b>
									</p>
									<h4 className="bold">¿Cómo puedo generar el resumen criptográfico o huella digital de un
										archivo?</h4>
									<p>Existen diversos algoritmos o funciones SHA (Secure Hash Algorithm, Algoritmo de Hash
										Seguro), que nos ayudan a generar una cadena de longitud fija única a partir de una
										entrada, la cual puede ser un texto, o un archivo en cualquier formato digital.</p>
									<p>Una de estas funciones hash es la llamada SHA 256, la cual es un pilar importante para
										diversas Blockchain, ya que mantiene un buen equilibrio entre seguridad y coste
										computacional.</p>
									<p>Este resumen criptográfico nos sirve para dos cosas:</p>
									<ol>
										<li><b className="bold">Validar rápidamente cambios o modificaciones en los datos de
											entrada. </b>Esta familia
											de funciones es determinista, lo que nos asegura que ante la misma entrada, siempre
											obtendremos la misma salida. Pero si el archivo de entrada sufre algún cambio en su
											contenido por mínimo que sea, el resumen criptográfico obtenido será completamente
											diferente al anterior.</li>
										<li><b className="bold">Hacer pública una prueba de existencia sin revelar el contenido del
											archivo.</b> Es
											importante saber que a partir de un archivo podemos generar un resumen
											criptográfico, pero es imposible hacer el proceso inverso. Lo que nos garantiza que
											podemos publicar nuestro resumen sin miedo a que la confidencialidad de nuestra
											información sea vulnerada.</li>
									</ol>
									<p>A continuación te mostramos un <b className="bold">demo práctico</b> de
										lo que una función SHA 256 puede hacer. Puedes modificar el mensaje de la caja de texto,
										y notar que con mover un solo carácter en el mensaje, el resumen criptográfico es
										completamente diferente.
									</p>
									<br />
									<br />
									<textarea name="textarea" rows="5" cols="30" id="hashInput"
										onKeyUp="$('#lblHashResult').html(sha256($('#hashInput').val()));" onKeyUp={generateDemoHash}>
										Esto es un demo de lo que una función SHA-256 puede hacer
									</textarea>
									<p><b className="bold">Resumen criptográfico generado: </b></p>
									<p id="lblHashResult">{state.demoHash || "0x..."}</p>
									<br />
									<br />
									<p>Así es como en LifeChain aseguramos que ningún archivo pueda ser modificado, ya que
										aplicamos esta prueba matemática de criptografía y lo comparamos con el archivo
										original, aunque solo haya cambiado una sola letra en el documento, nos daremos cuenta
										que ya no es el mismo.
									</p>
									<p>Nuestros expertos en blockchain, criptografía y seguridad desarrollaron un mecanismo que
										permite generar archivos infalsificables y crear pruebas de existencia de documentos,
										videos, fotografías o cualquier archivo digital que se quiera proteger, así como validar
										su autenticidad, consultar el archivo original (si tienes permisos), demostrar la
										existencia en un momento específico, y asegurar que no ha sido modificado el contenido
										en el mismo.
									</p>
									<p>Si te gustaría tener la seguridad y tranquilidad de que no modificarán tus archivos,
										contáctanos para mostrarte el sistema.</p>
								</div>
							</div>
						</div>
					</section>
					<section>
						<div className="container py-5 b-t b-2x">
							<div className="media align-items-center">
								<img className="d-flex mr-3 rounded-circle shadow" style={{ "width": "100px", "height": "100px" }}
									src="/images/landing/avatar/team/goretti-profile.jpg" alt="..." />
								<div className="media-body">
									<h5 className="my-0 bold">Goretti Oronia</h5>
									<span className="color-2">Lifechain</span>
									<hr />
									<p className="small color-2 mb-0">Lic. en Mercadotecnia con especialidad en investigación de
										mercados, por la Universidad de Guadalajara, traductora y evangelista de blockchain.</p>
								</div>
							</div>
						</div>
					</section>
					<section>
						<div className="container py-5 b-t">
							<ul className="list-unstyled d-flex flex-wrap flex-row align-items-center">
								<li className="mr-4">
									<i className="fas fa-tag color-2"></i>
								</li>
								<li>
									<a href=" #" className="badge badge-pill badge-outline-2 mr-2">blockchain</a>
								</li>
								<li>
									<a href=" #" className="badge badge-pill badge-outline-2 mr-2">emprendimiento social</a>
								</li>
								<li>
									<a href=" #" className="badge badge-pill badge-outline-2 mr-2">covid-19</a>
								</li>
								<li>
									<a href=" #" className="badge badge-pill badge-outline-2 mr-2">vacunación</a>
								</li>
								<li>
									<a href=" #" className="badge badge-pill badge-outline-2 mr-2">organizaciones</a>
								</li>
								<li>
									<a href=" #" className="badge badge-pill badge-outline-2 mr-2">innovacion</a>
								</li>
								<li>
									<a href=" #" className="badge badge-pill badge-outline-2 mr-2">pruebas de existencia</a>
								</li>
								<li>
									<a href=" #" className="badge badge-pill badge-outline-2 mr-2">seguimiento</a>
								</li>
								<li>
									<a href=" #" className="badge badge-pill badge-outline-2 mr-2">transparencia</a>
								</li>
							</ul>
							<ul className="list-unstyled d-flex flex-wrap flex-row align-items-center">
								<li className="mr-4">
									<i className="fas fa-bookmark color-2"></i>
								</li>
								<li>
									<a href=" #" className="btn btn-circle btn-sm brand-twitter mr-3">
										<i className="fab fa-twitter"></i>
									</a>
								</li>
								<li>
									<a href=" #" className="btn btn-circle btn-sm brand-facebook mr-3">
										<i className="fab fa-facebook"></i>
									</a>
								</li>
								<li>
									<a href=" #" className="btn btn-circle btn-sm brand-google mr-3">
										<i className="fab fa-google-plus"></i>
									</a>
								</li>
							</ul>
						</div>
					</section>
				</main>
				<BlogFooter />
			</Fragment>
		</div>
	);
}

export default BlogProtegerCertificadosVacunacion;