import React, { useEffect, useState } from "react";
import Api from "../../util/api";
import swal from 'sweetalert2';
import Util from "../../util/util";
import useLoading from "../../hooks/useLoading";

function Login() {
    const {startLoading, stopLoading } = useLoading();
    const [state, setState] = useState({
		email: ""
	});
    useEffect(() => {
        document.title = "LifeChain - Olvide contraseña";
    });

    const handleChange = (e) => {
		const { id, value } = e.target;
		setState((prevState) => ({
			...prevState,
			[id]: value,
		}));
	};

    async function sendEmail(){
        try{
            if(state.email.length===0){
                await swal.fire("¡Ups!", "Es necesario que ingreses tu email", "error");
				return;
            }
            startLoading();
            var res = await Api.usuarios.forgotPass({email:state.email});
            stopLoading();
            if (!Util.requestSuccess(res)) {
				await swal.fire("Ups!", "Error al tratar de reestablecer tu contraseña", "error");
				return;
			}
            await swal.fire({
				icon: 'success',
				html: 'Se envió un correo electrónico para restablecer tu contraseña'
			});
        }catch(e){
            console.log(e);
            stopLoading();
            await swal.fire("Ups!", "Error al tratar de reestablecer tu contraseña", "error");
        }
    }

    return (
        <div className="container-fluid">
            <div className="row align-items-center">
                <div className="col-md-6 col-lg-7 fullscreen-md d-flex justify-content-center align-items-center overlay gradient gradient-53 alpha-7 image-background cover"
                    style={{backgroundImage:"url(https://picsum.photos/1920/1200/?random&gravity=west)"}}>
                    <div className="content">
                        <h2 className="display-4 display-md-3 color-1 mt-4 mt-md-0">Olvidaste
                            <span className="bold d-block">tu contraseña?</span>
                        </h2>
                        <p className="lead color-1 alpha-5">Ntp, a todos nos puede pasar</p>
                        <hr className="mt-md-6 w-25" />
                        {/*<!--<div class ="d-flex flex-column">
                        <p class ="small bold color-1">O ingresa con</p>
                        <nav class ="nav mb-4">
                        <a class ="btn btn-rounded btn-outline-2 brand-facebook mr-2" href="#">
                        <i class ="fab fa-facebook-f"></i>
                        </a>
                        <a class ="btn btn-rounded btn-outline-2 brand-twitter mr-2" href="#">
                        <i class ="fab fa-twitter"></i>
                        </a>
                        <a class ="btn btn-rounded btn-outline-2 brand-linkedin" href="#">
                        <i class ="fab fa-linkedin-in"></i>
                        </a>
                        </nav>
    </div>-->*/}
                    </div>
                </div>
                <div className="col-md-5 col-lg-4 mx-auto">
                    <div className="forgot-form mt-5 mt-md-0">
                        <img src="/images/logo_lifechain_negro.png" alt="LifeChain" style={{ width: "30%" }} />
                        <h1 className="color-5 bold">Olvidaste tu contraseña?</h1>
                        <p className="color-2 mt-0 mb-4 mb-md-6">Ingresa tu correo electrónico a continuación para recuperar
                            tu cuenta o
                            <a href="/login" className="accent bold">{" "}Inicia sesión</a>
                        </p>
                        <form className="cozy" data-validate-on="submit" noValidate>
                            <div className="form-group has-icon">
                                <input type="text" id="email" className="form-control form-control-rounded"
                                    placeholder="Ingresa aquí tu correo electrónico" onChange={handleChange}/>
                                <i className="icon fas fa-envelope"></i>
                            </div>
                            <div className="form-group d-flex align-items-center justify-content-between">
                                <button onClick={sendEmail} className="btn btn-accent btn-rounded ml-auto">Recuperar mi contraseña
                                    <i className="fas fa-long-arrow-alt-right ml-2"></i>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;
