import swal from 'sweetalert2';
import React, { useEffect, useState } from "react";
import Api from "../util/api";
import Util from "../util/util";
import useLoading from '../hooks/useLoading';
import useUser from '../hooks/useUser';
function Perfil(props) {
  const { startLoading, stopLoading } = useLoading();
  const { user } = useUser();
  const [state, setState] = useState({
    user: null,
  });
  useEffect(() => {
    async function fetchData() {
      if (!Util.validarJWT()) {
        await swal.fire("¡Ups!", "Es necesario iniciar sesión", "error");
        window.location.href = "/login";
        return;
      }
      if (user?._id === "") return;
      try {
        startLoading();
        var res = await Api.usuarios.get(user?._id);
        var user = (await res.json()).usuario;
        setState({
          user,
        });
        await Util.requestSuccess(res, true);
        console.log(res);
        stopLoading();
      } catch (e) {
        console.log(e);
        stopLoading();
        await swal.fire("¡Ups!", "Error al consultar información de usuario", "error");
      }
    }
    //fetchData();
  }, [user]);
  return (
    <main className="overflow-hidden bg-6" style={{ minHeight: "92vh" }}>
      <header className="page header">
        <div className="content">
          <div className="row">
            <div className="col-md-9 col-xs-9">
              <h1 className="display-4 mb-0 nombreUsuario">
                {(user &&
                  user?.nombre + " " + user?.apellidos) ||
                  "Perfil de usuario"}
              </h1>
            </div>
            <div className="col-md-3 col-xs-3">
              <nav className="navigation" style={{ backgroundColor: "rgba(0,0,0,0)" }}>
                <a
                  className="btn btn-rounded btn-solid mr-3 px-3 mt-2 editarPerfilLink"
                  href={"/app/mi-perfil/editar/" + user?._id}
                >
                  <i className="fas fa-sign-in-alt d-none d-md-inline mr-md-0 mr-lg-2"></i>
                  <span className="d-md-none d-lg-inline">Editar perfil</span>
                </a>
              </nav>
            </div>
          </div>
        </div>
      </header>
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-md-3 col-xs-12" style={{ padding: "10px" }}>
              <div clas="row">
                <div className="col-md-0 col-xs-2"></div>
                <div className="card col-md-12 col-xs-8">
                  <img
                    alt={
                      (user &&
                        user?.nombre + " " + user?.apellidos) ||
                      "Perfil de usuario"
                    }
                    id="fotoUsuario"
                    src={(user && user?.foto) || "/admin/user.png"}
                    style={{ width: "100%", height: "100%" }}
                  />
                  <div>
                    <b>
                      <h4 className="nombreUsuario">
                        {(user &&
                          user?.nombre + " " + user?.apellidos) ||
                          "Perfil de usuario"}
                      </h4>
                    </b>
                  </div>
                </div>
                <div className="col-md-0 col-xs-2"></div>
              </div>
            </div>
            <div className="col-md-9" style={{ padding: "30px 80px" }}>
              <h4>Correo electrónico:</h4>
              <p className="emailUsuario">
                {(user && user.email) || "No se encontró email"}
              </p>
              <div id="semblanzaContainer">
                <h4>Semblanza del usuario:</h4>
                <p id="semblanzaUsuario">
                  {(user && user.semblanza) ||
                    "No se encontró semblanza"}
                </p>
              </div>
            </div>
          </div>
          <div style={{ marginTop: "30px", display: "none" }}>
            <h3 className="title">Nivel de altruismo:</h3>
            <div className="row">
              <div className="col-md-3"></div>
              <div className="col-md-6"></div>
            </div>
          </div>
          <div className="row gap-y">
            <div className="col-6">
              <a
                className="btn btn-5 d-flex flex-column align-items-center py-4"
                id="hrefDonaciones"
                href="/app/mis-donaciones/"
              >
                <p className="mb-0">Donaciones realizadas</p>
              </a>
            </div>
            <div className="col-6">
              <a
                className="btn btn-3 d-flex flex-column align-items-center py-4 cmdAdmin"
                id="hrefDocumentos"
                href="/app/documentos/"
              >
                <p className="mb-0">Documentos certificados</p>
              </a>
            </div>
          </div>
          <div className="row"></div>
        </div>
      </div>
    </main>
  );
}

export default Perfil;
