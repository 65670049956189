import React, { useEffect, useState } from "react";
import swal from 'sweetalert2';
import Api from "../util/api";
import Util from "../util/util";
import useLoading from "../hooks/useLoading";
import useUser from "../hooks/useUser";

function PerfilEditar(props) {
  const { startLoading, stopLoading } = useLoading();
  const { user } = useUser();
  const [state, setState] = useState({
    usuario: {
      nombre: "",
      apellidos: "",
      telefono: "",
      semblanza: "",
    },
    id_usuario_editar: "",
    showModalNuevaTarjeta: false,
    planta_produccion_id: "",
    loading: true,
    admin: false,
    tipoOrganizacion: null,
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      usuario: {
        ...prevState.usuario,
        [id]: value,
      },
    }));
  };

  useEffect(() => {
    async function consultarUsuario() {
      try {
        if (window.location.href.search("editar") > 0) {
          startLoading();
          var id_usuario_editar = window.location.href.split("editar/")[1];
          var res = await Api.usuarios.get(id_usuario_editar);
          var data = await res.json();
          if (await Util.requestSuccess(res)) {
            console.log(data);
            var usuario = data.usuario;
            setState(prevState => ({
              ...prevState,
              id_usuario_editar,
              usuario: {
                nombre: usuario.nombre,
                apellidos: usuario.apellidos,
                telefono: usuario.telefono,
                semblanza: usuario.semblanza,
                foto: usuario.foto
              }
            }));
          } else {
            console.log(data.msg);
            stopLoading();
            await swal.fire("Ups!", "¡Ocurrió un error al consultar usuario!", "error");
          }
        }
      } catch (e) {
        stopLoading();
        await swal.fire("Ups!", "¡Ocurrió un error al consultar usuario!", "error");
      }

      stopLoading();
    }

    async function validarPermisoAccesoUsuario() {
      /*if (!user?.tipo_usuario) return;
      if (user?.tipo_usuario === "Maestro") {
        await swal.fire("Ups!", "¡No tienes permisos para entrar aquí!", "error");
        window.location.href = "/app/home";
        return;
      }*/
      consultarUsuario();
    }
    validarPermisoAccesoUsuario();


  }, [user?.tipo_usuario]);

  const saveClick = async (e) => {
    e.preventDefault();
    try {
      var res;
      if (state.id_usuario_editar) {
        console.log(state.usuario);
        startLoading();
        res = await Api.usuarios.actualizar(state.usuario, state.id_usuario_editar);
      }

      var data = await res.json();
      if (!await Util.requestSuccess(res)) {
        stopLoading();
        await swal.fire("¡Ups!", data.msg, "error");
        return;
      }
      localStorage.setItem("user", JSON.stringify(data.usuario));

      window.location.href = "/app/mi-perfil";
    } catch (e) {
      console.log(e);
      stopLoading();
      await swal.fire("¡Ups!", "Error al guardar usuario", "error");
    }
  };

  const updateApiKeyClick = async (e) => {
    if(e) e.preventDefault();
    try {
      var confirm = await swal.fire({
        title: '¿Estás seguro que deseas actualizar tu api key?',
        text: "Si lo haces, borraras tu api key anterior",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Continuar',
        cancelButtonText: 'Cancelar'
      });
      if(!confirm.isConfirmed) return;
      startLoading();
      var res;
      if (state.id_usuario_editar) {
        console.log(state.usuario);
        res = await Api.usuarios.actualizarApiKey(state.id_usuario_editar);
        
        var data = await res.json();
        stopLoading();
        if (!await Util.requestSuccess(res)) {
          await swal.fire("¡Ups!", data.msg, "error");
          return;
        }
        
        var api_key = data.api_key;
        await swal.fire("¡Listo!", 'Tu nuevo api_key es "' + api_key + '". Es importante que no lo compartas. En caso de perdida o robo, puedes actualizarlo las veces que quieras.', "success");
      }

    } catch (e) {
      console.log(e);
      stopLoading();
      await swal.fire("¡Ups!", "Error al guardar usuario", "error");
    }
    stopLoading();
  };

  return (
    <div className="Organizacion" style={{ width: "100%", minHeight: "92vh" }}>
      <main className="overflow-hidden bg-6">
        <header className="page header">
          <div className="content">
            <div className="row">
              <div className="col-md-9">
                <h1 className="title nombreUsuario">Editar perfil</h1>
              </div>
            </div>
          </div>
        </header>
        <div className="content">
          <div className="container">
            <form className="form" id="register-form" method="post" action="/causas/">
              <div className="row">
                <div className="col-md-4">
                  <div>
                    <img id="fotoUsuario" src={state.usuario.foto || ""} alt="Foto de perfil" style={{ width: "100%", height: "100%" }} />
                    <div className="row" style={{ marginTop: "15px", marginBottom: "15px" }}>
                      <div className="col-md-12 col-xs-12" id="cambiarFotoContainer">
                        <button id="cmdCambiarFoto">Subir foto</button>
                      </div>
                      <div className="col-md-6 col-xs-6" style={{ display: "none" }} id="cancelarFotoContainer">
                        <button id="cmdCancelarFoto">Cancelar</button>
                      </div>
                    </div>

                    <input type="file" id="fotoFile" placeholder="Nombre" title="Foto de perfil" name="avatar"
                      accept="image/png, image/jpeg" style={{ display: "none" }} />

                    {/*<p id="nombreArchivoValidarRfc">Te recomendamos validar tu RFC subiendo tu constancia o cédula
                      fiscal que contenga un código QR. La puedes descargar <a
                        href="https://www.sat.gob.mx/aplicacion/53027/genera-tu-constancia-de-situacion-fiscal."
                        target="_blank" rel="noreferrer"> aquí</a>. Así podrás consultar todas las transacciones asociadas a tu RFC.
                    </p>
                    <div className="row" id="validarRfcGeneralContainer" style={{ marginTop: "15px", marginBottom: "15px" }}>
                      <div className="col-md-12 col-xs-12" id="validarRfcContainer">
                        <button id="cmdValidarRfc">Validar RFC</button>
                      </div>
                      <div className="col-md-6 col-xs-6" style={{ display: "none" }} id="cancelarValidarRfcContainer">
                        <button id="cmdCancelarValidarRfc">Cancelar</button>
                      </div>
  </div>

                    <input type="file" id="validarRfcFile" placeholder="Nombre" name="avatar" accept="application/pdf"
  style={{ display: "none" }} />*/}

                  </div>
                </div>
                <div className="col-md-8">
                  <input type="text" placeholder="Nombre" name="nombre" id="nombre" onChange={handleChange} value={state.usuario.nombre} />
                  <input type="text" placeholder="Apellidos" name="apellidos" id="apellidos" onChange={handleChange} value={state.usuario.apellidos} />
                  <input type="text" placeholder="Telefono" name="telefono" id="telefono" onChange={handleChange} value={state.usuario.telefono} />
                  {/*<input type="text" placeholder="Rfc" name="rfc" id="rfc" onChange={handleChange} value={state.usuario.rfc}/>*/}
                  <textarea placeholder="Semblanza" name="semblanza" id="semblanza" rows="5" onChange={handleChange} value={state.usuario.semblanza}></textarea>
                </div>
              </div>
              <p id="msgReg" style={{ color: "red" }}></p>
              <div className="row">
                <div className="col-md-4">
                  <button id="cmdCancelar" onClick={() => window.location.href = "/app/mi-perfil"}>Cancelar</button>
                </div>
                <div className="col-md-4">
                  <button id="cmdOk" onClick={saveClick}>Guardar</button>
                </div>
                <div className="col-md-4">
                  <button id="cmdNuevoApiKey" onClick={updateApiKeyClick}>Generar/actualizar Api Key<br />(Sólo desarrolladores)</button>
                </div>
              </div>

            </form>
          </div>
        </div>
      </main>
    </div>
  );
}

export default PerfilEditar;
