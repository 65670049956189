import React, { useEffect, useState, Fragment } from "react";
import Navigation from "../../components/Navigation";
import BlogFooter from "../../components/BlogFooter";
import ModalNuevoProspecto from "../../components/modal/NuevoProspecto";

function BlogCadenaSuministro() {
    const [state, setState] = useState({
        admin: false,
        user: { saldo_certificar_docs: 0 },
        loading: false,
        error: null,
        showModaNuevoProspecto: false,
      });

      function openModalNuevoProspecto() {
        setState({
          ...state,
          showModaNuevoProspecto: true,
        });
      }

      function closeModals() {
        setState({
          ...state,
          showModaNuevoProspecto: false,
        });
      }
  useEffect(() => {
    document.title = "LifeChain - Blog";
  });

  return (
    <div className="BlogCadenaSuministro">
      <Fragment>
        <Navigation />
        <main>
            <header className="section color-1 fullscreen blog-single-header image-background"
                style={{"backgroundImage": "url('/images/blog/1.svg')", "backgroundColor": "#464685"}}>
                <div className="container text-center">
                    <div className="row">
                        <div className="col-md-10 mx-md-auto">
                            <h1 className="post-title display-4">
                                Blockchain: revolucionando la cadena de suministro
                            </h1>
                        </div>
                    </div>
                    <a href="#blog-post" className="scrollto mt-auto">
                        <div className="arrow-down">
                            <i className="pe pe-7s-angle-down pe-4x color-5"></i>
                        </div>
                    </a>
                </div>
            </header>
            <section className="blog-post" id="blog-post">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 mx-auto">
                            <div className="media">
                                <img className="mr-3 rounded-circle icon-md"
                                    src="/images/landing/avatar/team/goretti-profile.jpg" alt="Goretti Oronia Tamayo"/>
                                <div className="media-body small">
                                    <span className="author">por
                                        <a href=" #"> Goretti Oronia Tamayo</a>
                                    </span>
                                    <span className="d-block color-2 alpha-6">20 de Agosto, 2020</span>
                                </div>
                            </div>
                            <hr className="mb-5"/>
                            <p className="lead color-2 italic mb-5">¿Conoces de una manera confiable todo el proceso por el
                                que pasó un producto? ¿De dónde provino una pieza defectuosa o quién fue el responsable?
                                ¿Cómo aseguras la calidad del manejo y procesamiento de los productos?</p>
                            <p>Es abrumador todo lo que se tiene que considerar y cuidar dentro de la cadena de
                                abastecimiento, pero gracias a la tecnología blockchain puedes hacer esto sin parar tu
                                producción. Pero, ¿qué es exactamente el blockchain y cuáles son algunas de las
                                soluciones que ofrece a los problemas de la cadena de suministro?</p>

                            <img className="img-responsive rounded" src="/images/blog/suministro/blockchain.jpg" alt=""/>

                            <h4 className="bold"> Redes de confianza
                            </h4>
                            <p>Blockchain es una red de miles de personas que se apoyan para proteger la información de
                                manera confiable. En palabras más técnicas, una blockchain es un libro digital
                                descentralizado con copias en varias computadoras ubicadas por el mundo (también
                                llamados nodos), donde se valida y resguarda la información que se escribe en tiempo
                                real.
                            </p>
                            <h4 className="bold"> ¿Cuáles son las principales características de blockchain?
                            </h4>
                            <ul>
                                <li className="m-1"><b className="bold">Transparente y consenso.</b> Todos los participantes de
                                    la red pueden ver la información y
                                    también los cambios, los cuales deben ser aprobados por la mayoría para poder ser
                                    registrados.
                                </li>
                                <li className="m-1"><b className="bold">Inalterable y seguro.</b> La información que se escribe
                                    dentro de la red de blockchain no se
                                    puede modificar o alterar.
                                </li>

                                <li className="m-1"><b className="bold">Descentralizado y confiable.</b> Al estar guardado en
                                    una
                                    red de computadoras, está disponible para validaciones en cualquier momento,
                                    pues nadie tiene el control de la información.</li>
                            </ul>

                            <h4 className="bold">¿Qué información conviene registrar de la supply chain? </h4>

                            <ul>
                                <li className="m-1"><b className="bold">Quién.</b> Registrar quién
                                    realizó el proceso de transformación o transporte, y la persona encargada del
                                    producto, en cualquier punto de la cadena.
                                </li>
                                <li className="m-1"><b className="bold">Qué.</b> Registrar qué productos o
                                    materiales se han recibido, qué proceso se realizó con ellos, el estado en el que se
                                    encuentran, temperatura, humedad, etcétera.
                                </li>
                                <li className="m-1"><b className="bold">Cuándo.</b> Registrar la
                                    fecha en la que se recibieron o se procesaron los productos. Ésta se registra
                                    automáticamente y no se puede modificar.</li>
                                <li className="m-1"><b className="bold">Identificación.</b> Registrar el número de lote, fecha
                                    de caducidad o consumo preferente, información que permita distinguir y seguir el
                                    proceso de elaboración.</li>
                                <li className="m-1"><b className="bold">Información adicional.</b> Registrar
                                    certificaciones, licencias, permisos y toda la información que creas relevante de
                                    tus productos y procesos.</li>
                            </ul>
                            <h4 className="bold">La importancia de la trazabilidad con blockchain</h4>
                            <p>En 2006 se registró en Estados Unidos un brote de E. coli O157:H7 causado por espinacas,
                                que dejó un saldo de 205 personas afectadas, de las cuales 104 fueron hospitalizadas, 31
                                sufrieron insuficiencia renal y, lamentablemente, tres fallecieron.</p>
                            <p>Como era de esperarse la comercialización de espinaca se detuvo completamente. La Food
                                and Drug Administration (FDA) de Estados Unidos tardó dos semanas en encontrar la
                                fuente, y durante ese tiempo, toda la industria se mantuvo detenida, con el impacto que
                                esto representa.</p>
                            <p>La forma más eficaz de prevenir este tipo de incidentes es tener el registro de todo el
                                proceso de elaboración de un producto, pasando por todos los pasos hasta que llega al
                                consumidor. En resumen, tener la trazabilidad. De esta forma, no solo se podría haber
                                prevenido lo que pasó con las espinacas, también se hubiera:</p>
                            <ul>
                                <li className="m-1">Mejorado la <b className="bold">gestión de stocks</b> y producto almacenado.
                                </li>
                                <li className="m-1">Controlado y vigilado la evolución de los productos.
                                </li>
                                <li className="m-1">Detectado, acotado y <b className="bold">analizado problemas</b>
                                    rápidamente.</li>
                                <li className="m-1">Ayudado a identificar y retirar producto con alguna incidencia.</li>
                            </ul>
                            <p>Simplemente, nadie podría alterar los datos del proceso y se podría evidenciar todo desde
                                las incidencias (a qué hora ocurrieron y quiénes fueron los responsables) hasta las
                                buenas prácticas (calidad de la materia prima utilizada, certificaciones obtenidas,
                                etcétera).</p>
                            <p>Se trata de contar con la posibilidad de revisar, vigilar y auditar todos los registros
                                del proceso en cualquier momento, de una manera transparente y confiable.</p>
                            <h4 className="bold">¿Quiénes se benefician de un sistema de logística basada en blockchain?
                            </h4>



                            <ul>
                                <li className="m-1"><b className="bold">Gobierno.</b> Contribuye a transparentar y eficientar
                                    procesos de auditoría, asegurándose de que los
                                    productos son de alta calidad, brindando herramientas que ayuden a promover la
                                    participación
                                    y confianza de la ciudadanía.
                                </li>
                                <li className="m-1"><b className="bold">Empresas.</b>
                                    Ayuda a hacer más eficientes ciertos procesos, como el rastreo de sus productos,
                                    generación automatizada de reportes y verificación rápida de la autenticidad de sus
                                    productos. También ayuda a mejorar la confianza ante sus consumidores, asegurando y
                                    mostrando la calidad de la materia prima y su elaboración.
                                </li>
                                <li className="m-1"><b className="bold">Usuarios o consumidores</b>
                                    Otorga las herramientas para poder ver de una manera transparente la procedencia y
                                    proceso de elaboración de los productos que consumen, cuidando el planeta, su salud
                                    y seguridad.</li>
                            </ul>

                            <p>La tecnología va a pasos agigantados, permitiendo a la industria adoptar diversas
                                aplicaciones a favor de la innovación, trazabilidad, seguridad y eficiencia, como
                                blockchain, que aplicado a las cadenas de suministro, tiene un impacto positivo para
                                todos.</p>

                            <p>Esta tecnología ya está en nuestras manos, úsala y sácale el mejor provecho ahorrado
                                costos, mejorando procesos y demostrando la calidad de tu cadena.</p>


                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="container py-5 b-t b-2x">
                    <div className="media align-items-center">
                        <img className="d-flex mr-3 rounded-circle shadow" style={{"width": "100px", "height": "100px"}}
                            src="/images/landing/avatar/team/goretti-profile.jpg" alt="Goretti Oronia Tamayo"/>
                        <div className="media-body">
                            <h5 className="my-0 bold">Goretti Oronia Tamayo</h5>
                            <span className="color-2">Lifechain</span>
                            <hr/>
                            <p className="small color-2 mb-0">Lic. en Mercadotecnia con especialidad en investigación de
                                mercados, por la Universidad de Guadalajara, traductora y evangelista de blockchain.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="container py-5 b-t">
                    <ul className="list-unstyled d-flex flex-wrap flex-row align-items-center">
                        <li className="mr-4">
                            <i className="fas fa-tag color-2"></i>
                        </li>
                        <li>
                            <a href=" #" className="badge badge-pill badge-outline-2 mr-2">blockchain</a>
                        </li>
                        <li>
                            <a href=" #" className="badge badge-pill badge-outline-2 mr-2">emprendimiento social</a>
                        </li>
                        <li>
                            <a href=" #" className="badge badge-pill badge-outline-2 mr-2">cadena</a>
                        </li>
                        <li>
                            <a href=" #" className="badge badge-pill badge-outline-2 mr-2">suministro</a>
                        </li>
                        <li>
                            <a href=" #" className="badge badge-pill badge-outline-2 mr-2">cadena de suministro</a>
                        </li>
                        <li>
                            <a href=" #" className="badge badge-pill badge-outline-2 mr-2">trazabilidad</a>
                        </li>
                        <li>
                            <a href=" #" className="badge badge-pill badge-outline-2 mr-2">transparencia</a>
                        </li>
                        <li>
                            <a href=" #" className="badge badge-pill badge-outline-2 mr-2">confianza</a>
                        </li>
                        <li>
                            <a href=" #" className="badge badge-pill badge-outline-2 mr-2">México</a>
                        </li>
                    </ul>
                </div>
            </section>
        </main>
        <BlogFooter/>
      </Fragment>
    </div>
  );
}

export default BlogCadenaSuministro;